import React, { useState, useEffect } from "react";
import {
  Form,
  OverlayTrigger,
  Tooltip,
  Col,
  Button,
  Alert,
} from "react-bootstrap";
import PerishApi from "../../api/perishApi";
import AuthApi from "../../api/authApi";
import AdminApi from "../../api/admin";
import { toast } from "react-toastify";
import CustomDataTable from "../../components/CustomDataTable";
import Helpers from "../../components/Helpers";
import DeleteModel from "../../components/DeleteModel";
import AlertDelete from "../../components/AlertDelete";
import MasterDataConfirmationModal from "../../components/MasterDataConfirmationModal";

import { Cookies, useCookies } from "react-cookie";
import Loader from "../../components/loader";
import ImagePlaceHolder from "../../assets/images/image-placeholder.png";

const Perish = () => {
  const [isError, setIsError] = useState(false);
  const [validated, setValidated] = useState(false);
  const [cp, setCp] = useState(false);
  const [icon_id, setIcon_id] = useState("");
  const [serviceDataList, setServiceDataList] = useState([]);
  const [isTempUpload, setIsTempUpload] = useState(false);
  const [show, setShow] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const handleClose = () => setShow(false);
  const [LoadData, setLoadData] = useState(false);
  let cookies = new Cookies();
  let adminApi = new AdminApi();
  let userData = cookies.get("LoveStrongUser") || null;
  let perishApi = new PerishApi();
  let authApi = new AuthApi();
  const [data, setData] = useState({
    parish: "",
    address: "",
    state: "",
    city: "",
    zip: "",
    imageUrl: "",
    link: "",
  });
  const [oldData, setOldData] = useState([]);
  const [oldValues, setOldValues] = useState([]);
  const [matches, setMatches] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    getPerishList();
    let report = {
      byWhom:
        `${userData && userData.userName ? userData.userName : null} ` +
        ` (${userData && userData.userRole ? userData.userRole : null})`,
      pagelink: window.location.href,
      description: "Visited on Perish page.",
      action: "View",
    };
    adminApi
      .addLogReport(report)
      .then((response) => {
        if (response.success) {
          console.log("Created new log report.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getPerishList = async () => {
    setLoadData(true);
    await authApi
      .getPerishList()
      .then((response) => {
        const sortedList = response.data.sort((a, b) =>
          a.parish.localeCompare(b.parish)
        );
        setServiceDataList(sortedList);
        setLoadData(false);
      })
      .catch((error) => {
        setLoadData(false);
        toast.error(error.toString());
      });
  };

  const handleShow = (item) => {
    if (item && (item.Retreats_count > 0 || item.couples_count > 0)) {
      setDeleteAlert(true);
    } else {
      setShow(true);
      setData(item);
      setIcon_id(item.id);
    }
  };

  const handleCloseAlert = () => {
    setDeleteAlert(false);
  };

  const handleChanges = (e) => {
    let { name, value } = e.target;
    let d = { ...data };
    let o = { ...oldData };
    if (name === "parish") {
      o.parish = oldValues.parish;
    } else if (name === "zip") {
      o.zip = oldValues.zip;
    } else if (name === "city") {
      o.city = oldValues.city;
    } else if (name === "address") {
      o.address = oldValues.address;
    } else if (name === "state") {
      o.state = oldValues.state;
    }
    setOldData(o);
    if (name == "imageUrl") {
      d.imageUrl = e.target.files[0];
      toast.success("The profile image has been successfully added.");
    } else {
      d[name] = value;
    }
    setData(d);
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
  
    if (form.checkValidity()) {
      if (icon_id === "") {
        let data1 = new FormData();
        data1.append("file", data.imageUrl);
        let stateRef = { ...data };
        delete stateRef.imageUrl;
        data1.append("fromData", JSON.stringify(stateRef));
        // Add Perish: Check for matches first
        perishApi
          .addPerish(data1)
          .then(async (res) => {
            if (res.success) {
              if (res.matches && res.matches.length > 0) {
                // Matches found, show confirmation modal
                setMatches(res.matches);
                setShowConfirmationModal(true);
              } else {
                // No matches, proceed directly
                handleAfterSubmit(res);
              }
            } else {
              toast.error(res.msg);
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error(error.toString());
          });
      } else {
        // Prepare update form data
        let data1 = new FormData();
        if (data.imageUrl !== "") {
          data1.append("file", data.imageUrl);
        }
        let stateRef = { ...data };
        data1.append("fromData", JSON.stringify(stateRef));
        // Update Perish
        perishApi
          .updatePerish(data1, icon_id)
          .then(async (response) => {
            getPerishList(); // Update perish list
            toast.success(response.msg);
            clearfield();
  
            // Log changes if any data property has been updated
            if (oldData) {
              for (const key in data) {
                if (data[key] !== oldData[key]) {
                  let report = {
                    byWhom: `${userData?.userName || ""} (${userData?.userRole || ""})`,
                    pagelink: window.location.href,
                    description: `Edited Perish (Perish Id: ${icon_id}, Parish Name: ${data.parish})` +
                      ` Field "${key}" changed from "${oldData[key] || "Nil"}" to "${data[key] || "Nil"}"`,
                    action: "Edit",
                  };
                  await adminApi.addLogReport(report).catch((error) => {
                    console.error(error);
                  });
                }
              }
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error(error.toString());
          });
      }
    } else {
      setValidated(true);
    }
  };
  
  const handleConfirmSubmit = () => {
    if (matches.length > 0) {
      // Proceed with adding perish when user confirms
      let data1 = new FormData();
      data1.append("file", data.imageUrl);
      let stateRef = { ...data, forceSave: true}; // Include forceSave
      delete stateRef.imageUrl;
      stateRef = {...stateRef, }
      data1.append("fromData", JSON.stringify(stateRef));
      perishApi
        .addPerish(data1) 
        .then((res) => {
          if (res.success) {
            handleAfterSubmit(res);
          } else {
            toast.error(res.msg);
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.toString());
        });
  
      // Close modal after submission
      setShowConfirmationModal(false);
    }
  };
  
  const handleAfterSubmit = async (response) => {
    getPerishList(); // Refresh perish list after adding or updating
    toast.success(response.msg);
    clearfield();
  
    // Log report for Add action
    let report = {
      byWhom: `${userData?.userName || ""} (${userData?.userRole || ""})`,
      pagelink: window.location.href,
      description: `Added New Perish (Perish Id: ${response.data?.id}, Parish Name: ${response.data?.parish})`,
      action: "Add",
    };
    await adminApi.addLogReport(report).catch((error) => {
      console.error(error);
    });
  };
  

  const clearfield = () => {
    setData({
      parish: "",
      address: "",
      state: "",
      city: "",
      zip: "",
      imageUrl: "",
      link: "",
    });
    setIcon_id("");
  };

  const resetForm = () => {
    clearfield(); // Clear form fields
    setValidated(false);
  };

  const CollectData = (item) => {
    setIcon_id(item.id);
    setOldValues(item);
    setValidated(false);
    let d = { ...data };
    d.id = item.id;
    d.parish = item.parish;
    d.address = item.address;
    d.state = item.state;
    d.city = item.city;
    d.zip = item.zip;
    d.imageUrl = item.imageUrl;
    d.link = item.link;
    setData(d);
    const form = document.getElementById("container-scroller");
    form.scrollIntoView();
  };

  const DeleteData = async () => {
    clearfield();
    await perishApi
      .deletePerish(data, icon_id)
      .then(async (response) => {
        getPerishList();
        setShow(false);
        setIcon_id("");
        toast.success(response.msg);
        let report = {
          byWhom:
            `${userData && userData.userName ? userData.userName : null} ` +
            ` (${userData && userData.userRole ? userData.userRole : null})`,
          pagelink: window.location.href,
          description: `Deleted Parish ( Parish Id : ${icon_id} 
                          Parish Name : ${data.parish} )`,
          action: "Delete",
        };
        await adminApi
          .addLogReport(report)
          .then((response) => {
            if (response.success) {
              console.log("Created new log report.");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        setShow(false);
        setIcon_id("");
        toast.error(error.toString());
      });
  };

  const SearchData = (event) => {
    setLoadData(true);
    const key = event.target.value;
    if (key) {
      console.warn(key);
      perishApi
        .searchKey(data, key)
        .then(async (response) => {
          setServiceDataList(response.data);
          setLoadData(false);
        })
        .catch((error) => {
          setLoadData(false);
          toast.error(error.toString());
        });
    } else {
      setLoadData(false);
      getPerishList();
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip">{props}</Tooltip>
  );

  const columns = [
    ...(userData && userData.userRole === "ADMIN"
      ? [
          {
            id: 3,
            name: "Action",
            button: true,
            cell: (row) => (
              <>
                <button
                  className="font-size"
                  onClick={() => {
                    CollectData(row);
                    setIsTempUpload(false);
                  }}
                >
                  <i
                    className="mdi mdi-pencil-box text-md text-primary font-size"
                    title="Edit"
                  ></i>
                </button>
                <button className="font-size" onClick={() => handleShow(row)}>
                  <i
                    className="mdi mdi-delete-forever text-md text-danger mx-2 font-size"
                    title="Delete"
                  ></i>
                </button>
              </>
            ),
          },
        ]
      : []),
    {
      id: 1,
      name: "Parish",
      selector: (row) => row.parish,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.parish ? row.parish : "")}
        >
          <a href={row.link} target="_blank">
            <span data-tag="allowRowEvents">{row.parish}</span>
          </a>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      tag: "allowRowEvents",
      style: {
        cursor: "text",
      },
      width: "300px",
    },
    {
      id: "Address",
      name: "Address",
      selector: (row) => row.address,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.address)}
        >
          <span>
            {row.address &&
              row.address.slice(0, 10) + (row.address.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "300px",
      style: {
        cursor: "text",
      },
    },

    {
      id: "city",
      name: "City",
      selector: (row) => row.city,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.city)}
        >
          <span>
            {row.city &&
              row.city.slice(0, 10) + (row.city.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "state",
      name: "State",
      selector: (row) => row.state,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.state)}
        >
          <span>
            {row.state &&
              row.state.slice(0, 10) + (row.state.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "zip",
      name: "Zip",
      selector: (row) => row.zip,
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },

    {
      id: 3,
      name: "Retreats count",
      selector: (row) => row.Retreats_count,
      cell: (row) => <a href="/manageRetreat">{row.Retreats_count}</a>,
      sortable: true,
      reorder: true,
      tag: "allowRowEvents",
      style: {
        cursor: "text",
      },
    },
    {
      id: 2,
      name: "CreatedDate",
      selector: (row) => Helpers.convertUtcToTimezone(row.createdAt),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY hh:mm:a")
          )}
        >
          <span>
            {row.createdAt
              ? Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY")
              : ""}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      style: {
        cursor: "text",
      },
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) =>
        row.accountStatus === "deceased" ||
        row.accountStatus === "divorced" ||
        row.accountStatus === "inactive",
      style: {
        backgroundColor: "#f8d7da",
        color: "#000",
        borderColor: "#dfc2c4",
      },
    },
  ];

  const upload = () => {
    document.getElementById("CoupleProfileUpload").click();
    setIsTempUpload(true);
  };

  const remove = () => {
    let d = { ...data };
    d.imageUrl = "";
    setData(d);
    setIsTempUpload(false);
  };

  return (
    <div className="row">
      <div className="col-sm-12">
        {userData && userData.userRole === "ADMIN" ? (
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card custom-card">
              <div className="card-body">
                <h4 className="card-title Couple_heading">Add Parish</h4>
                <Form
                  onSubmit={handleSubmit}
                  noValidate
                  validated={validated}
                  className="forms-sample Couple_heading"
                >
                  <div className="form-group row flex-grow">
                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <Form.Label>Parish Name</Form.Label>
                      <Form.Control
                        required
                        size="sm"
                        type="text"
                        name="parish"
                        id="parish"
                        placeholder="Enter New Parish"
                        value={data.parish}
                        className="form-control Couple_heading"
                        onChange={(e) => handleChanges(e)}
                      />
                    </Form.Group>
                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="address"
                        id="address"
                        placeholder="Address"
                        value={data.address}
                        onChange={(e) => handleChanges(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Address is required!
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <Form.Label>State</Form.Label>
                      <Form.Select
                        // className="form-control minimal"
                        size="sm"
                        type="text"
                        name="state"
                        id="state"
                        placeholder="State"
                        value={data.state}
                        onChange={(e) => handleChanges(e)}
                      >
                        <option value="">Select State</option>
                        <option value="Texas">Texas</option>
                        <option value="Alaska">Alaska</option>
                        <option value="Alabama">Alabama</option>
                        <option value="Arkansas">Arkansas</option>
                        <option value="Arizona">Arizona</option>
                        <option value="California">California</option>
                        <option value="Colorado">Colorado</option>
                        <option value="Connecticut">Connecticut</option>
                        <option value="District of Columbia">
                          District of Columbia
                        </option>
                        <option value="Delaware">Delaware</option>
                        <option value="Florida">Florida</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Hawaii">Hawaii</option>
                        <option value="Iowa">Iowa</option>
                        <option value="Idaho">Idaho</option>
                        <option value="Illinois">Illinois</option>
                        <option value="Indiana">Indiana</option>
                        <option value="Kansas">Kansas</option>
                        <option value="Kentucky">Kentucky</option>
                        <option value="Louisiana">Louisiana</option>
                        <option value="Massachusetts">Massachusetts</option>
                        <option value="Maryland">Maryland</option>
                        <option value="Maine">Maine</option>
                        <option value="Michigan">Michigan</option>
                        <option value="Minnesota">Minnesota</option>
                        <option value="Missouri">Missouri</option>
                        <option value="Mississippi">Mississippi</option>
                        <option value="Montana">Montana</option>
                        <option value="North Carolina<">North Carolina</option>
                        <option value="North Dakota">North Dakota</option>
                        <option value="Nebraska">Nebraska</option>
                        <option value="New Hampshire">New Hampshire</option>
                        <option value="New Jersey">New Jersey</option>
                        <option value="New Mexico">New Mexico</option>
                        <option value="Nevada">Nevada</option>
                        <option value="New York">New York</option>
                        <option value="Ohio">Ohio</option>
                        <option value="Oklahoma">Oklahoma</option>
                        <option value="Oregon">Oregon</option>
                        <option value="Pennsylvania">Pennsylvania</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Rhode Island">Rhode Island</option>
                        <option value="South Carolina">South Carolina</option>
                        <option value="South Dakota">South Dakota</option>
                        <option value="Tennessee">Tennessee</option>
                        <option value="Texas">Texas</option>
                        <option value="Utah">Utah</option>
                        <option value="Virginia">Virginia</option>
                        <option value="Vermont">Vermont</option>
                        <option value="Washington">Washington</option>
                        <option value="Wisconsin">Wisconsin</option>
                        <option value="West Virginia">West Virginia</option>
                        <option value="Wyoming">Wyoming</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        State is required!
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="city"
                        id="city"
                        placeholder="City"
                        value={data.city}
                        onChange={(e) => handleChanges(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        City is required!
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <Form.Label>Zip</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="zip"
                        id="zip"
                        placeholder="Zip"
                        value={data.zip}
                        onChange={(e) => handleChanges(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Zip is required!
                      </Form.Control.Feedback>

                      <Form.Group className="form-group mt-3">
                        <Form.Label>Link</Form.Label>
                        <Form.Control
                          // required
                          size="sm"
                          type="text"
                          name="link"
                          id="link"
                          placeholder="Link"
                          value={data.link}
                          onChange={(e) => handleChanges(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Link is required!
                        </Form.Control.Feedback>
                        <h5 className="mt-2">
                          (example - https://www.xyz.com)
                        </h5>
                      </Form.Group>
                    </Form.Group>

                    <Col lg={6} md={6} xs={12}>
                      <Form.Group>
                        {data.imageUrl == "" || data.imageUrl == undefined ? (
                          <div className="edit-profile-img mb-2 text-center">
                            <img src={ImagePlaceHolder} />
                          </div>
                        ) : (
                          <div className="edit-profile-img mb-2 text-center">
                            {isTempUpload ? (
                              <>
                                <img
                                  src={URL.createObjectURL(data?.imageUrl)}
                                />
                              </>
                            ) : (
                              <>
                                <img src={data?.imageUrl} />
                              </>
                            )}
                          </div>
                        )}{" "}
                        <div className="text-center mt-3">
                          {data.imageUrl == "" || data.imageUrl == undefined ? (
                            <>
                              <input
                                name="imageUrl"
                                className="pt-1"
                                type="file"
                                accept="image/*"
                                style={{ display: "none" }}
                                id="CoupleProfileUpload"
                                onChange={(e) => handleChanges(e)}
                              />
                              <Button
                                variant="primary"
                                className="mb-1 text-center"
                                onClick={(e) => {
                                  upload();
                                }}
                              >
                                Upload Parish Logo
                              </Button>
                            </>
                          ) : (
                            <Button
                              variant="danger"
                              className="mb-1 mt-2 "
                              onClick={(e) => {
                                remove();
                              }}
                            >
                              Remove
                            </Button>
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 text-big mx-2">
                      <Button
                        disabled={cp || isError}
                        variant="primary"
                        className="btn-sm"
                        type="submit"
                      >
                        {icon_id === "" ? "Add" : "Update"}
                      </Button>
                      <Button
                        variant="secondary"
                        className="btn-sm mx-2"
                        type="button"
                        onClick={resetForm}
                      >
                        Clear
                      </Button>
                    </div>
                  </div>
                  <Form.Control.Feedback type="invalid">
                    Parish is required!
                  </Form.Control.Feedback>
                </Form>
              </div>
            </div>
          </div>
        ) : null}
        <div className="col-lg-12 d-flex flex-column">
          <div className="row flex-grow">
            <div className="col grid-margin stretch-card">
              <div className="card custom-card">
                <div className="card-body">
                  <div className="d-sm-flex justify-content-between align-items-start Couple_heading">
                    <h4 className="card-title">Parishes</h4>
                    <div className="col-4 d-flex justify-content-end">
                      {/* <label className="">
                        <input type="search" onChange={SearchData} className="form-control" placeholder="Search" aria-controls="order-listing" />
                      </label> */}
                      <div className="form-group">
                        <div className="input-group setSearch">
                          <input
                            onChange={SearchData}
                            type="text"
                            className="form-control searchData"
                            placeholder="Search..."
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-sm btn-primary"
                              type="button"
                            >
                              <i className="icon-search"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <CustomDataTable
                    loading={LoadData}
                    data={serviceDataList}
                    columns={columns}
                    conditionalRowStyles={conditionalRowStyles}
                    pointerOnHover={true}
                    highlightOnHover={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteModel
        show={show}
        handleClose={handleClose}
        DeleteData={DeleteData}
      ></DeleteModel>
      {deleteAlert ? (
        <AlertDelete
          show={deleteAlert}
          alertHeader="You are not able to delete this Parish"
          alerMessage={
            <div>
              <h4>Retreat(s) and Couple(s) are added for this Parish.</h4>{" "}
              <h5>
                {" "}
                If you want to delete this Parish, first please change the
                Parish for Retreat and Couples.
              </h5>
            </div>
          }
          handleClose={handleCloseAlert}
        />
      ) : null}
      <MasterDataConfirmationModal
        show={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={handleConfirmSubmit}
        matches={matches}
        dataKey="parish"
      />
    </div>
  );
};

export default Perish;
