import React, { useState, useEffect } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import TalksApi from "../../api/TalksApi";
import { toast } from "react-toastify";
import CustomDataTable from "../../components/CustomDataTable";
import Helpers from "../../components/Helpers";
import DeleteModel from "../../components/DeleteModel";
import { Cookies, useCookies } from "react-cookie";
import Loader from "../../components/loader";
import AdminApi from "../../api/admin";
import AlertDelete from "../../components/AlertDelete";
import MasterDataConfirmationModal from "../../components/MasterDataConfirmationModal";

const Talks = () => {
  const [isError, setIsError] = useState(false);
  const [validated, setValidated] = useState(false);
  const [cp, setCp] = useState(false);
  const [icon_id, setIcon_id] = useState("");
  const [talksDataList, setTalksDataList] = useState([]);
  const [show, setShow] = useState(false);
  const [LoadData, setLoadData] = useState(false);
  let adminApi = new AdminApi();
  let cookies = new Cookies();
  let userData = cookies.get("LoveStrongUser") || null;
  let talksApi = new TalksApi();
  const [data, setData] = useState({
    talk: "",
  });
  const [oldData, setOldData] = useState([]);
  const [oldValues, setOldValues] = useState([]);
  const [deleteAlert, setDeleteAlert] = useState(false);

  const [matches, setMatches] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleShow = (item) => {
    if (item && item.roasters_count > 0) {
      setDeleteAlert(true);
    } else {
      setShow(true);
      setIcon_id(item.talkId);
    }
  };

  const handleClose = () => setShow(false);

  const handleCloseAlert = () => {
    setDeleteAlert(false);
  };

  const handleChanges = (e) => {
    let { name, value } = e.target;
    let d = { ...data };
    let o = { ...oldData };
    if (name === "talk") {
      o.talk = oldValues.talk;
    }
    setOldData(o);
    d[name] = value;
    setData(d);
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
  
    if (form.checkValidity()) {
      // If it's a new talk, check for potential matches
      if (icon_id === "") {
        talksApi
          .addTalk(data) // Pass data here
          .then((res) => {
            if (res.success) {
              if (res.matches && res.matches.length > 0) {
                // If matches are found, show confirmation modal
                setMatches(res.matches);
                setShowConfirmationModal(true);
              } else {
                // If no matches, proceed directly
                handleAfterSubmit(res);
              }
            } else {
              toast.error(res.msg);
            }
          })
          .catch((error) => {
            console.log(error);
            toast.error(error.toString());
          });
      } else {
        talksApi
          .updateTalk(data, icon_id)
          .then((response) => {
            handleAfterSubmit(response);
          })
          .catch((error) => {
            toast.error(error.toString());
          });
      }
    } else {
      setValidated(true);
    }
  };
  
  // Function to handle actions after submission (successful API response)
  const handleAfterSubmit = async (response) => {
    getTalksList();
    toast.success(response.msg);
    clearfield();
    let report = {
      byWhom: `${userData?.userName || ''} (${userData?.userRole || ''})`,
      pagelink: window.location.href,
      description: `Added/Edited Talk (Talk Id: ${response.data?.id || icon_id}, Talk Name: ${data.talk})`,
      action: icon_id === "" ? "Add" : "Edit",
    };
    await adminApi.addLogReport(report);
  };
  
  // Function to handle confirmation action from the modal
  const handleConfirmSubmit = () => {
    if (matches.length > 0) {
      // Proceed with submitting the talk if the user confirmed
      talksApi
        .addTalk({ ...data, forceSave: true })
        .then((res) => {
          if (res.success) {
            handleAfterSubmit(res);
          } else {
            toast.error(res.msg);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.toString());
        });
      setShowConfirmationModal(false); // Close the confirmation modal after submission
    }
  };
  

  const clearfield = () => {
    setData({
      talk: "",
    });
    setIcon_id("");
  };

  const CollectData = (item) => {
    setIcon_id(item.talkId);
    setOldValues(item);
    let a = { ...data };
    a.talk = item.talk;
    setData(a);
    setValidated(false);
    const form = document.getElementById("container-scroller");
    form.scrollIntoView();
  };

  useEffect(() => {
    getTalksList();
    let report = {
      byWhom:
        `${userData && userData.userName ? userData.userName : null} ` +
        ` (${userData && userData.userRole ? userData.userRole : null})`,
      pagelink: window.location.href,
      description: "Visited on Talk page.",
      action: "View",
    };
    adminApi
      .addLogReport(report)
      .then((response) => {
        if (response.success) {
          console.log("Created new log report.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getTalksList = async () => {
    setLoadData(true);
    await talksApi
      .getAllTalkList()
      .then(async (response) => {
        const sortedList = response.data.sort((a, b) =>
          a.talk.localeCompare(b.talk)
        );
        setTalksDataList(sortedList);
        setLoadData(false);
      })
      .catch((error) => {
        setLoadData(false);
        toast.error(error.toString());
      });
  };

  const DeleteData = async () => {
    clearfield();
    await talksApi
      .deleteTalk(data, icon_id)
      .then(async (response) => {
        getTalksList();
        setShow(false);
        setIcon_id("");
        toast.success(response.msg);
        let report = {
          byWhom:
            `${userData && userData.userName ? userData.userName : null} ` +
            ` (${userData && userData.userRole ? userData.userRole : null})`,
          pagelink: window.location.href,
          description: `Deleted Talk ( Talk Id : ${icon_id}, Talk Name : ${data.talk} )`,
          action: "Delete",
        };
        await adminApi
          .addLogReport(report)
          .then((response) => {
            if (response.success) {
              console.log("Created new log report.");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        setShow(false);
        setIcon_id("");
        toast.error(error.toString());
      });
  };

  const SearchData = (event) => {
    setLoadData(true);
    const key = event.target.value;
    if (key) {
      console.warn(key);
      talksApi
        .searchTalk(data, key)
        .then(async (response) => {
          setTalksDataList(response.data);
          setLoadData(false);
        })
        .catch((error) => {
          setLoadData(false);
          toast.error(error.toString());
        });
    } else {
      setLoadData(false);
      getTalksList();
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip">{props}</Tooltip>
  );

  const columns = [
    ...(userData && userData.userRole === "ADMIN"
      ? [
          {
            id: 3,
            name: "Action",
            button: true,
            cell: (row) => (
              <>
                <button className="font-size" onClick={() => CollectData(row)}>
                  <i
                    className="mdi mdi-pencil-box text-md text-primary font-size"
                    title="Edit"
                  ></i>
                </button>
                <button className="font-size" onClick={() => handleShow(row)}>
                  <i
                    className="mdi mdi-delete-forever text-md text-danger mx-2 font-size"
                    title="Delete"
                  ></i>
                </button>
              </>
            ),
          },
        ]
      : []),
    {
      id: 1,
      name: "Talk",
      selector: (row) => row.talk,
      cell: (row) => (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.talk)}
        >
          <span>{row.talk}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      tag: "allowRowEvents",
      style: {
        cursor: "text",
      },
    },
    // {
    //   id: 7,
    //   name: 'Last When',
    //   selector: row =>
    //     Helpers.convertUtcToTimezone(row.latestTalk),
    //   cell: (row) => (
    //     <OverlayTrigger
    //       placement="bottom"
    //       delay={{ show: 250, hide: 400 }}
    //       overlay={renderTooltip(Helpers.convertUtcToTimezone(row.latestTalk, 'MM-DD-YYYY hh:mm:a'))}
    //     >
    //       <span>{row.latestTalk ? Helpers.convertUtcToTimezone(row.latestTalk, 'MM-DD-YYYY') : ""}</span>
    //     </OverlayTrigger>
    //   ),
    //   sortable: true,
    //   style : {
    //     cursor: "text"
    //   }
    // },
    // {
    //   id: 8,
    //   name: 'By Whom',
    //   selector: row => row && row.hisFirstName !== null ? (row.hisLastName + ', ' + row.hisFirstName + ' and ' + row.herFirstName): '',
    //   cell: (row) => (
    //     <OverlayTrigger
    //       placement="bottom"
    //       delay={{ show: 250, hide: 400 }}
    //       overlay={renderTooltip(row && row.hisFirstName !== null ? (row.hisLastName + ', ' + row.hisFirstName + ' and ' + row.herFirstName): '')}
    //     >
    //       <span>
    //         {row && row.hisFirstName !== null ? (row.hisLastName + ', ' + row.hisFirstName + ' and ' + row.herFirstName): ''}
    //       </span>
    //     </OverlayTrigger>
    //   ),
    //   sortable: true,
    //   reorder: true,
    //   tag: "allowRowEvents",
    //   style : {
    //     cursor: "text"
    //   }
    // },
    {
      id: 2,
      name: "CreatedDate",
      selector: (row) => Helpers.convertUtcToTimezone(row.talkCreated),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            Helpers.convertUtcToTimezone(row.talkCreated, "MM-DD-YYYY hh:mm:a")
          )}
        >
          <span>
            {row.talkCreated
              ? Helpers.convertUtcToTimezone(row.talkCreated, "MM-DD-YYYY")
              : ""}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      style: {
        cursor: "text",
      },
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) =>
        row.accountStatus === "deceased" ||
        row.accountStatus === "divorced" ||
        row.accountStatus === "inactive",
      style: {
        backgroundColor: "#f8d7da",
        color: "#000",
        borderColor: "#dfc2c4",
      },
    },
  ];

  return (
    <div className="row">
      <div className="col-sm-12">
        {userData && userData.userRole === "ADMIN" ? (
          <div className="col-md-6 grid-margin stretch-card">
            <div className="card custom-card">
              <div className="card-body">
                <h4 className="card-title Couple_heading">Add Talk</h4>
                <Form
                  onSubmit={handleSubmit}
                  noValidate
                  validated={validated}
                  className="forms-sample"
                  id="form"
                >
                  <div className="form-group row flex-grow Couple_heading">
                    <Form.Group className="col-sm-11 d-flex">
                      <Form.Control
                        required
                        size="sm"
                        type="text"
                        name="talk"
                        id="talk"
                        placeholder="Enter New Talk"
                        value={data.talk}
                        className="form-control"
                        onChange={(e) => handleChanges(e)}
                      />
                      <div className="text-big mx-2">
                        <button
                          className="font-size"
                          type="submit"
                          disabled={cp || isError}
                        >
                          {icon_id == "" ? (
                            <i
                              className="mdi mdi-plus-circle font-size"
                              title="Add"
                            ></i>
                          ) : (
                            <i
                              className="mdi mdi-checkbox-marked-circle mx-2 font-size"
                              title="Delete"
                            ></i>
                          )}
                        </button>
                      </div>
                    </Form.Group>
                  </div>
                  <Form.Control.Feedback type="invalid">
                    Talk is required!
                  </Form.Control.Feedback>
                </Form>
              </div>
            </div>
          </div>
        ) : null}
        <div className="col-lg-12 d-flex flex-column">
          <div className="row flex-grow">
            <div className="col grid-margin stretch-card">
              <div className="card custom-card">
                <div className="card-body">
                  <div className="d-sm-flex justify-content-between align-items-start Couple_heading Couple_heading">
                    <h4 className="card-title">Talks</h4>
                    <div className="col-4 d-flex justify-content-end">
                      {/* <label>
                        <input type="search" onChange={SearchData} className="form-control searchable" placeholder="Search" aria-controls="order-listing" />
                      </label> */}
                      <div className="form-group">
                        <div className="input-group setSearch">
                          <input
                            onChange={SearchData}
                            type="text"
                            className="form-control searchData"
                            placeholder="Search..."
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-sm btn-primary"
                              type="button"
                            >
                              <i className="icon-search"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <CustomDataTable
                    loading={LoadData}
                    data={talksDataList}
                    columns={columns}
                    conditionalRowStyles={conditionalRowStyles}
                    pointerOnHover={true}
                    highlightOnHover={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteModel
        show={show}
        handleClose={handleClose}
        DeleteData={DeleteData}
      ></DeleteModel>
      {deleteAlert ? (
        <AlertDelete
          show={deleteAlert}
          alertHeader="You are not able to delete this Talk"
          alerMessage={
            <div>
              <h4>Retreat Roaster(s) are added for this Talk.</h4>{" "}
              <h5>
                If you want to delete this Talk, first please change the Talk
                for Retreat Roasters.
              </h5>
            </div>
          }
          handleClose={handleCloseAlert}
        />
      ) : null}

      <MasterDataConfirmationModal
        show={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={handleConfirmSubmit}
        matches={matches}
        dataKey="talk"
      />
    </div>
  );
};
export default Talks;
