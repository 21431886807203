import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
const resources = {
  english: {
    translation: {
      registration_form: "REGISTRATION FORM",
      retreat_team: "Are you Retreatant Couple or a Team Couple ?",
      retreatant: "Retreatant",
      team: "Team",
      check_year:
        "If you or your spouse are 35 years of age or younger, click this box:",
      yes: "Yes",
      his_info: "His Info",
      her_info: "Her Info",
      his_first_name: "His First Name",
      her_first_name: "Her First Name",
      his_last_name: "His Last Name",
      her_last_name: "Her Last Name",
      his_email: "His Email",
      her_email: "Her Email",
      email: "Email",
      his_mobile: "His Mobile",
      her_mobile: "Her Mobile",
      mobile: "Mobile",
      address: "Address",
      state: "State",
      city: "City",
      zip: "Zip Code",
      parish: "Parish",
      anniversary_date: "Anniversary Date (Month/Day)",
      notes: "Notes",
      dietary_allergies:
        "Do you or your spouse have any dietary allergies, dietary restrictions or physical limitations?",
      one_emergency_contact: "Please provide at least one emergency contact:",
      name: "Name",
      relationship: "Relationship",
      phone: "Phone",
      hear_us: "How did you hear about us?",
      website: "Website",
      church_bulletin: "Church Bulletin",
      facebook: "Facebook",
      school_marquee: "School Marquee",
      Pulpit_church: "Pulpit Talk/Table in Church",
      referral: "Referral from a friend/Family member",
      register: "Register",
      add_one_name: "Please add at least one name.",
      least_email_id: "Please add at least one email ID.",
      couple_register_retreat_msg: "Couple registered for Retreat.",
      select_state: "Select State",
      select_parish: "Select Parish...",
      mission_statement: "Our Mission Statement:",
      day: "Day",
      date: "Date",
      start: "Start",
      end: "End",
      lead_couple: "Lead Couple",
      co_lead_couple: "Co-Lead Couple",
      shadow_lead_couple: "Shadow Lead Couple",
      spiritual_director: "Spiritual Director",
      spiritual_mentor: "Spiritual Mentor",
      mentor_couple: "Mentor Couple",
    },
  },
  spanish: {
    translation: {
      registration_form: "FORMULARIO DE INSCRIPCIÓN",
      retreat_team: "¿Son una pareja de retiro o una pareja de equipo?",
      retreatant: "Retiro",
      team: "Equipo",
      check_year:
        "Si usted o su cónyuge tienen 35 años o menos, haga clic en esta casilla:",
      yes: "Sí",
      his_info: "Información de el",
      her_info: "Información de ella",
      his_first_name: "Nombre de el",
      her_first_name: "Nombre de Ella",
      his_last_name: "Apellido (El)",
      her_last_name: "Apellido (Ella)",
      his_email: "Correo Electrónico (El)",
      her_email: "Correo Electrónico (Ella)",
      email: "Correo electrónico",
      his_mobile: "Celular (El)",
      her_mobile: "Celular (Ella)",
      mobile: "Móvil",
      address: "Dirección",
      state: "Estado",
      city: "Ciudad",
      zip: "Código postal",
      parish: "Parroquia / Iglesia",
      anniversary_date: "Fecha de aniversario (mes/día)",
      notes: "Notas",
      dietary_allergies:
        "¿Tiene usted o su cónyuge alguna alergia dietética, restricciones dietéticas o problemas físicos o limitaciones? Si es así, por favor explique.",
      one_emergency_contact: "Proporcione al menos un contacto de emergencia:",
      name: "Nombre",
      relationship: "Relación",
      phone: "Teléfono",
      hear_us: "¿Cómo se enteraron de nosotros?",
      website: "Sitio web",
      church_bulletin: "Boletín de la Iglesia",
      facebook: "Facebook",
      school_marquee: "Anuncio escolar",
      Pulpit_church: "Anuncio desde el púlpito en la iglesia",
      referral: "Referencia de un amigo/familiar",
      register: "Registro",
      add_one_name: "Por favor agregue al menos un nombre.",
      least_email_id:
        "Agregue al menos una identificación de correo electrónico.",
      couple_register_retreat_msg: "Pareja registrada para el Retiro.",
      select_state: "Seleccione estado",
      select_parish: "Seleccione Parroquia...",
      mission_statement: "Nuestra Misión:",
      day: "Dia",
      date: "Fecha",
      start: "Empieza",
      end: "Termina",
      lead_couple: "Pareja Líder",
      co_lead_couple: "Pareja Colider",
      shadow_lead_couple: "Pareja líder de la sombra",
      spiritual_director: "Director espiritual",
      spiritual_mentor: "Mentora espiritual",
      mentor_couple: "Pareja Mentora",
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "english",
    fallbackLng: "spanish",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
