import { Spinner } from 'react-bootstrap';
const Loader = () => {
    return (
        <>
            <div className="loading-overlay is-active">
                <Spinner variant="danger" className="spinner-border" />
            </div>
        </>
    )
}

export default Loader;