import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const MasterDataConfirmationModal = ({ show, matches, onConfirm, onClose, dataKey }) => {
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Potential Matches Found</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Potential matches were found for the {dataKey} you're adding. Please review before proceeding:</p>
        <ul>
          {matches.map((match) => (
            <li key={match.id}>
              <strong>{match[dataKey]}</strong> (ID: {match.id})
            </li>
          ))}
        </ul>
        <p>Are you sure you want to proceed?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>Cancel</Button>
        <Button variant="primary" onClick={onConfirm}>Proceed</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MasterDataConfirmationModal;
