import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RetreatApi from "../api/retreatApi";
import DeleteModel from "./DeleteModel";

const FileUploadModal = ({
  show,
  handleClose,
  rowId,
  handleUploadSuccess,
  fileType,
  existingFileUrl,
  onDeleteFile,
  modalTitle,
  setSelectedRetreat,
  setRetreatHeading,
  setIncommingRetreats
}) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fileUrl, setFileUrl] = useState("");

  let retreatApi = new RetreatApi();
  useEffect(() => {
    setFileUrl(existingFileUrl);
  }, [existingFileUrl]);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUploadSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      toast.warning("Please select a file to upload.");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append(fileType, file);
    formData.append("retreatId", rowId);

    try {
      const response = await retreatApi.uploadFiles(formData);

      if (response.success) {
        toast.success("File uploaded successfully!");
        setFileUrl(response.fileUrl);
        handleClose();
        handleUploadSuccess();
        setSelectedRetreat(response.data.id);
        setRetreatHeading(response.data);
        setIncommingRetreats((prevRetreats) =>
        prevRetreats.map((item) =>
          item.id === response.data.id ? { ...item, ...response.data } : item
        )
      );
     
      } else {
        toast.error("File upload failed.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Error uploading file.");
    } finally {
      setLoading(false);
    }
  };

  const confirmDeleteFile = () => {
    setShowDeleteModal(true); // Show the delete confirmation modal
  };

  const handleDeleteFile = async () => {
    setShowDeleteModal(false); // Hide the modal after confirmation
    try {
      const response = await retreatApi.deleteFile({
        retreatId: rowId,
        fileType,
      });

      if (response.success) {
        toast.success("File deleted successfully!");
        setFileUrl(null);
        onDeleteFile();
      } else {
        toast.error("Failed to delete the file.");
      }
    } catch (error) {
      console.error("Error deleting file:", error);
      toast.error("Error deleting file.");
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {fileType === "programFile"
              ? "Upload Program File"
              : "Upload Directory File"}{" "}
            For "{modalTitle}"
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {fileUrl ? (
            <div className="text-center">
              <p>
                {/*<strong>Uploaded File:</strong>*/}
                <br />
                <a
                  href={fileUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary"
                >
                  View File
                </a>
              </p>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Delete the uploaded file</Tooltip>}
              >
                <Button
                  variant="outline-danger"
                  onClick={confirmDeleteFile}
                  className="mt-3"
                >
                  Delete File
                </Button>
              </OverlayTrigger>
            </div>
          ) : (
            <Form onSubmit={handleUploadSubmit} className="p-3">
              <Form.Group controlId="fileUpload">
                <Form.Label>Select a file to upload</Form.Label>
                <Form.Control
                  className="py-2 mt-2"
                  type="file"
                  onChange={handleFileChange}
                  required
                />
              </Form.Group>
              <div className="text-center mt-4">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={loading}
                  className="w-100"
                >
                  {loading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}
                      Uploading...
                    </>
                  ) : (
                    "Upload"
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <DeleteModel
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)} // Close modal on cancel
        DeleteData={handleDeleteFile}
      />
    </>
  );
};

export default FileUploadModal;
