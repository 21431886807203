import React, { useState } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import Header from "../layout/Header";
import Footer from "../layout/footer";
import SideBar from "../layout/SideBar";
import { UpdateCouple } from "../pages/couple/updateCouple";
import MyProfile from "../pages/admin/MyProfile";
import ParticipatedInRetreats from "../pages/couple/participatedInRetreats";
import { RegistrationRetreat } from "../pages/auth/registrationRetreat";
import RetreatRegistrations from "../pages/admin/retreatRegistrations";
import { Cookies, useCookies } from "react-cookie";
import RetreatRoaster from "../pages/admin/Retreats/RetreatRoaster";
import CreateOuterRetreat from "../pages/auth/CreateOuterRetreat";

const CoupleRouter = ({ logoutUser = null }) => {
  let cookies = new Cookies();
  let userData = cookies.get("LoveStrongUser") || null;

  const commonRotes = [
    { path: "/update-couple", element: <UpdateCouple /> },
    { path: "/my-profile", element: <MyProfile /> },
    { path: "/participated-in-retreats", element: <ParticipatedInRetreats /> },
    { path: "/registration-retreat/:id", element: <RegistrationRetreat /> },
    { path: "/registration-retreat", element: <RegistrationRetreat /> },
    { path: "/register-new-retreat", element: <CreateOuterRetreat/> },
  ];
  if (userData && userData.access === true) {
    commonRotes.push({
      path: "/retreat-registrations",
      element: <RetreatRegistrations />,
    });
    commonRotes.push({
      path: "/retreatRoaster",
      element: <RetreatRoaster />,
    });
  }

  if (userData && userData.coupleId === null) {
    commonRotes.push({
      path: "/*",
      element: <Navigate to="/retreat-registrations" />,
    });
  } else {
    commonRotes.push({ path: "/*", element: <Navigate to="/update-couple" /> });
  }
  const route = useRoutes(commonRotes);

  const [active, setActive] = useState("");
  const RightNav = () => {
    if (active === "active") {
      setActive("");
    } else {
      setActive("active");
    }
  };
  const [LeftPanel, setLeftPanel] = useState("");
  const LeftNav = () => {
    if (LeftPanel === "sidebar-icon-only") {
      setLeftPanel("");
    } else {
      setLeftPanel("sidebar-icon-only");
    }
  };

  let pathName = route;
  if (
    pathName.props.match.route.path === "/registration-retreat/:id" ||
    pathName.props.match.route.path === "/register-new-retreat"
  ) {
    return <>{route}</>;
  }
  return (
    <div className={`${LeftPanel}`}>
      <div className="container-scroller" id="container-scroller">
        <Header logoutUser={logoutUser} RightNav={RightNav} LeftNav={LeftNav} />
        <div className="container-fluid page-body-wrapper">
          <SideBar active={active} RightNav={RightNav} />
          <div className="main-panel">
            <div className="content-wrapper retreats">{route}</div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoupleRouter;
