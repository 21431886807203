import React, { useState, useEffect } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import RolesApi from "../../api/RolesApi";
import { toast } from "react-toastify";
import CustomDataTable from "../../components/CustomDataTable";
import Helpers from "../../components/Helpers";
import DeleteModel from "../../components/DeleteModel";
import { Cookies, useCookies } from "react-cookie";
import Loader from "../../components/loader";
import AdminApi from "../../api/admin";
import AlertDelete from "../../components/AlertDelete";
import MasterDataConfirmationModal from "../../components/MasterDataConfirmationModal";

const Roles = () => {
  const [isError, setIsError] = useState(false);
  const [validated, setValidated] = useState(false);
  const [cp, setCp] = useState(false);
  const [icon_id, setIcon_id] = useState("");
  const [rolesDataList, setrolesDataList] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [LoadData, setLoadData] = useState(false);
  const [oldData, setOldData] = useState([]);
  const [oldValues, setOldValues] = useState([]);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [matches, setMatches] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  let cookies = new Cookies();
  let adminApi = new AdminApi();

  let userData = cookies.get("LoveStrongUser") || null;

  let rolesApi = new RolesApi();
  const [data, setData] = useState({
    role: "",
  });

  useEffect(() => {
    getrolesList();
    let report = {
      byWhom:
        `${userData && userData.userName ? userData.userName : null} ` +
        ` (${userData && userData.userRole ? userData.userRole : null})`,
      pagelink: window.location.href,
      description: "Visited on Roles page.",
      action: "View",
    };
    adminApi
      .addLogReport(report)
      .then((response) => {
        if (response.success) {
          console.log("Created new log report.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleShow = (item) => {
    if (item && item.roasters_count > 0) {
      setDeleteAlert(true);
    } else {
      setShow(true);
      setIcon_id(item.id);
      setData(item);
    }
  };

  const handleCloseAlert = () => {
    setDeleteAlert(false);
  };

  const handleChanges = (e) => {
    let { name, value } = e.target;
    let d = { ...data };
    let o = { ...oldData };
    if (name === "role") {
      o.role = oldValues.role;
    }
    setOldData(o);
    d[name] = value;
    setData(d);
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
  
    if (form.checkValidity()) {
      if (icon_id === "") {
        // Add Role: Check for matches first
        rolesApi
          .addRole(data)
          .then(async (res) => {
            if (res.success) {
              if (res.matches && res.matches.length > 0) {
                // Matches found, show confirmation modal
                setMatches(res.matches);
                setShowConfirmationModal(true);
              } else {
                // No matches, proceed directly
                handleAfterSubmit(res);
              }
            } else {
              toast.error(res.msg);
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error(error.toString());
          });
      } else {
        // Update Role
        rolesApi
          .updateRole(data, icon_id)
          .then(async (response) => {
            getrolesList();
            toast.success(response.msg);
            clearfield();
  
            // Log changes if there are updates in `role`
            if (oldData.role && oldData.role !== data.role) {
              let report = {
                byWhom: `${userData?.userName || ""} (${userData?.userRole || ""})`,
                pagelink: window.location.href,
                description: `Edited Role (Role Id: ${icon_id}, Role Name: ${data.role})` +
                  ` From "${oldData.role || "Nil"}" to "${data.role || "Nil"}"`,
                action: "Edit",
              };
              await adminApi.addLogReport(report).catch((error) => {
                console.error(error);
              });
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error(error.toString());
          });
      }
    } else {
      setValidated(true);
    }
  };

  const handleConfirmSubmit = () => {
    if (matches.length > 0) {
      // Proceed with adding the role when user confirms
      rolesApi
        .addRole({ ...data, forceSave: true }) // Include forceSave
        .then((res) => {
          if (res.success) {
            handleAfterSubmit(res);
          } else {
            toast.error(res.msg);
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.toString());
        });
  
      // Close modal after submission
      setShowConfirmationModal(false);
    }
  };
  
  const handleAfterSubmit = async (response) => {
    getrolesList();
    toast.success(response.msg);
    clearfield();
  
    // Log report for Add action
    let report = {
      byWhom: `${userData?.userName || ""} (${userData?.userRole || ""})`,
      pagelink: window.location.href,
      description: `Added New Role (Role Id: ${response.data?.id}, Role Name: ${response.data?.role})`,
      action: "Add",
    };
    await adminApi.addLogReport(report).catch((error) => {
      console.error(error);
    });
  };

  const clearfield = () => {
    setData({
      role: "",
    });
    setIcon_id("");
  };

  const CollectData = (item) => {
    setIcon_id(item.id);
    setData(item);
    setOldValues(item);
    setValidated(false);
    const form = document.getElementById("container-scroller");
    form.scrollIntoView();
  };

  const getrolesList = async () => {
    setLoadData(true);
    await rolesApi
      .getAllRoleList()
      .then(async (response) => {
        // const sortedList = response.data.sort((a, b) =>
        //   a.role.localeCompare(b.role));
        setrolesDataList(response.data);
        setLoadData(false);
      })
      .catch((error) => {
        setLoadData(false);
        toast.error(error.toString());
      });
  };

  const DeleteData = async () => {
    await rolesApi
      .deleteRole(data, icon_id)
      .then(async (response) => {
        getrolesList();
        setShow(false);
        setIcon_id("");
        toast.success(response.msg);
        let report = {
          byWhom:
            `${userData && userData.userName ? userData.userName : null} ` +
            ` (${userData && userData.userRole ? userData.userRole : null})`,
          pagelink: window.location.href,
          description: `Deleted Role ( Role Id : ${icon_id} 
            Role Name : ${data.role} )`,
          action: "Delete",
        };
        await adminApi
          .addLogReport(report)
          .then((response) => {
            if (response.success) {
              console.log("Created new log report.");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        setShow(false);
        setIcon_id("");
        toast.error(error.toString());
      });
  };

  const SearchData = (event) => {
    setLoadData(true);
    const key = event.target.value;
    if (key) {
      console.warn(key);
      rolesApi
        .searchRole(data, key)
        .then(async (response) => {
          setrolesDataList(response.data);
          setLoadData(false);
        })
        .catch((error) => {
          setLoadData(false);
          toast.error(error.toString());
        });
    } else {
      setLoadData(false);
      getrolesList();
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip">{props}</Tooltip>
  );

  const columns = [
    ...(userData && userData.userRole === "ADMIN"
      ? [
          {
            id: 3,
            name: "Action",
            button: true,
            cell: (row) => (
              <>
                <button className="font-size" onClick={() => CollectData(row)}>
                  <i
                    className="mdi mdi-pencil-box text-md text-primary font-size"
                    title="Edit"
                  ></i>
                </button>
                <button className="font-size" onClick={() => handleShow(row)}>
                  <i
                    className="mdi mdi-delete-forever text-md text-danger mx-2 font-size"
                    title="Delete"
                  ></i>
                </button>
              </>
            ),
          },
        ]
      : []),
    {
      id: 1,
      name: " Service Role",
      selector: (row) => row.role,
      cell: (row) => (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.role)}
        >
          <span>{row.role}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      tag: "allowRowEvents",
      style: {
        cursor: "text",
      },
    },
    {
      id: 2,
      name: "CreatedDate",
      sortable: true,
      selector: (row) =>
        Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY"),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY hh:mm:a")
          )}
        >
          <span>
            {row.createdAt
              ? Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY")
              : ""}
          </span>
        </OverlayTrigger>
      ),
      style: {
        cursor: "text",
      },
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) =>
        row.accountStatus === "deceased" ||
        row.accountStatus === "divorced" ||
        row.accountStatus === "inactive",
      style: {
        backgroundColor: "#f8d7da",
        color: "#000",
        borderColor: "#dfc2c4",
      },
    },
  ];

  return (
    <div className="row">
      <div className="col-sm-12">
        {userData && userData.userRole === "ADMIN" ? (
          <div className="col-md-6 grid-margin stretch-card">
            <div className="card custom-card">
              <div className="card-body">
                <h4 className="card-title  Couple_heading">Add Service Role</h4>
                <Form
                  onSubmit={handleSubmit}
                  noValidate
                  validated={validated}
                  className="forms-sample Couple_heading"
                  id="form"
                >
                  <div className="form-group row flex-grow">
                    <Form.Group className="col-sm-11 d-flex">
                      <Form.Control
                        required
                        size="sm"
                        type="text"
                        name="role"
                        id="role"
                        placeholder="Enter New Service Role"
                        value={data.role}
                        className="form-control"
                        onChange={(e) => handleChanges(e)}
                      />
                      <div className="text-big mx-2">
                        <button
                          className="font-size"
                          type="submit"
                          disabled={cp || isError}
                        >
                          {icon_id == "" ? (
                            <i
                              className="mdi mdi-plus-circle font-size"
                              title="Add"
                            ></i>
                          ) : (
                            <i
                              className="mdi mdi-checkbox-marked-circle mx-2 font-size"
                              title="Delete"
                            ></i>
                          )}
                        </button>
                      </div>
                    </Form.Group>
                  </div>
                  <Form.Control.Feedback type="invalid">
                    Service role is required!
                  </Form.Control.Feedback>
                </Form>
              </div>
            </div>
          </div>
        ) : null}
        <div className="col-lg-12 d-flex flex-column">
          <div className="row flex-grow">
            <div className="col grid-margin stretch-card">
              <div className="card custom-card">
                <div className="card-body">
                  <div className="d-sm-flex justify-content-between align-items-start Couple_heading">
                    <h4 className="card-title">Service Roles</h4>
                    <div className="col-4 d-flex justify-content-end">
                      {/* <label className="">
                        <input type="search" onChange={SearchData} className="form-control" placeholder="Search" aria-controls="order-listing" />
                      </label> */}
                      <div className="form-group">
                        <div className="input-group setSearch">
                          <input
                            onChange={SearchData}
                            type="text"
                            className="form-control searchData"
                            placeholder="Search..."
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-sm btn-primary"
                              type="button"
                            >
                              <i className="icon-search"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <CustomDataTable
                    loading={LoadData}
                    data={rolesDataList}
                    columns={columns}
                    conditionalRowStyles={conditionalRowStyles}
                    pointerOnHover={true}
                    highlightOnHover={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteModel
        show={show}
        handleClose={handleClose}
        DeleteData={DeleteData}
      ></DeleteModel>
      {deleteAlert ? (
        <AlertDelete
          show={deleteAlert}
          alertHeader="You are not able to delete this Role"
          alerMessage={
            <div>
              <h4>Retreat Roaster(s) are added for this Role.</h4>{" "}
              <h5>
                If you want to delete this Role, first please change the Role
                for Retreat Roasters.
              </h5>
            </div>
          }
          handleClose={handleCloseAlert}
        />
      ) : null}
      
      <MasterDataConfirmationModal
        show={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={handleConfirmSubmit}
        matches={matches}
        dataKey="role"
      />
    </div>
  );
};
export default Roles;
