import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import { useState } from "react";
const SideBar = (props) => {
  const [hover, setHover] = useState("");
  const [masterDataShow, setMasterDataShow] = useState("masterlist");
  let cookies = new Cookies();
  let userData = cookies.get("LoveStrongUser") || null;
  const ShowSideTitle = (menuItem) => {
    setHover(menuItem);
  };

  const Showmaster = () => {
    if (masterDataShow === "masterlist") {
      setMasterDataShow("");
    } else {
      setMasterDataShow("masterlist");
    }
  };
  const location = useLocation();

  return (
    <nav className={`sidebar sidebar-offcanvas ${props.active}`} id="sidebar">
      {userData &&
      userData.userRole === "ADMIN" &&
      userData.isDisabled === 0 ? (
        <ul className="nav">
          <li
            className={
              hover === "quickReport" ? `nav-item hover-open ` : "nav-item"
            }
            onMouseOut={() => setHover("")}
            onClick={props.RightNav}
          >
            <Link
              className="nav-link"
              to="/quickReport"
              onMouseOver={() => ShowSideTitle("quickReport")}
            >
              <i
                className="mdi mdi-grid-large menu-icon"
                style={
                  hover === "quickReport" ? { color: "#CCA91D" } : { color: "" }
                }
              ></i>
              <span
                className="menu-title"
                style={
                  hover === "quickReport" ? { color: "#CCA91D" } : { color: "" }
                }
              >
                Quick Reports
              </span>
            </Link>
          </li>
          <li
            className={
              hover === "search_data" ? `nav-item hover-open ` : "nav-item"
            }
            onMouseOut={() => setHover("")}
            onClick={props.RightNav}
          >
            <Link
              className="nav-link"
              to="/search_data"
              onMouseOver={() => ShowSideTitle("search_data")}
            >
              <i
                className="menu-icon mdi mdi-magnify"
                style={
                  hover === "search_data" ? { color: "#CCA91D" } : { color: "" }
                }
              ></i>
              <span
                className="menu-title"
                style={
                  hover === "search_data" ? { color: "#CCA91D" } : { color: "" }
                }
              >
                {" "}
                Advanced Search
              </span>
            </Link>
          </li>
          <li
            className={
              hover === "manageCouples" ? `nav-item hover-open ` : "nav-item"
            }
            onMouseOut={() => setHover("")}
            onClick={props.RightNav}
          >
            <Link
              className="nav-link"
              to="/manageCouples"
              onMouseOver={() => ShowSideTitle("manageCouples")}
            >
              <i
                className="menu-icon mdi mdi-account-multiple"
                style={
                  hover === "manageCouples"
                    ? { color: "#CCA91D" }
                    : { color: "" }
                }
              ></i>
              <span
                className="menu-title"
                style={
                  hover === "manageCouples"
                    ? { color: "#CCA91D" }
                    : { color: "" }
                }
              >
                Manage Couples Data
              </span>
            </Link>
          </li>
          <li
            className={
              hover === "manageRetreat" ? `nav-item hover-open ` : "nav-item"
            }
            onMouseOut={() => setHover("")}
            onClick={props.RightNav}
          >
            <Link
              className="nav-link"
              to="/manageRetreat"
              onMouseOver={() => ShowSideTitle("manageRetreat")}
            >
              <i
                className="menu-icon mdi mdi-church"
                style={
                  hover === "manageRetreat"
                    ? { color: "#CCA91D" }
                    : { color: "" }
                }
              ></i>
              <span
                className="menu-title"
                style={
                  hover === "manageRetreat"
                    ? { color: "#CCA91D" }
                    : { color: "" }
                }
              >
                Manage Retreats
              </span>
            </Link>
          </li>
          <li
            className={
              hover === "Send Email" ? `nav-item hover-open ` : "nav-item"
            }
            onMouseOut={() => setHover("")}
            onClick={props.RightNav}
          >
            <Link
              className="nav-link "
              to="/sendEmail"
              onMouseOver={() => ShowSideTitle("Send Email")}
            >
              <i
                className="menu-icon mdi mdi-email"
                style={
                  hover === "Send Email" ? { color: "#CCA91D" } : { color: "" }
                }
              ></i>
              <span
                className="menu-title"
                style={
                  hover === "Send Email" ? { color: "#CCA91D" } : { color: "" }
                }
              >
                Broadcast Emails
              </span>
            </Link>
          </li>
          <li
            className={
              hover === "Log Report" ? `nav-item hover-open ` : "nav-item"
            }
            onMouseOut={() => setHover("")}
            onClick={props.RightNav}
          >
            <Link
              className="nav-link "
              to="/logreport"
              onMouseOver={() => ShowSideTitle("Log Report")}
            >
              <i
                className="menu-icon mdi mdi-file-document-box-outline"
                style={
                  hover === "Log Report" ? { color: "#CCA91D" } : { color: "" }
                }
              ></i>
              <span
                className="menu-title"
                style={
                  hover === "Log Report" ? { color: "#CCA91D" } : { color: "" }
                }
              >
                Log Report
              </span>
            </Link>
          </li>
          <li
            className={hover === "helper" ? `nav-item hover-open ` : "nav-item"}
            onMouseOut={() => setHover("")}
            onClick={props.RightNav}
          >
            <a
              className="nav-link"
              href="https://lovestrongmarriage.com/?page_id=6"
              target="_blank"
              onMouseOver={() => ShowSideTitle("helper")}
            >
              <i
                className="menu-icon mdi mdi-help-circle-outline"
                style={
                  hover === "helper" ? { color: "#CCA91D" } : { color: "" }
                }
              ></i>
              <span
                className="menu-title"
                style={
                  hover === "helper" ? { color: "#CCA91D" } : { color: "" }
                }
              >
                Help
              </span>
            </a>
          </li>
          <li
            className={
              hover === "showList"
                ? `nav-item border-top hover-open`
                : "nav-item border-top"
            }
            onMouseOut={() => setHover("")}
          >
            <a
              className={
                masterDataShow === "masterlist"
                  ? `nav-link collapsed`
                  : "nav-link"
              }
              data-bs-toggle="collapse"
              aria-expanded={
                masterDataShow === "masterlist" ? `false` : "false"
              }
              aria-controls="ui-basic"
              onMouseOver={() => ShowSideTitle("showList")}
              onClick={Showmaster}
            >
              <i
                className="menu-icon mdi mdi-table"
                style={
                  hover === "showList" ? { color: "#CCA91D" } : { color: "" }
                }
              ></i>
              <span
                className="menu-title"
                style={
                  hover === "showList"
                    ? { color: "#CCA91D", cursor: "pointer" }
                    : { color: "", cursor: "pointer" }
                }
              >
                Master Data
              </span>
              <i
                className="menu-arrow"
                style={
                  hover === "showList" ? { color: "#CCA91D" } : { color: "" }
                }
              ></i>
            </a>

            <div
              className={
                masterDataShow === "masterlist" ? `collapse` : "collapse show"
              }
              id="ui-basic"
            >
              <ul
                className="nav flex-column sub-menu"
                onMouseOver={() => ShowSideTitle("showList")}
              >
                <div className="d-flex">
                  <div className="outerDot">
                    {" "}
                    <span
                      className={
                        hover === "talks" ? `dotted hover-open ` : "dotted"
                      }
                    ></span>
                  </div>

                  <li
                    className={
                      hover === "perish" ? `nav-item hover-open ` : "nav-item"
                    }
                    onClick={props.RightNav}
                  >
                    <Link
                      className="nav-link"
                      to="/parish"
                      onMouseOver={() => ShowSideTitle("perish")}
                    >
                      <span
                        className="menu-titles"
                        style={
                          hover === "perish"
                            ? { color: "#CCA91D" }
                            : { color: "" }
                        }
                      >
                        Parishes
                      </span>
                    </Link>
                  </li>
                </div>

                <div className="d-flex">
                  <div className="outerDot">
                    {" "}
                    <span
                      className={
                        hover === "talks" ? `dotted hover-open ` : "dotted"
                      }
                    ></span>
                  </div>
                  <li
                    className={
                      hover === "service" ? `nav-item hover-open ` : "nav-item"
                    }
                    onClick={props.RightNav}
                  >
                    <Link
                      className="nav-link"
                      to="/service"
                      onMouseOver={() => ShowSideTitle("service")}
                    >
                      <span
                        className="menu-titles"
                        style={
                          hover === "service"
                            ? { color: "#CCA91D" }
                            : { color: "" }
                        }
                      >
                        Services
                      </span>
                    </Link>
                  </li>
                </div>

                <div className="d-flex">
                  <div className="outerDot">
                    {" "}
                    <span
                      className={
                        hover === "talks" ? `dotted hover-open ` : "dotted"
                      }
                    ></span>
                  </div>
                  <li
                    className={
                      hover === "roles" ? `nav-item hover-open ` : "nav-item"
                    }
                    onClick={props.RightNav}
                  >
                    <Link
                      className="nav-link"
                      to="/roles"
                      onMouseOver={() => ShowSideTitle("roles")}
                    >
                      <span
                        className="menu-titles"
                        style={
                          hover === "roles"
                            ? { color: "#CCA91D" }
                            : { color: "" }
                        }
                      >
                        Roles
                      </span>
                    </Link>
                  </li>
                </div>

                <div className="d-flex">
                  <div className="outerDot">
                    {" "}
                    <span
                      className={
                        hover === "talks" ? `dotted hover-open ` : "dotted"
                      }
                    ></span>
                  </div>
                  <li
                    className={
                      hover === "talks" ? `nav-item hover-open ` : "nav-item"
                    }
                    onClick={props.RightNav}
                  >
                    <Link
                      className="nav-link"
                      to="/talks"
                      onMouseOver={() => ShowSideTitle("talks")}
                    >
                      <span
                        className="menu-titles"
                        style={
                          hover === "talks"
                            ? { color: "#CCA91D" }
                            : { color: "" }
                        }
                      >
                        Talks
                      </span>
                    </Link>
                  </li>
                </div>
              </ul>
            </div>
          </li>
          <li
            className={
              hover === "Retreat Registrations"
                ? `nav-item hover-open `
                : "nav-item"
            }
            onMouseOut={() => setHover("")}
            onClick={props.RightNav}
          >
            <Link
              className="nav-link"
              to="/retreat-registrations"
              onMouseOver={() => ShowSideTitle("Retreat Registrations")}
            >
              <i
                className="menu-icon mdi mdi-checkbox-multiple-marked"
                style={
                  hover === "Retreat Registrations"
                    ? { color: "#CCA91D" }
                    : { color: "" }
                }
              ></i>
              <span
                className="menu-title"
                style={
                  hover === "Retreat Registrations"
                    ? { color: "#CCA91D" }
                    : { color: "" }
                }
              >
                Retreat Registrations
              </span>
            </Link>
          </li>
          <li
            className={
              hover === "participated-in-retreats"
                ? `nav-item hover-open `
                : "nav-item"
            }
            onMouseOut={() => setHover("")}
            onClick={props.RightNav}
          >
            <Link
              className="nav-link"
              to="/participated-in-retreats"
              onMouseOver={() => ShowSideTitle("participated-in-retreats")}
            >
              <i
                className="menu-icon mdi mdi-church"
                style={
                  hover === "participated-in-retreats"
                    ? { color: "#CCA91D" }
                    : { color: "" }
                }
              ></i>
              <span
                className="menu-title"
                style={
                  hover === "participated-in-retreats"
                    ? { color: "#CCA91D" }
                    : { color: "" }
                }
              >
                {" "}
                Participated In Retreats
              </span>
            </Link>
          </li>
          <li
            className={
              hover === "update-couple" ? `nav-item hover-open ` : "nav-item"
            }
            onMouseOut={() => setHover("")}
            onClick={props.RightNav}
          >
            <Link
              className="nav-link"
              to="/update-couple"
              onMouseOver={() => ShowSideTitle("update-couple")}
            >
              <i
                className="menu-icon mdi mdi-account-multiple"
                style={
                  hover === "update-couple"
                    ? { color: "#CCA91D" }
                    : { color: "" }
                }
              ></i>
              <span
                className="menu-title"
                style={
                  hover === "update-couple"
                    ? { color: "#CCA91D" }
                    : { color: "" }
                }
              >
                {" "}
                Update Your Profile
              </span>
            </Link>
          </li>

          <li
            className={
              hover === "new-retreats" ? `nav-item hover-open ` : "nav-item"
            }
            onMouseOut={() => setHover("")}
            onClick={props.RightNav}
          >
            <Link
              className="nav-link"
              to="/new-retreats"
              onMouseOver={() => ShowSideTitle("new-retreats")}
            >
              <i
                className="menu-icon mdi mdi-new-box"
                style={
                  hover === "new-retreats"
                    ? { color: "#CCA91D" }
                    : { color: "" }
                }
              ></i>
              <span
                className="menu-title"
                style={
                  hover === "new-retreats"
                    ? { color: "#CCA91D" }
                    : { color: "" }
                }
              >
                {" "}
                New Retreats
              </span>
            </Link>
          </li>
        </ul>
      ) : null}

      {userData &&
      userData.userRole === "ADMIN" &&
      userData.isDisabled === 1 ? (
        <ul className="nav" onClick={props.RightNav}>
          {userData && userData.coupleId !== null ? (
            <>
              {" "}
              <li
                className={
                  hover === "update-couple"
                    ? `nav-item hover-open `
                    : "nav-item"
                }
                onMouseOut={() => setHover("")}
                onClick={props.RightNav}
              >
                <Link
                  className="nav-link"
                  to="/update-couple"
                  onMouseOver={() => ShowSideTitle("update-couple")}
                >
                  <i
                    className="menu-icon mdi mdi-account-multiple"
                    style={
                      hover === "update-couple"
                        ? { color: "#CCA91D" }
                        : { color: "" }
                    }
                  ></i>
                  <span
                    className="menu-title"
                    style={
                      hover === "update-couple"
                        ? { color: "#CCA91D" }
                        : { color: "" }
                    }
                  >
                    {" "}
                    Update Your Profile
                  </span>
                </Link>
              </li>
              <li
                className={
                  hover === "participated-in-retreats"
                    ? `nav-item hover-open `
                    : "nav-item"
                }
                onMouseOut={() => setHover("")}
                onClick={props.RightNav}
              >
                <Link
                  className="nav-link"
                  to="/participated-in-retreats"
                  onMouseOver={() => ShowSideTitle("participated-in-retreats")}
                >
                  <i
                    className="menu-icon mdi mdi-church"
                    style={
                      hover === "participated-in-retreats"
                        ? { color: "#CCA91D" }
                        : { color: "" }
                    }
                  ></i>
                  <span
                    className="menu-title"
                    style={
                      hover === "participated-in-retreats"
                        ? { color: "#CCA91D" }
                        : { color: "" }
                    }
                  >
                    {" "}
                    Participated In Retreats
                  </span>
                </Link>
              </li>
            </>
          ) : null}

          {userData && userData.access === true ? (
            <li
              className={
                hover === "Retreat Registrations"
                  ? `nav-item hover-open `
                  : "nav-item"
              }
              onMouseOut={() => setHover("")}
              onClick={props.RightNav}
            >
              <Link
                className="nav-link"
                to="/retreat-registrations"
                onMouseOver={() => ShowSideTitle("Retreat Registrations")}
              >
                <i
                  className="menu-icon mdi mdi-checkbox-multiple-marked"
                  style={
                    hover === "Retreat Registrations"
                      ? { color: "#CCA91D" }
                      : { color: "" }
                  }
                ></i>
                <span
                  className="menu-title"
                  style={
                    hover === "Retreat Registrations"
                      ? { color: "#CCA91D" }
                      : { color: "" }
                  }
                >
                  Retreat Registrations
                </span>
              </Link>
            </li>
          ) : null}
        </ul>
      ) : null}

      {userData &&
      userData.userRole === "SUBADMIN" &&
      userData.isDisabled === 0 ? (
        <>
          {" "}
          <ul className="nav" onClick={props.RightNav}>
            {/* <li
              className={
                hover === "quickReport" ? `nav-item hover-open ` : "nav-item"
              }
              onClick={props.RightNav}
            >
              <Link
                className="nav-link"
                to="/quickReport"
                onMouseOver={() => ShowSideTitle("quickReport")}
              >
                <i
                  className="mdi mdi-grid-large menu-icon"
                  style={
                    hover === "quickReport"
                      ? { color: "#CCA91D" }
                      : { color: "" }
                  }
                ></i>
                <span
                  className="menu-title"
                  style={
                    hover === "quickReport"
                      ? { color: "#CCA91D" }
                      : { color: "" }
                  }
                >
                  Quick Reports
                </span>
              </Link>
            </li>

            <li
              className={
                hover === "search_data" ? `nav-item hover-open ` : "nav-item"
              }
              onClick={props.RightNav}
            >
              <Link
                className="nav-link"
                to="/search_data"
                onMouseOver={() => ShowSideTitle("search_data")}
              >
                <i
                  className="menu-icon mdi mdi-magnify"
                  style={
                    hover === "search_data"
                      ? { color: "#CCA91D" }
                      : { color: "" }
                  }
                ></i>
                <span
                  className="menu-title"
                  style={
                    hover === "search_data"
                      ? { color: "#CCA91D" }
                      : { color: "" }
                  }
                >
                  {" "}
                  Advanced Search
                </span>
              </Link>
            </li> */}
            <li
              className={
                hover === "update-couple" ? `nav-item hover-open ` : "nav-item"
              }
              onMouseOut={() => setHover("")}
              onClick={props.RightNav}
            >
              <Link
                className="nav-link"
                to="/update-couple"
                onMouseOver={() => ShowSideTitle("update-couple")}
              >
                <i
                  className="menu-icon mdi mdi-account-multiple"
                  style={
                    hover === "update-couple"
                      ? { color: "#CCA91D" }
                      : { color: "" }
                  }
                ></i>
                <span
                  className="menu-title"
                  style={
                    hover === "update-couple"
                      ? { color: "#CCA91D" }
                      : { color: "" }
                  }
                >
                  {" "}
                  Update Your Profile
                </span>
              </Link>
            </li>
            <li
              className={
                hover === "participated-in-retreats"
                  ? `nav-item hover-open `
                  : "nav-item"
              }
              onMouseOut={() => setHover("")}
              onClick={props.RightNav}
            >
              <Link
                className="nav-link"
                to="/participated-in-retreats"
                onMouseOver={() => ShowSideTitle("participated-in-retreats")}
              >
                <i
                  className="menu-icon mdi mdi-church"
                  style={
                    hover === "participated-in-retreats"
                      ? { color: "#CCA91D" }
                      : { color: "" }
                  }
                ></i>
                <span
                  className="menu-title"
                  style={
                    hover === "participated-in-retreats"
                      ? { color: "#CCA91D" }
                      : { color: "" }
                  }
                >
                  {" "}
                  Participated In Retreats
                </span>
              </Link>
            </li>
            <li
              className={
                hover === "manageCouples" ? `nav-item hover-open ` : "nav-item"
              }
              onClick={props.RightNav}
            >
              <Link
                className="nav-link"
                to="/manageCouples"
                onMouseOver={() => ShowSideTitle("manageCouples")}
              >
                <i
                  className="menu-icon mdi mdi-account-multiple"
                  style={
                    hover === "manageCouples"
                      ? { color: "#CCA91D" }
                      : { color: "" }
                  }
                ></i>
                <span
                  className="menu-title"
                  style={
                    hover === "manageCouples"
                      ? { color: "#CCA91D" }
                      : { color: "" }
                  }
                >
                  View Couples Data
                </span>
              </Link>
            </li>

            {/* <li
              className={
                hover === "manageRetreat" ? `nav-item hover-open ` : "nav-item"
              }
              onClick={props.RightNav}
            >
              <Link
                className="nav-link"
                to="/manageRetreat"
                onMouseOver={() => ShowSideTitle("manageRetreat")}
              >
                <i
                  className="menu-icon mdi mdi-church"
                  style={
                    hover === "manageRetreat"
                      ? { color: "#CCA91D" }
                      : { color: "" }
                  }
                ></i>
                <span
                  className="menu-title"
                  style={
                    hover === "manageRetreat"
                      ? { color: "#CCA91D" }
                      : { color: "" }
                  }
                >
                  View Retreats
                </span>
              </Link>
            </li> */}

            <li
              className={
                hover === "helper" ? `nav-item hover-open ` : "nav-item"
              }
              onClick={props.RightNav}
            >
              <a
                className="nav-link"
                href="https://lovestrongmarriage.com/?page_id=6"
                onMouseOver={() => ShowSideTitle("helper")}
                target="_blank"
              >
                <i
                  className="menu-icon mdi mdi-help-circle-outline"
                  style={
                    hover === "helper" ? { color: "#CCA91D" } : { color: "" }
                  }
                ></i>
                <span
                  className="menu-title"
                  style={
                    hover === "helper" ? { color: "#CCA91D" } : { color: "" }
                  }
                >
                  Help
                </span>
              </a>
            </li>

            {userData && userData.access === true ? (
              <li
                className={
                  hover === "Retreat Registrations"
                    ? `nav-item border-top hover-open `
                    : "nav-item border-top"
                }
                onMouseOut={() => setHover("")}
                onClick={props.RightNav}
              >
                <Link
                  className="nav-link"
                  to="/retreat-registrations"
                  onMouseOver={() => ShowSideTitle("Retreat Registrations")}
                >
                  <i
                    className="menu-icon mdi mdi-checkbox-multiple-marked"
                    style={
                      hover === "Retreat Registrations"
                        ? { color: "#CCA91D" }
                        : { color: "" }
                    }
                  ></i>
                  <span
                    className="menu-title"
                    style={
                      hover === "Retreat Registrations"
                        ? { color: "#CCA91D" }
                        : { color: "" }
                    }
                  >
                    Retreat Registrations
                  </span>
                </Link>
              </li>
            ) : null}
          </ul>{" "}
          {/* <ul className="nav" onClick={props.RightNav}>
            <li
              className={
                hover === "update-couple" ? `nav-item hover-open ` : "nav-item"
              }
              onClick={props.RightNav}
            >
              <Link
                className="nav-link"
                to="/update-couple"
                onMouseOver={() => ShowSideTitle("update-couple")}
              >
                <i
                  className="menu-icon mdi mdi-church"
                  style={
                    hover === "update-couple"
                      ? { color: "#CCA91D" }
                      : { color: "" }
                  }
                ></i>
                <span
                  className="menu-title"
                  style={
                    hover === "update-couple"
                      ? { color: "#CCA91D" }
                      : { color: "" }
                  }
                >
                  Update Your Profile
                </span>
              </Link>
            </li>

            <li
              className={
                hover === "participated-in-retreats"
                  ? `nav-item hover-open `
                  : "nav-item"
              }
              onClick={props.RightNav}
            >
              <Link
                className="nav-link"
                to="/participated-in-retreats"
                onMouseOver={() => ShowSideTitle("participated-in-retreats")}
              >
                <i
                  className="menu-icon mdi mdi-church"
                  style={
                    hover === "participated-in-retreats"
                      ? { color: "#CCA91D" }
                      : { color: "" }
                  }
                ></i>
                <span
                  className="menu-title"
                  style={
                    hover === "participated-in-retreats"
                      ? { color: "#CCA91D" }
                      : { color: "" }
                  }
                >
                  Participated In Retreats
                </span>
              </Link>
            </li>
          </ul> */}
        </>
      ) : null}

      {userData &&
      userData.userRole === "SUBADMIN" &&
      userData.isDisabled === 1 ? (
        <ul className="nav" onClick={props.RightNav}>
          {userData && userData.coupleId !== null ? (
            <>
              {" "}
              <li
                className={
                  hover === "update-couple"
                    ? `nav-item hover-open `
                    : "nav-item"
                }
                onMouseOut={() => setHover("")}
                onClick={props.RightNav}
              >
                <Link
                  className="nav-link"
                  to="/update-couple"
                  onMouseOver={() => ShowSideTitle("update-couple")}
                >
                  <i
                    className="menu-icon mdi mdi-account-multiple"
                    style={
                      hover === "update-couple"
                        ? { color: "#CCA91D" }
                        : { color: "" }
                    }
                  ></i>
                  <span
                    className="menu-title"
                    style={
                      hover === "update-couple"
                        ? { color: "#CCA91D" }
                        : { color: "" }
                    }
                  >
                    {" "}
                    Update Your Profile
                  </span>
                </Link>
              </li>
              <li
                className={
                  hover === "participated-in-retreats"
                    ? `nav-item hover-open `
                    : "nav-item"
                }
                onMouseOut={() => setHover("")}
                onClick={props.RightNav}
              >
                <Link
                  className="nav-link"
                  to="/participated-in-retreats"
                  onMouseOver={() => ShowSideTitle("participated-in-retreats")}
                >
                  <i
                    className="menu-icon mdi mdi-church"
                    style={
                      hover === "participated-in-retreats"
                        ? { color: "#CCA91D" }
                        : { color: "" }
                    }
                  ></i>
                  <span
                    className="menu-title"
                    style={
                      hover === "participated-in-retreats"
                        ? { color: "#CCA91D" }
                        : { color: "" }
                    }
                  >
                    {" "}
                    Participated In Retreats
                  </span>
                </Link>
              </li>
            </>
          ) : null}

          {userData && userData.access === true ? (
            <li
              className={
                hover === "Retreat Registrations"
                  ? `nav-item hover-open `
                  : "nav-item"
              }
              onMouseOut={() => setHover("")}
              onClick={props.RightNav}
            >
              <Link
                className="nav-link"
                to="/retreat-registrations"
                onMouseOver={() => ShowSideTitle("Retreat Registrations")}
              >
                <i
                  className="menu-icon mdi mdi-checkbox-multiple-marked"
                  style={
                    hover === "Retreat Registrations"
                      ? { color: "#CCA91D" }
                      : { color: "" }
                  }
                ></i>
                <span
                  className="menu-title"
                  style={
                    hover === "Retreat Registrations"
                      ? { color: "#CCA91D" }
                      : { color: "" }
                  }
                >
                  Retreat Registrations
                </span>
              </Link>
            </li>
          ) : null}
        </ul>
      ) : null}

      {userData && userData.userRole === "COUPLE" ? (
        <ul className="nav" onClick={props.RightNav}>
          {userData && userData.coupleId !== null ? (
            <>
              {" "}
              <li
                className={
                  hover === "update-couple"
                    ? `nav-item hover-open `
                    : "nav-item"
                }
                onMouseOut={() => setHover("")}
                onClick={props.RightNav}
              >
                <Link
                  className="nav-link"
                  to="/update-couple"
                  onMouseOver={() => ShowSideTitle("update-couple")}
                >
                  <i
                    className="menu-icon mdi mdi-account-multiple"
                    style={
                      hover === "update-couple"
                        ? { color: "#CCA91D" }
                        : { color: "" }
                    }
                  ></i>
                  <span
                    className="menu-title"
                    style={
                      hover === "update-couple"
                        ? { color: "#CCA91D" }
                        : { color: "" }
                    }
                  >
                    {" "}
                    Update Your Profile
                  </span>
                </Link>
              </li>
              <li
                className={
                  hover === "participated-in-retreats"
                    ? `nav-item hover-open `
                    : "nav-item"
                }
                onMouseOut={() => setHover("")}
                onClick={props.RightNav}
              >
                <Link
                  className="nav-link"
                  to="/participated-in-retreats"
                  onMouseOver={() => ShowSideTitle("participated-in-retreats")}
                >
                  <i
                    className="menu-icon mdi mdi-church"
                    style={
                      hover === "participated-in-retreats"
                        ? { color: "#CCA91D" }
                        : { color: "" }
                    }
                  ></i>
                  <span
                    className="menu-title"
                    style={
                      hover === "participated-in-retreats"
                        ? { color: "#CCA91D" }
                        : { color: "" }
                    }
                  >
                    {" "}
                    Participated In Retreats
                  </span>
                </Link>
              </li>
            </>
          ) : null}

          {userData && userData.access === true && userData.isDisabled === 0 ? (
            <li
              className={
                hover === "Retreat Registrations"
                  ? `nav-item hover-open `
                  : "nav-item"
              }
              onMouseOut={() => setHover("")}
              onClick={props.RightNav}
            >
              <Link
                className="nav-link"
                to="/retreat-registrations"
                onMouseOver={() => ShowSideTitle("Retreat Registrations")}
              >
                <i
                  className="menu-icon mdi mdi-checkbox-multiple-marked"
                  style={
                    hover === "Retreat Registrations"
                      ? { color: "#CCA91D" }
                      : { color: "" }
                  }
                ></i>
                <span
                  className="menu-title"
                  style={
                    hover === "Retreat Registrations"
                      ? { color: "#CCA91D" }
                      : { color: "" }
                  }
                >
                  Retreat Registrations
                </span>
              </Link>
            </li>
          ) : null}
        </ul>
      ) : null}
    </nav>
  );
};
export default SideBar;
