import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Button,
  Modal,
  Tab,
  Tabs,
  OverlayTrigger,
  Tooltip,
  Accordion,
  Popover,
  Dropdown,
} from "react-bootstrap";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import AdminApi from "../../api/admin";
import SendEmailApi from "../../api/SendEmailApi";
import { toast } from "react-toastify";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { MultiSelect } from "react-multi-select-component";
import CustomDataTable from "../../components/CustomDataTable";
import { Link } from "react-router-dom";
import ServiceApi from "../../api/ServiceApi";
import AuthApi from "../../api/authApi";
import RolesApi from "../../api/RolesApi";
import TalksApi from "../../api/TalksApi";
import PerishApi from "../../api/perishApi";
import RetreatApi from "../../api/retreatApi";
import DatePicker from "react-datepicker";
import Helpers from "../../components/Helpers";
import RetreatRoastApi from "./../../api/retreatRoasterApi";
import moment from "moment";
import { utils, writeFile } from "xlsx";
import jsPDF from "jspdf";
import Select from "react-select";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Cookies, useCookies } from "react-cookie";
import Loader from "../../components/loader";
import Profile from "../../assets/images/couple-default.png";
import UpdateCouplePopUp from "../common/UpdateCouplePopUp";

const SearchData = () => {
  let navigate = useNavigate();
  const location1 = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();
  const [validated, setValidated] = useState(false);
  const [mailshow, setMailShow] = useState(false);
  const [show, setShow] = useState(false);
  const [mail_id, SetMail_id] = useState("");
  const [sendEmailList, setSendEmailList] = useState([]);
  const [cp, setCp] = useState(false);
  const [composedMails, setComposedMails] = useState([]);
  const [allCoupleData, setAllCoupleData] = useState([]);
  const [allCoupleList, setAllCoupleList] = useState([]);
  const [selectedData, setselectedData] = useState([]);
  const [serviceDataList, setServiceDataList] = useState([]);
  const [rolesDataList, setrolesDataList] = useState([]);
  const [talksDataList, setTalksDataList] = useState([]);
  const [parishDataList, setParishDataList] = useState([]);
  const [allRetreatData, setAllRetreatData] = useState([]);
  const [selectRetreat, setSelectRetreat] = useState(null);
  const [selectCouple, setSelectCouple] = useState(null);
  const [selectParish, setSelectParish] = useState(null);
  const [couplesList, setCouplesList] = useState([]);
  const [retreatsList, setRetreatsList] = useState([]);
  const [roasterCount, setRoasterCount] = useState("");
  const [LoadData, setLoadData] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const initialEditorContent =
    "Hello ${firstName} ${lastName}, <br /> <strong>Here we are inviting you on the Retreat- </strong><br />Scott ,Robot and Maria <br />  March 2-3 , 2023 : St.Mattew's,Eng,San Antonio <br /> \n We look forward to see you there </br>\n   Thank you! </br> LOVESTRONG Marriage.";
  const [defaultEditorContent, setDefaultEdtitorContent] =
    useState(initialEditorContent);
  const [maildata, setMailData] = useState({
    couple: [],
    subject: "",
    message: "",
  });
  const [ciytState, setCityState] = useState([]);
  const [selectedStateCity, setSelectedStateCity] = useState([]);
  const [location, setLocation] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedZips, setSelectedZips] = useState([]);
  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;
  const day = "01";
  const searchInitial = {
    retreatId: 0,
    parisheId: 0,
    coupleId: 0,
    cityState: [],
    location: [],
    primaryKey: 0,
    dateFrom: "",
    dateTo: "",
    retreatAttended: false,
    language: 0,
    serviceIds: [],
    roleIds: [],
    talkIds: [],
    accountStatus: [],
    neverServedTeam: false,
    neverServiceRole: false,
    neverTalk: false,
    retreatants: [],
    searchFor: "retreat",
    zip: [],
  };

  const [searchQuery, setSearchQuery] = useState(searchInitial);
  const [Message, setMessage] = useState(defaultEditorContent);
  let adminApi = new AdminApi();
  let authApi = new AuthApi();
  let serviceApi = new ServiceApi();
  let sendEmailApi = new SendEmailApi();
  let rolesApi = new RolesApi();
  let talksApi = new TalksApi();
  let perishApi = new PerishApi();
  let retreatApi = new RetreatApi();
  let cookies = new Cookies();
  let userData = cookies.get("LoveStrongUser") || null;
  const [cookie, setCookie] = useCookies(["LoveStrongUser"]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isShowData, setIsShowData] = useState(false);
  const [noRetAttend, setNoRetAttend] = useState(false);
  const [coupWithAttenType, setCoupWithAttenType] = useState(false);
  const [allDates, setAllDates] = useState(true);
  const [searchFor, setSearchFor] = useState("retreat");
  let reportId = searchParams.get("reportId");
  const scollToRef = useRef();
  const [roleTextArray, setRoleTextArray] = useState([]);
  const [serviceTextArray, setServiceTextArray] = useState([]);
  const [talkTextArray, setTalkTextArray] = useState([]);
  const [open1, setOpen1] = useState(true);
  const [showRegModal, setShowREgModal] = useState(false);
  const [ediUserIdClicked, setEdiUserIdClicked] = useState("");
  const [isEditAdmin, setIsEditAdmin] = useState(false);
  const [roast_id, setRoast_id] = useState("");
  const [roastersOfCoupleList, setRoastersOfCoupleList] = useState([]);
  const [quickSearchFor, setQuickSearchFor] = useState("");
  const [dataCount, setDataCount] = useState([]);
  const [oldValues, setOldValues] = useState([]);
  const [zip, setZip] = useState([]);
  const [retreatOptions, setRetreatOptions] = useState([]);
  const [selectedRetreats, setSelectedRetreats] = useState([]);
  const [parishOptions, setParishOptions] = useState([]);
  const [selectedParishes, setSelectedParishes] = useState([]);
  const [selectLanguage, setSelectLanguage] = useState([]);
  const [languagesOptions, setLanguagesOptions] = useState([
    { label: "English", value: "english" },
    { label: "Spanish", value: "spanish" },
  ]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [coupleOptions, setCoupleOptions] = useState([]);
  const [selectedCouples, setSelectedCouples] = useState([]);
  const [isTempUpload, setIsTempUpload] = useState(false);
  const [isTempUpload2, setIsTempUpload2] = useState(false);

  const [data, setData] = useState({
    id: "",
    hisFirstName: "",
    hisLastName: "",
    hisEmail: "",
    hisMobile: "",
    herFirstName: "",
    herLastName: "",
    herEmail: "",
    herMobile: "",
    city: "",
    state: "",
    zip: "",
    notes: "",
    imageUrl: "",
    // marriageImageUrl: "",
    parishId: null,
    address: "",
    allergies: "",
    anniversary_date: "",
    under_age_35: "NO",
    emergency_name1: "",
    emergency_relationship1: "",
    emergency_phone1: "",
    emergency_name2: "",
    emergency_relationship2: "",
    emergency_phone2: "",
    hear_us: [],
    referral_from_a_friend_or_family_member: "",
  });
  const [alreadyPk, setAlreadyPk] = useState();
  const handleShow = (item) => {
    setRoast_id(item);
    setShow(true);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip">{props}</Tooltip>
  );

  useEffect(() => {
    getPerishList();
  }, [searchFor]);

  const popover = (row) => {
    return (
      <Popover id="popover-ImgPk" bsPrefix="popover">
        <Popover.Body>
          <div style={{ textAlign: "center", padding: "10px 0px" }}>
            {row.imageUrl ? (
              <span>
                <img src={row.imageUrl} className="column-rowImagePopover" />
              </span>
            ) : (
              <img src={Profile} className="column-rowImagePopover" />
            )}
            <h6 className="couplename">
              {" "}
              {row &&
                row.hisLastName +
                  ", " +
                  row.hisFirstName +
                  " and " +
                  row.herFirstName}
            </h6>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  useEffect(() => {
    getPerishList();
  }, [searchFor]);

  const retreatsColumns = [
    {
      id: "title",
      name: "Retreat Title",
      selector: (row) => row.title,
      cell: (row) => (
        <>
          <a
            href={`/retreatRoaster?retreatId=${row.retreatId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              {`${
                row.title !== null && row.title != ""
                  ? `${row.title.charAt(0).toUpperCase() + row.title.slice(1)}`
                  : ""
              }`}
              {/* ${row.language !== null && row.language != '' ? `${row.language.charAt(0).toUpperCase() + row.language.slice(1) + ", "}` : ''}
              ${row.location !== null && row.location != '' ? `${row.location.charAt(0).toUpperCase() + row.location.slice(1)} ` : ''}  */}
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip(
                  moment(row.dateFrom).format("MMM DD") +
                    " - " +
                    moment(row.dateTo).format("MMM DD") +
                    ", " +
                    moment(row.dateFrom).format("YYYY")
                )}
              >
                <span>
                  {moment(row.dateFrom).format("MMM DD") +
                    " - " +
                    moment(row.dateTo).format("MMM DD") +
                    ", " +
                    moment(row.dateFrom).format("YYYY")}
                </span>
              </OverlayTrigger>
            </div>
          </a>
        </>
      ),
      sortable: true,
      reorder: true,
      width: "300px",
    },
    {
      id: "language",
      name: "Language",
      selector: (row) =>
        row.language.charAt(0).toUpperCase() + row.language.slice(1),
      sortable: true,
      reorder: true,
      width: "120px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "location",
      name: "Location",
      selector: (row) =>
        row.location.charAt(0).toUpperCase() + row.location.slice(1),
      sortable: true,
      reorder: true,
      width: "130px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "team",
      name: "# Team Couples",
      selector: (row) => row && row.countTeam,
      cell: (row) => (
        <div className="tooltip-static-demo">
          <div
            className="bs-tooltip-bottom bs-tooltip-bottom-demo tooltip-info"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title=""
            data-bs-original-title="Decorations, Facilities, Go Team, Kitchen"
          >
            <div className="arrow"></div>
            <a
              href={`/retreatRoaster?retreatId=${row.retreatId}&flag=2`}
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="tooltip-inner">{row && row.countTeam}</div>
            </a>
          </div>
        </div>
      ),
      reorder: true,
      sortable: true,
      width: "200px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Retreatants",
      name: "# Retreatant Couples",
      selector: (row) => row && row.countRetreatant,
      cell: (row) => (
        <div className="tooltip-static-demo">
          <div
            className="bs-tooltip-bottom bs-tooltip-bottom-demo tooltip-info"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title=""
            data-bs-original-title="Decorations, Facilities, Go Team, Kitchen"
          >
            <div className="arrow"></div>
            <a
              href={`/retreatRoaster?retreatId=${row.retreatId}&flag=1`}
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="tooltip-inner">{row && row.countRetreatant}</div>
            </a>
          </div>
        </div>
      ),
      reorder: true,
      sortable: true,
      width: "250px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "fromToDate",
      name: "Retreat Dates",
      selector: (row) =>
        moment(row.dateFrom).format("MMM DD") +
        "-" +
        moment(row.dateTo).format("MMM DD") +
        "," +
        moment(row.dateFrom).format("YYYY"),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            moment(row.dateFrom).format("MMM DD") +
              "-" +
              moment(row.dateTo).format("MMM DD") +
              "," +
              moment(row.dateFrom).format("YYYY")
          )}
        >
          <span>
            {moment(row.dateFrom).format("MMM DD") +
              "-" +
              moment(row.dateTo).format("MMM DD") +
              "," +
              moment(row.dateFrom).format("YYYY")}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      width: "150px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "heading",
      name: "Retreat Theme",
      selector: (row) => row.heading,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.heading)}
        >
          <span>
            {row.heading &&
              row.heading.slice(0, 15) + (row.heading.length > 15 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
    },
    {
      id: "created",
      name: "Created",
      selector: (row) =>
        Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY"),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY hh:mm:a")
          )}
        >
          <span>
            {Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY")}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      width: "130px",
      style: {
        cursor: "text",
      },
    },
  ];

  const retreatColWithCouple = [
    {
      id: "primaryKey",
      name: "Couple Id",
      selector: (row) => row.primaryKey,
      cell: (row) => (
        <OverlayTrigger
          placement="right"
          // delay={{ show: 250, hide: 400 }}
          overlay={popover(row)}
        >
          {row.imageUrl ? (
            <span
              style={{ fontSize: "14px" }}
              onClick={() => {
                editCouple(row.coupleId);
                setShowREgModal(true);
                getroastersOfCouple(row.coupleId);
              }}
            >
              <span className="roasterKey">
                {" "}
                <img src={row.imageUrl} className="column-rowImage" />
              </span>

              {row.primaryKey && row.primaryKey}
            </span>
          ) : (
            <span
              style={{ fontSize: "14px" }}
              onClick={() => {
                editCouple(row.coupleId);
                setShowREgModal(true);
                getroastersOfCouple(row.coupleId);
              }}
            >
              <img src={Profile} className="column-rowImage-profile" />
              {row.primaryKey && row.primaryKey}
            </span>
          )}
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "130px",
    },
    {
      id: "hisLastName",
      name: "His Last Name",
      selector: (row) => row.hisLastName,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.hisLastName)}
        >
          <span
            style={{ fontSize: "16px" }}
            onClick={() => {
              editCouple(row.coupleId);
              setShowREgModal(true);
              getroastersOfCouple(row.coupleId);
            }}
          >
            {row.hisLastName ? row.hisLastName : ""}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      style: {
        backgroundColor: "#E0F8FE",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
      width: "150px",
    },
    {
      id: "hisFirstName",
      name: "His First Name",
      selector: (row) => row.hisFirstName,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.hisFirstName)}
        >
          <span
            style={{ fontSize: "16px" }}
            onClick={() => {
              editCouple(row.coupleId);
              setShowREgModal(true);
              getroastersOfCouple(row.coupleId);
            }}
          >
            {row.hisFirstName ? row.hisFirstName : ""}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      style: {
        backgroundColor: "#E0F8FE",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
      width: "150px",
    },
    {
      id: "hisEmail",
      name: "His Email",
      selector: (row) => row.hisEmail,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.hisEmail)}
        >
          <a style={{ fontSize: "14px" }} href={`mailto:${row.hisEmail}`}>
            {" "}
            <span>{row.hisEmail ? row.hisEmail : ""}</span>
          </a>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      style: {
        backgroundColor: "#E0F8FE",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
      width: "170px",
    },
    {
      id: "hisMobile",
      name: "His Mobile",
      selector: (row) => row.hisMobile,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.hisMobile)}
        >
          <span>
            {row.hisMobile &&
              row.hisMobile.slice(0, 10) +
                (row.hisMobile.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        backgroundColor: "#E0F8FE",
        cursor: "text",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
      width: "150px",
    },
    {
      id: "herLastName",
      name: "Her Last Name",
      selector: (row) => row.herLastName,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.herLastName)}
        >
          <span
            style={{ fontSize: "16px" }}
            onClick={() => {
              editCouple(row.coupleId);
              setShowREgModal(true);
              getroastersOfCouple(row.coupleId);
            }}
          >
            {row.herLastName ? row.herLastName : ""}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      style: {
        backgroundColor: "#FFEDFB",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
      width: "150px",
    },
    {
      id: "herFirstName",
      name: "Her First Name",
      selector: (row) => row.herFirstName,
      cell: (row) => (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.herFirstName)}
        >
          <span
            style={{ fontSize: "16px" }}
            onClick={() => {
              editCouple(row.coupleId);
              setShowREgModal(true);
              getroastersOfCouple(row.coupleId);
            }}
          >
            {row.herFirstName ? row.herFirstName : ""}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      style: {
        backgroundColor: "#FFEDFB",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
      width: "120px",
    },
    {
      id: "herEmail",
      name: "Her Email",
      selector: (row) => row.herEmail,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.herEmail)}
        >
          <a style={{ fontSize: "14px" }} href={`mailto:${row.herEmail}`}>
            <span>{row.herEmail ? row.herEmail : ""}</span>
          </a>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      style: {
        backgroundColor: "#FFEDFB",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
      width: "170px",
    },
    {
      id: "herMobile",
      name: "Her Mobile",
      selector: (row) => row.herMobile,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.herMobile)}
        >
          <span>
            {row.herMobile &&
              row.herMobile.slice(0, 10) +
                (row.herMobile.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        backgroundColor: "#FFEDFB",
        cursor: "text",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
    },
    {
      id: "AttendeeType",
      name: "Type",
      selector: (row) => row && row.attendeeType,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.attendeeType)}
        >
          <span style={{ fontSize: "14px" }}>
            {row.attendeeType ? row.attendeeType : ""}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      width: "130px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "parish",
      name: "Parish",
      selector: (row) => row.coupleParish,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.coupleParish)}
        >
          <span style={{ fontSize: "14px" }}>
            {row.coupleParish ? row.coupleParish : ""}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      width: "150px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Role",
      name: "Role",
      selector: (row) => row && row.role,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.role)}
        >
          <span style={{ fontSize: "14px" }}>{row.role ? row.role : ""}</span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      width: "150px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Service Title",
      name: "Service Title",
      selector: (row) => row && row.service,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.service)}
        >
          <span style={{ fontSize: "14px" }}>
            {row.service ? row.service : ""}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      width: "150px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Talk",
      name: "Talk",
      selector: (row) => row && row.talk,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.talk)}
        >
          <span style={{ fontSize: "14px" }}>{row.talk ? row.talk : ""}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "190px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "title",
      name: "Retreat Title",
      selector: (row) =>
        moment(row.dateFrom).format("MMM DD") +
        " - " +
        moment(row.dateTo).format("MMM DD") +
        ", " +
        moment(row.dateFrom).format("YYYY"),
      cell: (row) => (
        <>
          <a
            style={{ fontSize: "14px" }}
            href={`/retreatRoaster?retreatId=${row.retreatId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              {/* <Parishname>, <language>, <cityname> <month year>  */}
              {`${
                row.title !== null && row.title != ""
                  ? `${row.title.charAt(0).toUpperCase() + row.title.slice(1)}`
                  : ""
              }`}
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip(
                  moment(row.dateFrom).format("MMM DD") +
                    " - " +
                    moment(row.dateTo).format("MMM DD") +
                    ", " +
                    moment(row.dateFrom).format("YYYY")
                )}
              >
                <span>
                  {moment(row.dateFrom).format("MMM DD") +
                    " - " +
                    moment(row.dateTo).format("MMM DD") +
                    ", " +
                    moment(row.dateFrom).format("YYYY")}
                </span>
              </OverlayTrigger>
            </div>
          </a>
        </>
      ),
      sortable: true,
      reorder: true,
      width: "300px",
    },
    {
      id: "Address",
      name: "Address",
      selector: (row) => row.address,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.address)}
        >
          <span>
            {row.address &&
              row.address.slice(0, 10) + (row.address.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "city",
      name: "City",
      selector: (row) => row.city,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.city)}
        >
          <span style={{ fontSize: "14px" }}>{row.city ? row.city : ""}</span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "state",
      name: "State",
      selector: (row) => row.state,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.state)}
        >
          <span style={{ fontSize: "14px" }}>{row.state ? row.state : ""}</span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "zip",
      name: "Zip",
      selector: (row) => row.zip,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.zip)}
        >
          <span style={{ fontSize: "14px" }}>{row.zip ? row.zip : ""}</span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Anniversary Date",
      name: "Anniversary Date",
      selector: (row) =>
        Helpers.convertUtcToTimezone(row.anniversary_date, "MM-DD"),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            Helpers.convertUtcToTimezone(row.anniversary_date, "MM-DD")
          )}
        >
          <span>
            {row.anniversary_date
              ? Helpers.convertUtcToTimezone(row.anniversary_date, "MM-DD")
              : "--"}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    /*{
      id: "Dietary restrictions or physical limitations",
      name: "Dietary restrictions or physical limitations",
      selector: (row) => row.allergies,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.allergies)}
        >
          <span>
            {row.allergies &&
              row.allergies.slice(0, 10) +
                (row.allergies.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Name1",
      name: "Emergency Name1",
      selector: (row) => row.emergency_name1,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_name1)}
        >
          <span>
            {row.emergency_name1 &&
              row.emergency_name1.slice(0, 12) +
                (row.emergency_name1.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Relationship1",
      name: "Emergency Relationship1",
      selector: (row) => row.emergency_relationship1,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_relationship1)}
        >
          <span>
            {row.emergency_relationship1 &&
              row.emergency_relationship1.slice(0, 12) +
                (row.emergency_relationship1.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Phone1",
      name: "Emergency Phone1",
      selector: (row) => row.emergency_phone1,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_phone1)}
        >
          <span>
            {row.emergency_phone1 &&
              row.emergency_phone1.slice(0, 12) +
                (row.emergency_phone1.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Name2",
      name: "Emergency Name2",
      selector: (row) => row.emergency_name2,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_name2)}
        >
          <span>
            {row.emergency_name2 &&
              row.emergency_name2.slice(0, 12) +
                (row.emergency_name2.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Relationship2",
      name: "Emergency Relationship2",
      selector: (row) => row.emergency_relationship2,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_relationship2)}
        >
          <span>
            {row.emergency_relationship2 &&
              row.emergency_relationship2.slice(0, 12) +
                (row.emergency_relationship2.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Phone2",
      name: "Emergency Phone2",
      selector: (row) => row.emergency_phone2,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_phone2)}
        >
          <span>
            {row.emergency_phone2 &&
              row.emergency_phone2.slice(0, 12) +
                (row.emergency_phone2.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Hear about us",
      name: "Hear about us",
      selector: (row) => row.hear_us,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            row.hear_us &&
              row.hear_us
                .slice(2, -2)
                .split('","')
                .map((source) => source.replace(/\\"/g, ""))
                .join(", ")
          )}
        >
          <span>
            {row.hear_us &&
              row.hear_us
                .slice(2, -2)
                .split('","')
                .map((source) => source.replace(/\\"/g, ""))
                .join(", ")
                .slice(0, 12) + (row.hear_us.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Referral from a friend/Family member",
      name: "Referral from a friend/Family member",
      selector: (row) => row.referral_from_a_friend_or_family_member,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.referral_from_a_friend_or_family_member)}
        >
          <span>
            {row.referral_from_a_friend_or_family_member &&
              row.referral_from_a_friend_or_family_member.slice(0, 12) +
                (row.referral_from_a_friend_or_family_member.length > 12
                  ? "..."
                  : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Under Age 35",
      name: "Under Age 35",
      selector: (row) => row.under_age_35,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.under_age_35)}
        >
          <span>
            {row.under_age_35 &&
              row.under_age_35.slice(0, 12) +
                (row.under_age_35.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "notes",
      name: "Notes",
      selector: (row) => row.notes,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.notes)}
        >
          <span style={{ fontSize: "14px" }}>{row.notes ? row.notes : ""}</span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      width: "300px",
      style: {
        cursor: "text",
      },
    },*/
    {
      id: "fromToDate",
      name: "Retreat Dates",
      selecter: (row) =>
        moment(row.dateFrom).format("MMM DD") +
        "-" +
        moment(row.dateTo).format("MMM DD") +
        "," +
        moment(row.dateFrom).format("YYYY"),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            moment(row.dateFrom).format("MMM DD") +
              "-" +
              moment(row.dateTo).format("MMM DD") +
              "," +
              moment(row.dateFrom).format("YYYY")
          )}
        >
          <span style={{ fontSize: "14px" }}>
            {moment(row.dateFrom).format("MMM DD") +
              "-" +
              moment(row.dateTo).format("MMM DD") +
              "," +
              moment(row.dateFrom).format("YYYY")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "200px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Status",
      name: "Status",
      selecter: (row) => row && row.accountStatus,
      cell: (row) => (
        <span style={{ fontSize: "12px" }}>
          {" "}
          {row.accountStatus &&
            row.accountStatus.charAt(0).toUpperCase() +
              row.accountStatus.slice(1).toLowerCase()}
        </span>
      ),
      sortable: true,
      reorder: true,
      width: "100px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "created",
      name: "Created",
      selector: (row) =>
        Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY"),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY hh:mm:a")
          )}
        >
          <span style={{ fontSize: "14px" }}>
            {Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY")}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      width: "110px",
      style: {
        cursor: "text",
      },
    },
  ];

  const coupleColWithRetAttend = [
    {
      id: "primaryKey",
      name: "Couple Id",
      selector: (row) => row.primaryKey,
      cell: (row) => (
        <OverlayTrigger
          placement="right"
          // delay={{ show: 250, hide: 400 }}
          overlay={popover(row)}
        >
          {row.imageUrl ? (
            <span
              onClick={() => {
                editCouple(row.coupleId);
                setShowREgModal(true);
                getroastersOfCouple(row.coupleId);
              }}
            >
              <span className="roasterKey">
                {" "}
                <img src={row.imageUrl} className="column-rowImage" />
              </span>

              {row.primaryKey && row.primaryKey}
            </span>
          ) : (
            <span
              onClick={() => {
                editCouple(row.coupleId);
                setShowREgModal(true);
                getroastersOfCouple(row.coupleId);
              }}
            >
              <img src={Profile} className="column-rowImage-profile" />
              {row.primaryKey && row.primaryKey}
            </span>
          )}
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "130px",
    },
    {
      id: "No retreat attend",
      name: "# Retreat Attended",
      selecter: (row) => row.retreatsCount,
      cell: (row) => (
        <a
          href={`/manageRetreat?coupleId=${row.coupleId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {row.retreatsCount}
        </a>
      ),
      reorder: true,
      sortable: true,
      width: "190px",
    },
    {
      id: "AttendeeType",
      name: "Type",
      selector: (row) => row && row.attendeeType,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.attendeeType)}
        >
          <span>{row.attendeeType ? row.attendeeType : ""}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "130px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "hisLastName",
      name: "His Last Name",
      selector: (row) => row.hisLastName,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.hisLastName)}
        >
          <span
            onClick={() => {
              editCouple(row.coupleId);
              setShowREgModal(true);
              getroastersOfCouple(row.coupleId);
            }}
          >
            {row.hisLastName ? row.hisLastName : ""}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      style: {
        backgroundColor: "#E0F8FE",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
      width: "140px",
    },
    {
      id: "hisFirstName",
      name: "His First Name",
      selector: (row) => row.hisFirstName,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.hisFirstName)}
        >
          <span
            onClick={() => {
              editCouple(row.coupleId);
              setShowREgModal(true);
              getroastersOfCouple(row.coupleId);
            }}
          >
            {row.hisFirstName ? row.hisFirstName : ""}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      style: {
        backgroundColor: "#E0F8FE",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
      style: {
        backgroundColor: "#E0F8FE",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
      width: "140px",
    },
    {
      id: "hisEmail",
      name: "His Email",
      selector: (row) => row.hisEmail,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.hisEmail)}
        >
          <a href={`mailto:${row.herEmail}`}>
            <span>
              {row.hisEmail &&
                row.hisEmail.slice(0, 12) +
                  (row.hisEmail.length > 12 ? "..." : "")}
            </span>
          </a>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        backgroundColor: "#E0F8FE",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
      style: {
        backgroundColor: "#E0F8FE",
        cursor: "text",
      },
    },
    {
      id: "hisMobile",
      name: "His Mobile",
      selector: (row) => row.hisMobile,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.hisMobile)}
        >
          <span>
            {row.hisMobile &&
              row.hisMobile.slice(0, 10) +
                (row.hisMobile.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        backgroundColor: "#E0F8FE",
        cursor: "text",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
    },
    {
      id: "herLastName",
      name: "Her Last Name",
      selector: (row) => row.herLastName,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.herLastName)}
        >
          <span
            onClick={() => {
              editCouple(row.coupleId);
              setShowREgModal(true);
              getroastersOfCouple(row.coupleId);
            }}
          >
            {row.herLastName ? row.herLastName : ""}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      style: {
        backgroundColor: "#FFEDFB",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
      width: "150px",
    },
    {
      id: "herFirstName",
      name: "Her First Name",
      selector: (row) => row.herFirstName,
      cell: (row) => (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.herFirstName)}
        >
          <span
            onClick={() => {
              editCouple(row.coupleId);
              setShowREgModal(true);
              getroastersOfCouple(row.coupleId);
            }}
          >
            {row.herFirstName ? row.herFirstName : ""}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      style: {
        backgroundColor: "#FFEDFB",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
      width: "140px",
    },
    {
      id: "herEmail",
      name: "Her Email",
      selector: (row) => row.herEmail,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.herEmail)}
        >
          <a href={`mailto:${row.herEmail}`}>
            <span>
              {row.herEmail &&
                row.herEmail.slice(0, 12) +
                  (row.herEmail.length > 12 ? "..." : "")}
            </span>
          </a>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        backgroundColor: "#FFEDFB",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
    },
    {
      id: "herMobile",
      name: "Her Mobile",
      selector: (row) => row.herMobile,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.herMobile)}
        >
          <span>
            {row.herMobile &&
              row.herMobile.slice(0, 10) +
                (row.herMobile.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        backgroundColor: "#FFEDFB",
        cursor: "text",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
    },
    {
      id: "parish",
      name: "Parish",
      selector: (row) => row.coupleParish,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.coupleParish)}
        >
          <span>{row.coupleParish ? row.coupleParish : ""}</span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      width: "150px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Role",
      name: "Role",
      selector: (row) => row && row.role,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.role)}
        >
          <span>{row.role ? row.role : ""}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "150px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Service Title",
      name: "Service Title",
      selector: (row) => row && row.service,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.service)}
        >
          <span>{row.service ? row.service : ""}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "150px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Talk",
      name: "Talk",
      selector: (row) => row && row.talk,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.talk)}
        >
          <span>{row.talk ? row.talk : ""}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "190px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "his email",
      name: "His Email",
      selector: (row) => row.hisEmail,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.hisEmail)}
        >
          <a href={`mailto:${row.hisEmail}`}>
            <span>{row.hisEmail ? row.hisEmail : ""}</span>
          </a>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      width: "170px",
    },
    {
      id: "her email",
      name: "Her Email",
      selector: (row) => row.herEmail,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.herEmail)}
        >
          <a href={`mailto:${row.herEmail}`}>
            <span>{row.herEmail ? row.herEmail : ""}</span>
          </a>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      width: "170px",
    },
    {
      id: "his mobile",
      name: "His Mobile",
      selector: (row) => row.hisMobile,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.hisMobile)}
        >
          <span>{row.hisMobile ? row.hisMobile : ""}</span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      width: "150px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "her mobile",
      name: "Her Mobile",
      selector: (row) => row.herMobile,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.herMobile)}
        >
          <span>{row.herMobile ? row.herMobile : ""}</span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      width: "150px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Address",
      name: "Address",
      selector: (row) => row.address,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.address)}
        >
          <span>
            {row.address &&
              row.address.slice(0, 10) + (row.address.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "city",
      name: "City",
      selector: (row) => row.city,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.city)}
        >
          <span>{row.city ? row.city : ""}</span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "state",
      name: "State",
      selector: (row) => row.state,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.state)}
        >
          <span>{row.state ? row.state : ""}</span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "zip",
      name: "Zip",
      selector: (row) => row.zip,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.zip)}
        >
          <span>{row.zip ? row.zip : ""}</span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Anniversary Date",
      name: "Anniversary Date",
      selector: (row) =>
        Helpers.convertUtcToTimezone(row.anniversary_date, "MM-DD"),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            Helpers.convertUtcToTimezone(row.anniversary_date, "MM-DD")
          )}
        >
          <span>
            {row.anniversary_date
              ? Helpers.convertUtcToTimezone(row.anniversary_date, "MM-DD")
              : "--"}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    /*{
      id: "Dietary restrictions or physical limitations",
      name: "Dietary restrictions or physical limitations",
      selector: (row) => row.allergies,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.allergies)}
        >
          <span>
            {row.allergies &&
              row.allergies.slice(0, 10) +
                (row.allergies.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Name1",
      name: "Emergency Name1",
      selector: (row) => row.emergency_name1,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_name1)}
        >
          <span>
            {row.emergency_name1 &&
              row.emergency_name1.slice(0, 12) +
                (row.emergency_name1.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Relationship1",
      name: "Emergency Relationship1",
      selector: (row) => row.emergency_relationship1,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_relationship1)}
        >
          <span>
            {row.emergency_relationship1 &&
              row.emergency_relationship1.slice(0, 12) +
                (row.emergency_relationship1.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Phone1",
      name: "Emergency Phone1",
      selector: (row) => row.emergency_phone1,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_phone1)}
        >
          <span>
            {row.emergency_phone1 &&
              row.emergency_phone1.slice(0, 12) +
                (row.emergency_phone1.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Name2",
      name: "Emergency Name2",
      selector: (row) => row.emergency_name2,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_name2)}
        >
          <span>
            {row.emergency_name2 &&
              row.emergency_name2.slice(0, 12) +
                (row.emergency_name2.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Relationship2",
      name: "Emergency Relationship2",
      selector: (row) => row.emergency_relationship2,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_relationship2)}
        >
          <span>
            {row.emergency_relationship2 &&
              row.emergency_relationship2.slice(0, 12) +
                (row.emergency_relationship2.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Phone2",
      name: "Emergency Phone2",
      selector: (row) => row.emergency_phone2,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_phone2)}
        >
          <span>
            {row.emergency_phone2 &&
              row.emergency_phone2.slice(0, 12) +
                (row.emergency_phone2.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Hear about us",
      name: "Hear about us",
      selector: (row) => row.hear_us,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            row.hear_us &&
              row.hear_us
                .slice(2, -2)
                .split('","')
                .map((source) => source.replace(/\\"/g, ""))
                .join(", ")
          )}
        >
          <span>
            {row.hear_us &&
              row.hear_us
                .slice(2, -2)
                .split('","')
                .map((source) => source.replace(/\\"/g, ""))
                .join(", ")
                .slice(0, 12) + (row.hear_us.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Referral from a friend/Family member",
      name: "Referral from a friend/Family member",
      selector: (row) => row.referral_from_a_friend_or_family_member,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.referral_from_a_friend_or_family_member)}
        >
          <span>
            {row.referral_from_a_friend_or_family_member &&
              row.referral_from_a_friend_or_family_member.slice(0, 12) +
                (row.referral_from_a_friend_or_family_member.length > 12
                  ? "..."
                  : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Under Age 35",
      name: "Under Age 35",
      selector: (row) => row.under_age_35,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.under_age_35)}
        >
          <span>
            {row.under_age_35 &&
              row.under_age_35.slice(0, 12) +
                (row.under_age_35.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "notes",
      name: "Notes",
      selector: (row) => row.notes,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.notes)}
        >
          <span>{row.notes ? row.notes : ""}</span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      width: "300px",
      style: {
        cursor: "text",
      },
    },*/
    {
      id: "fromToDate",
      name: "Retreat Dates",
      selector: (row) =>
        moment(row.dateFrom).format("MMM DD") +
        "-" +
        moment(row.dateTo).format("MMM DD") +
        "," +
        moment(row.dateFrom).format("YYYY"),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            moment(row.dateFrom).format("MMM DD") +
              "-" +
              moment(row.dateTo).format("MMM DD") +
              "," +
              moment(row.dateFrom).format("YYYY")
          )}
        >
          <span>
            {moment(row.dateFrom).format("MMM DD") +
              "-" +
              moment(row.dateTo).format("MMM DD") +
              "," +
              moment(row.dateFrom).format("YYYY")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "200px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Status",
      name: "Status",
      selector: (row) =>
        row.accountStatus &&
        row.accountStatus.charAt(0).toUpperCase() +
          row.accountStatus.slice(1).toLowerCase(),
      sortable: true,
      reorder: true,
      width: "100px",
      style: {
        cursor: "text",
      },
    },
  ];

  const coupleColWithAttendType = [
    {
      id: "primaryKey",
      name: "Couple Id",
      selector: (row) => row.primaryKey,
      cell: (row) => (
        <OverlayTrigger
          placement="right"
          // delay={{ show: 250, hide: 400 }}
          overlay={popover(row)}
        >
          {row.imageUrl ? (
            <span
              style={{ fontSize: "14px" }}
              onClick={() => {
                editCouple(row.coupleId);
                setShowREgModal(true);
                getroastersOfCouple(row.coupleId);
              }}
            >
              <span className="roasterKey">
                {" "}
                <img src={row.imageUrl} className="column-rowImage" />
              </span>
              {row.primaryKey && row.primaryKey}
            </span>
          ) : (
            <span
              style={{ fontSize: "14px" }}
              onClick={() => {
                editCouple(row.coupleId);
                setShowREgModal(true);
                getroastersOfCouple(row.coupleId);
              }}
            >
              <img src={Profile} className="column-rowImage-profile" />
              {row.primaryKey && row.primaryKey}
            </span>
          )}
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "130px",
    },
    {
      id: "hisLastName",
      name: "His Last Name",
      selector: (row) => row.hisLastName,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.hisLastName)}
        >
          <span
            style={{ fontSize: "16px" }}
            onClick={() => {
              editCouple(row.coupleId);
              setShowREgModal(true);
              getroastersOfCouple(row.coupleId);
            }}
          >
            {row.hisLastName ? row.hisLastName : ""}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      style: {
        backgroundColor: "#E0F8FE",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
      width: "140px",
    },
    {
      id: "hisFirstName",
      name: "His First Name",
      selector: (row) => row.hisFirstName,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.hisFirstName)}
        >
          <span
            style={{ fontSize: "16px" }}
            onClick={() => {
              editCouple(row.coupleId);
              setShowREgModal(true);
              getroastersOfCouple(row.coupleId);
            }}
          >
            {row.hisFirstName ? row.hisFirstName : ""}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      style: {
        backgroundColor: "#E0F8FE",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
      width: "140px",
    },
    {
      id: "hisEmail",
      name: "His Email",
      selector: (row) => row.hisEmail,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.hisEmail)}
        >
          <a style={{ fontSize: "14px" }} href={`mailto:${row.hisEmail}`}>
            <span>{row.hisEmail ? row.hisEmail : ""}</span>
          </a>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      style: {
        backgroundColor: "#E0F8FE",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
      width: "170px",
    },
    {
      id: "hisMobile",
      name: "His Mobile",
      selector: (row) => row.hisMobile,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.hisMobile)}
        >
          <span style={{ fontSize: "14px" }}>
            {row.hisMobile ? row.hisMobile : ""}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      style: {
        backgroundColor: "#E0F8FE",
        cursor: "text",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
      width: "150px",
    },
    {
      id: "herLastName",
      name: "Her Last Name",
      selector: (row) => row.herLastName,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.herLastName)}
        >
          <span
            style={{ fontSize: "16px" }}
            onClick={() => {
              editCouple(row.coupleId);
              setShowREgModal(true);
              getroastersOfCouple(row.coupleId);
            }}
          >
            {row.herLastName ? row.herLastName : ""}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      style: {
        backgroundColor: "#FFEDFB",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
      width: "150px",
    },
    {
      id: "herFirstName",
      name: "Her First Name",
      selector: (row) => row.herFirstName,
      cell: (row) => (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.herFirstName)}
        >
          <span
            style={{ fontSize: "16px" }}
            onClick={() => {
              editCouple(row.coupleId);
              setShowREgModal(true);
              getroastersOfCouple(row.coupleId);
            }}
          >
            {row.herFirstName ? row.herFirstName : ""}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      reorder: true,
      style: {
        backgroundColor: "#FFEDFB",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
      width: "140px",
    },
    {
      id: "herEmail",
      name: "Her Email",
      selector: (row) => row.herEmail,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.herEmail)}
        >
          <a style={{ fontSize: "14px" }} href={`mailto:${row.herEmail}`}>
            <span>{row.herEmail ? row.herEmail : ""}</span>
          </a>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      style: {
        backgroundColor: "#FFEDFB",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
      width: "150px",
    },
    {
      id: "herMobile",
      name: "Her Mobile",
      selector: (row) => row.herMobile,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.herMobile)}
        >
          <span style={{ fontSize: "14px" }}>
            {row.herMobile ? row.herMobile : ""}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      style: {
        backgroundColor: "#FFEDFB",
        cursor: "text",
      },
      conditionalCellStyles: [
        {
          when: (row) => row.accountStatus !== "ACTIVE",
          style: {
            backgroundColor: "#f8d7da",
            color: "#000",
            borderColor: "#dfc2c4",
          },
        },
      ],
      width: "150px",
    },
    {
      id: "talk",
      name: "Talk",
      selector: (row) => row && row.talk,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.talk)}
        >
          <span style={{ fontSize: "16px" }}>{row.talk ? row.talk : ""}</span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      width: "190px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "title",
      name: "Retreat Title",
      selector: (row) => row && row.title,
      cell: (row) => (
        <>
          <a
            style={{ fontSize: "14px" }}
            href={`/retreatRoaster?retreatId=${row.retreatId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              {/* <Parishname>, <language>, <cityname> <month year>  */}
              {`${
                row.title !== null && row.title != ""
                  ? `${row.title.charAt(0).toUpperCase() + row.title.slice(1)}`
                  : ""
              } `}
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip(
                  moment(row.dateFrom).format("MMM DD") +
                    " - " +
                    moment(row.dateTo).format("MMM DD") +
                    ", " +
                    moment(row.dateFrom).format("YYYY")
                )}
              >
                <span>
                  {moment(row.dateFrom).format("MMM DD") +
                    " - " +
                    moment(row.dateTo).format("MMM DD") +
                    ", " +
                    moment(row.dateFrom).format("YYYY")}
                </span>
              </OverlayTrigger>
            </div>
          </a>
        </>
      ),
      sortable: true,
      reorder: true,
      width: "300px",
    },
    {
      id: "AttendeeType",
      name: "Type",
      selector: (row) => row && row.attendeeType,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.attendeeType)}
        >
          <span style={{ fontSize: "14px" }}>
            {row.attendeeType ? row.attendeeType : ""}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "130px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "parish",
      name: "Parish",
      selector: (row) => row.coupleParish,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.coupleParish)}
        >
          <span>{row.coupleParish ? row.coupleParish : ""}</span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      width: "150px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "role",
      name: "Role",
      selector: (row) => row.role,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.role)}
        >
          <span style={{ fontSize: "14px" }}>{row.role ? row.role : ""}</span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      width: "150px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "service",
      name: "Service Title",
      selector: (row) => row.service,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.service)}
        >
          <span style={{ fontSize: "14px" }}>
            {row.service ? row.service : ""}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      width: "150px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Address",
      name: "Address",
      selector: (row) => row.address,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.address)}
        >
          <span>
            {row.address &&
              row.address.slice(0, 10) + (row.address.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "city",
      name: "City",
      selector: (row) => row.city,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.city)}
        >
          <span style={{ fontSize: "14px" }}>{row.city ? row.city : ""}</span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      width: "150px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "state",
      name: "State",
      selector: (row) => row.state,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.state)}
        >
          <span style={{ fontSize: "14px" }}>{row.state ? row.state : ""}</span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "zip",
      name: "Zip",
      selector: (row) => row.zip,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.zip)}
        >
          <span style={{ fontSize: "14px" }}>{row.zip ? row.zip : ""}</span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      style: {
        cursor: "text",
      },
    },

    {
      id: "Anniversary Date",
      name: "Anniversary Date",
      selector: (row) =>
        Helpers.convertUtcToTimezone(row.anniversary_date, "MM-DD"),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            Helpers.convertUtcToTimezone(row.anniversary_date, "MM-DD")
          )}
        >
          <span>
            {row.anniversary_date
              ? Helpers.convertUtcToTimezone(row.anniversary_date, "MM-DD")
              : "--"}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    /*{
      id: "Dietary restrictions or physical limitations",
      name: "Dietary restrictions or physical limitations",
      selector: (row) => row.allergies,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.allergies)}
        >
          <span>
            {row.allergies &&
              row.allergies.slice(0, 10) +
                (row.allergies.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Name1",
      name: "Emergency Name1",
      selector: (row) => row.emergency_name1,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_name1)}
        >
          <span>
            {row.emergency_name1 &&
              row.emergency_name1.slice(0, 12) +
                (row.emergency_name1.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Relationship1",
      name: "Emergency Relationship1",
      selector: (row) => row.emergency_relationship1,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_relationship1)}
        >
          <span>
            {row.emergency_relationship1 &&
              row.emergency_relationship1.slice(0, 12) +
                (row.emergency_relationship1.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Phone1",
      name: "Emergency Phone1",
      selector: (row) => row.emergency_phone1,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_phone1)}
        >
          <span>
            {row.emergency_phone1 &&
              row.emergency_phone1.slice(0, 12) +
                (row.emergency_phone1.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Name2",
      name: "Emergency Name2",
      selector: (row) => row.emergency_name2,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_name2)}
        >
          <span>
            {row.emergency_name2 &&
              row.emergency_name2.slice(0, 12) +
                (row.emergency_name2.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Relationship2",
      name: "Emergency Relationship2",
      selector: (row) => row.emergency_relationship2,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_relationship2)}
        >
          <span>
            {row.emergency_relationship2 &&
              row.emergency_relationship2.slice(0, 12) +
                (row.emergency_relationship2.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Phone2",
      name: "Emergency Phone2",
      selector: (row) => row.emergency_phone2,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_phone2)}
        >
          <span>
            {row.emergency_phone2 &&
              row.emergency_phone2.slice(0, 12) +
                (row.emergency_phone2.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Hear about us",
      name: "Hear about us",
      selector: (row) => row.hear_us,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            row.hear_us &&
              row.hear_us
                .slice(2, -2)
                .split('","')
                .map((source) => source.replace(/\\"/g, ""))
                .join(", ")
          )}
        >
          <span>
            {row.hear_us &&
              row.hear_us
                .slice(2, -2)
                .split('","')
                .map((source) => source.replace(/\\"/g, ""))
                .join(", ")
                .slice(0, 12) + (row.hear_us.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Referral from a friend/Family member",
      name: "Referral from a friend/Family member",
      selector: (row) => row.referral_from_a_friend_or_family_member,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.referral_from_a_friend_or_family_member)}
        >
          <span>
            {row.referral_from_a_friend_or_family_member &&
              row.referral_from_a_friend_or_family_member.slice(0, 12) +
                (row.referral_from_a_friend_or_family_member.length > 12
                  ? "..."
                  : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Under Age 35",
      name: "Under Age 35",
      selector: (row) => row.under_age_35,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.under_age_35)}
        >
          <span>
            {row.under_age_35 &&
              row.under_age_35.slice(0, 12) +
                (row.under_age_35.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },

    {
      id: "notes",
      name: "Notes",
      selector: (row) => row.notes,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.notes)}
        >
          <span style={{ fontSize: "14px" }}>{row.notes ? row.notes : ""}</span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      width: "300px",
      style: {
        cursor: "text",
      },
    },*/
    {
      id: "fromToDate",
      name: "Retreat Dates",
      selector: (row) =>
        moment(row.dateFrom).format("MMM DD") +
        "-" +
        moment(row.dateTo).format("MMM DD") +
        "," +
        moment(row.dateFrom).format("YYYY"),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            moment(row.dateFrom).format("MMM DD") +
              "-" +
              moment(row.dateTo).format("MMM DD") +
              "," +
              moment(row.dateFrom).format("YYYY")
          )}
        >
          <span style={{ fontSize: "14px" }}>
            {moment(row.dateFrom).format("MMM DD") +
              "-" +
              moment(row.dateTo).format("MMM DD") +
              "," +
              moment(row.dateFrom).format("YYYY")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "200px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Status",
      name: "Status",
      selector: (row) => row && row.accountStatus,
      cell: (row) => (
        <span style={{ fontSize: "14px" }}>
          {row.accountStatus &&
            row.accountStatus.charAt(0).toUpperCase() +
              row.accountStatus.slice(1).toLowerCase()}
        </span>
      ),
      sortable: true,
      reorder: true,
      width: "100px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "created",
      name: "Created",
      selector: (row) =>
        Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY"),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY hh:mm:a")
          )}
        >
          <span style={{ fontSize: "14px" }}>
            {Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY")}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      sortable: true,
      width: "140px",
      style: {
        cursor: "text",
      },
    },
  ];

  const attendData = [
    {
      id: "title",
      name: "Retreats",
      selector: (row) => (
        <div className="titleWithDate">
          <a
            href={`/retreatRoaster?retreatId=${row.id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="retreat_title"
          >
            <div>
              {`${
                row.title !== null && row.title != ""
                  ? `${row.title.charAt(0).toUpperCase() + row.title.slice(1)}`
                  : ""
              }`}

              {/* {
                `
              ${row.parish !== null && row.parish != '' ? `${row.parish.charAt(0).toUpperCase() + row.parish.slice(1) + ", "}` : ''}
              ${row.language !== null && row.language != '' ? `${row.language.charAt(0).toUpperCase() + row.language.slice(1) + ", "}` : ''}
              ${row.location !== null && row.location != '' ? `${row.location.charAt(0).toUpperCase() + row.location.slice(1)} ` : ''} `
              } */}
            </div>
          </a>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(
              moment(row.dateFrom).format("MMM DD") +
                " - " +
                moment(row.dateTo).format("MMM DD") +
                ", " +
                moment(row.dateFrom).format("YYYY")
            )}
          >
            <span>
              {moment(row.dateFrom).format("MMM DD") +
                " - " +
                moment(row.dateTo).format("MMM DD") +
                ", " +
                moment(row.dateFrom).format("YYYY")}
            </span>
          </OverlayTrigger>
        </div>
      ),
      sortable: true,
      reorder: true,
      width: "300px",
    },
    {
      id: "AttendeeType",
      name: "Type",
      selector: (row) => row && row.attendeeType,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.attendeeType)}
        >
          {row && row.attendeeType == "Team" ? (
            <span className="badge badge-danger">{row.attendeeType}</span>
          ) : (
            <span className="badge badge-success">{row.attendeeType}</span>
          )}
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "200px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Role",
      name: "Role",
      selector: (row) => row && row.role,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.role)}
        >
          <span>{row.role ? row.role : ""}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "200px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Service Title",
      name: "Service Title",
      selector: (row) => row && row.service,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.service)}
        >
          <span>{row.service ? row.service : ""}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "200px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Talk",
      name: "Talk",
      selector: (row) => row && row.talk,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.talk)}
        >
          <span>{row.talk ? row.talk : ""}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "230px",
      style: {
        cursor: "text",
      },
    },
  ];

  let talkids1 = [];
  const getTalksList = async () => {
    await talksApi
      .getAllTalkSearch()
      .then(async (response) => {
        const sortedList = response.data.sort((a, b) =>
          a.talk.localeCompare(b.talk)
        );
        setTalksDataList(sortedList);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  let serviceIds1 = [];
  const getServiceList = async () => {
    await serviceApi
      .getAllServiceSearch()
      .then(async (response) => {
        const sortedList = response.data.sort((a, b) =>
          a.service.localeCompare(b.service)
        );
        setServiceDataList(sortedList);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  let roleIds1 = [];
  const getrolesList = async () => {
    await rolesApi
      .getAllRoleSearch()
      .then(async (response) => {
        // const sortedList = response.data.sort((a, b) =>
        //   a.role.localeCompare(b.role));
        setrolesDataList(response.data);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  useEffect(() => {
    if (location1.state !== null) {
      setOpen1(!open1);
      getTalksList();
      getServiceList();
      const filter = location1.state;
      if (filter.reportId == 1) {
        setLoadData(true);
        serviceApi
          .getAllServiceSearch()
          .then(async (res) => {
            res.data.forEach((s) => {
              serviceIds1.push(s.id);
            });
            if (serviceIds1 && serviceIds1.length) {
              const d = Object.assign(searchQuery, searchQuery);
              // d.searchFor = 'couple'
              d.retreatId = filter.filter.retreatId;
              d.dateFrom = filter.filter.dateFrom;
              d.dateTo = filter.filter.dateTo;
              d.searchFor = "couple";
              serviceIds1 &&
                serviceIds1 !== "" &&
                serviceIds1.map((item) => {
                  d.serviceIds.push(item);
                });
              setSearchQuery(d);
              setLoadData(false);
              searchRetreatsAndCouples();
              setQuickSearchFor("Served on Team");
            } else {
              setLoadData(false);
            }
          })
          .catch((error) => {
            setLoadData(false);
            toast.error(error.toString());
          });
      } else if (filter.reportId == 2) {
        const d = Object.assign(searchQuery, searchQuery);
        d.retreatId = filter.filter.retreatId;
        d.dateFrom = filter.filter.dateFrom;
        d.dateTo = filter.filter.dateTo;
        d.searchFor = "couple";
        // d.searchFor = 'couple'
        d.neverServedTeam = true;
        setSearchQuery(d);
        searchRetreatsAndCouples();
        setQuickSearchFor("Never Served on Team");
      } else if (filter.reportId === 3) {
        setLoadData(true);
        talksApi
          .getAllTalkSearch()
          .then(async (response) => {
            response.data.forEach((item) => {
              talkids1.push(item.talkId);
            });
            if (talkids1 && talkids1.length) {
              const d = Object.assign(searchQuery, searchQuery);
              d.retreatId = filter.filter.retreatId;
              d.dateFrom = filter.filter.dateFrom;
              d.dateTo = filter.filter.dateTo;
              d.searchFor = "couple";
              talkids1 &&
                talkids1 !== "" &&
                talkids1.map((item) => {
                  d.talkIds.push(item);
                });
              setSearchQuery(d);
              setLoadData(false);
              searchRetreatsAndCouples();
              setQuickSearchFor("Given a Talk");
            } else {
              setLoadData(false);
            }
          })
          .catch((error) => {
            setLoadData(false);
            toast.error(error.toString());
          });
      } else if (filter.reportId == 4) {
        const d = Object.assign(searchQuery, searchQuery);
        d.retreatId = filter.filter.retreatId;
        d.dateFrom = filter.filter.dateFrom;
        d.dateTo = filter.filter.dateTo;
        d.searchFor = "couple";
        d.neverTalk = true;
        setSearchQuery(d);
        searchRetreatsAndCouples();
        setQuickSearchFor("Never Given a Talk");
      } else if (filter.reportId == 6) {
        setLoadData(true);
        const d = Object.assign(searchQuery, searchQuery);
        d.retreatId = filter.filter.retreatId;
        d.dateFrom = new Date(
          new Date().setFullYear(new Date().getFullYear() - 2)
        );
        d.dateTo = new Date();
        // let retreatantType = d.retreatants.findIndex((s) => s == 'Retreatant')
        // if (retreatantType != -1) {
        //   d.retreatants.splice(retreatantType, 1)
        // } else {
        //   d.retreatants.push('Retreatant')
        // }
        let retreatantType1 = d.retreatants.findIndex((s) => s == "Team");
        if (retreatantType1 != -1) {
          d.retreatants.splice(retreatantType1, 1);
        } else {
          d.retreatants.push("Team");
        }
        setSearchQuery(d);
        setLoadData(false);
        searchRetreatsAndCouples();
        setQuickSearchFor("Participated in Last 2 years");
      } else if (filter.reportId == 7) {
        const d = Object.assign(searchQuery, searchQuery);
        d.retreatId = filter.filter.retreatId;
        d.dateFrom = filter.filter.dateFrom;
        d.dateTo = filter.filter.dateTo;
        d.searchFor = "couple";
        d.accountStatus.push("ACTIVE", "DECEASED", "DIVORCED");
        setSearchFor("couple");
        setSearchQuery(d);
        searchRetreatsAndCouples();
      } else if (filter.reportId == 8) {
        setLoadData(true);
        rolesApi
          .getAllRoleList()
          .then(async (res) => {
            let serviceId = res.data.find((s) => s.role == "Lead");
            roleIds1.push(serviceId.id);
            if (roleIds1 && roleIds1.length) {
              const d = Object.assign(searchQuery, searchQuery);
              d.retreatId = filter.filter.retreatId;
              d.dateFrom = filter.filter.dateFrom;
              d.dateTo = filter.filter.dateTo;
              d.searchFor = "couple";
              roleIds1 &&
                roleIds1 !== "" &&
                roleIds1.map((item) => {
                  d.roleIds.push(item);
                });
              setSearchQuery(d);
              setLoadData(false);
              searchRetreatsAndCouples();
              setQuickSearchFor("Served as a Retreat Lead Couple");
            } else {
              setLoadData(false);
            }
          })
          .catch((error) => {
            setLoadData(false);
            toast.error(error.toString());
          });
      } else if (filter.reportId == 9) {
        setLoadData(true);
        rolesApi
          .getAllRoleSearch()
          .then(async (res) => {
            let serviceId = res.data.find((s) => s.role == "Head");
            if (serviceId) {
              roleIds1.push(serviceId.id);
              if (roleIds1 && roleIds1.length) {
                const d = Object.assign(searchQuery, searchQuery);
                d.retreatId = filter.filter.retreatId;
                d.dateFrom = filter.filter.dateFrom;
                d.dateTo = filter.filter.dateTo;
                d.searchFor = "couple";
                roleIds1 &&
                  roleIds1 !== "" &&
                  roleIds1.map((item) => {
                    d.roleIds.push(item);
                  });
                setSearchQuery(d);
                setLoadData(false);
                searchRetreatsAndCouples();
                setQuickSearchFor("Served as a Head of Service");
              }
            } else {
              setLoadData(false);
            }
          })
          .catch((error) => {
            setLoadData(false);
            toast.error(error.toString());
          });
      } else if (filter.reportId == 10) {
        setLoadData(true);
        rolesApi
          .getAllRoleList()
          .then(async (res) => {
            let headId = res.data.findIndex((s) => s.role == "Head");
            if (headId != -1) {
              res.data.splice(headId, 1);
            }
            let roleAllIds = res.data.map((s) => s.id);
            if (roleAllIds && roleAllIds.length) {
              const d = Object.assign(searchQuery, searchQuery);
              d.retreatId = filter.filter.retreatId;
              d.dateFrom = filter.filter.dateFrom;
              d.dateTo = filter.filter.dateTo;
              d.searchFor = "couple";
              d.is_Not_Head_Role = location1.state.is_Not_Head_Role;
              roleAllIds &&
                roleAllIds !== "" &&
                roleAllIds.map((item) => {
                  d.roleIds.push(item);
                  setSearchQuery(d);
                });
              setLoadData(false);
              searchRetreatsAndCouples();
              setQuickSearchFor("Never Served as a Head of Service");
            } else {
              setLoadData(false);
            }
          })
          .catch((error) => {
            setLoadData(false);
            toast.error(error.toString());
          });
      }
      getrolesList();
      AllComposedMailList();
      getAllCouples1();
      getAllRetreats();
      getStateCityCouples();
      getRetreatLocation();
      checkPKCouple();
    } else {
      location1.state = { ...reportId };
      location1.state.reportId = 0;
      getTalksList();
      getrolesList();
      getServiceList();
      AllComposedMailList();
      getAllCouples1();
      getAllRetreats();
      getStateCityCouples();
      getRetreatLocation();
      checkPKCouple();
      setQuickSearchFor("");
    }
    let report = {
      byWhom:
        `${userData && userData.userName ? userData.userName : null} ` +
        ` (${userData && userData.userRole ? userData.userRole : null})`,
      pagelink: window.location.href,
      description: "Visited on Advanced Search page.",
      action: "view",
    };
    adminApi
      .addLogReport(report)
      .then((response) => {
        if (response.success) {
          console.log("Created new log report.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getRetreatLocation = async () => {
    await retreatApi
      .getRetreatLocation()
      .then((res) => {
        if (res.success) {
          let retreatLocation = [];
          res.data &&
            res.data.forEach((item) => {
              if (item.location) {
                retreatLocation.push({
                  label: item.location,
                  value: item.location,
                });
              }
            });
          const sortedList = retreatLocation.sort((a, b) =>
            a.label.localeCompare(b.label)
          );
          setLocation(sortedList);
        }
      })
      .catch((error) => toast.error(error));
  };

  const getStateCityCouples = async () => {
    await adminApi
      .getStateCity()
      .then((res) => {
        if (res.success) {
          let stateCity = [];
          res.data &&
            res.data.forEach((item) => {
              if (item.state) {
                stateCity.push({
                  label: item.state + " - " + item.city,
                  value: item.state + " " + item.city,
                });
              }
            });
          const sortedList = stateCity.sort((a, b) =>
            a.label.localeCompare(b.label)
          );
          setCityState(sortedList);
        }
      })
      .catch((error) => toast.error(error));
  };

  const getAllCouples1 = async () => {
    let d = { userRole: "COUPLE" };
    d.export = true;
    await adminApi
      .getAllCouplesSearch(d)
      .then((res) => {
        if (res.success) {
          let zipCodes = res.data.map((item) => {
            return item.zip;
          });
          let result = zipCodes.filter((s) => {
            return s != "" && s != null;
          });
          let zipResult = [];
          result.forEach((item) => {
            if (item) {
              let findValue = zipResult.findIndex((s) => s.label == item);
              if (findValue == -1) {
                zipResult.push({
                  label: item,
                  value: item,
                });
              }
            }
          });
          setZip(zipResult);
          let allcoupleList = [];
          res.data &&
            res.data.forEach((item) => {
              if (item.hisFirstName) {
                allcoupleList.push({
                  label: `${item.hisLastName}, ${item.hisFirstName} and ${item.herFirstName}`,
                  value: item.id,
                });
              }
            });
          const sort = allcoupleList.sort((a, b) =>
            a.label.localeCompare(b.label)
          );
          // console.log("sort in couples---><", sort);
          setCoupleOptions(sort);

          const sortedList = res.data.sort((a, b) =>
            a.hisLastName > b.hisLastName
              ? 1
              : b.hisLastName > a.hisLastName
              ? -1
              : 0
          );
          setAllCoupleList(sortedList);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const searchRetreatsAndCouples = () => {
    setLoadData(true);
    retreatApi
      .searchRetreatsAndCouples(searchQuery)
      .then((res) => {
        if (res.success && res.retreats && res.retreats.length > 0) {
          if (
            res.retreats &&
            searchQuery.searchFor !== "couple" &&
            searchQuery.retreatants.length == 0 &&
            searchQuery.coupleId == 0 &&
            searchQuery.serviceIds.length == 0 &&
            searchQuery.talkIds.length == 0 &&
            searchQuery.roleIds.length == 0 &&
            searchQuery.neverServedTeam == false &&
            searchQuery.neverServiceRole == false &&
            searchQuery.neverTalk == false
          ) {
            // show retreats data only
            console.log("1");
            setLoadData(false);
            setTimeout(() => {
              const element = document.getElementById("section-1");
              if (element) {
                element.scrollIntoView({
                  behavior: "smooth",
                  block: "end",
                  inline: "nearest",
                });
              }
            }, 500);
            setDataCount(res.retreats.length);
            setIsShowData(false);
            setNoRetAttend(false);
            setCoupWithAttenType(false);
            setRetreatsList(res.retreats);
            let couples = [];

            const filteredData = res.couples.filter(
              (item) =>
                item.accountStatus !== "DIVORCED" &&
                item.accountStatus !== "DECEASED" &&
                item.accountStatus !== "INACTIVE"
            );

            filteredData.forEach((item) => {
              if (item.hisEmail) {
                couples.push({
                  label:
                    item.hisFirstName +
                    " " +
                    item.hisLastName +
                    "(" +
                    item.hisEmail +
                    ")",
                  value: item.hisEmail,
                });
              }
              if (item.herEmail) {
                couples.push({
                  label:
                    item.herFirstName +
                    " " +
                    item.herLastName +
                    "(" +
                    item.herEmail +
                    ")",
                  value: item.herEmail,
                });
              }
            });

            const key = "value";
            const arrayUniqueByKey = [
              ...new Map(couples.map((item) => [item[key], item])).values(),
            ];
            setAllCoupleData(arrayUniqueByKey);
          } else if (
            res.retreats &&
            searchQuery.retreatId == 0 &&
            searchQuery.language == 0 &&
            searchQuery.parisheId == 0 &&
            searchQuery.coupleId == 0 &&
            searchQuery.location.length == 0 &&
            searchQuery.searchFor === "couple" &&
            res.retreats[0].retreatsCount &&
            res.retreats[0].retreatsCount !== ""
          ) {
            // show couples with no. of retreat attended column at the end
            console.log("2");
            setDataCount(res.retreats.length);
            setNoRetAttend(true);
            setLoadData(false);
            setCoupWithAttenType(false);
            setRetreatsList(res.retreats);
            setTimeout(() => {
              const element = document.getElementById("section-1");
              if (element) {
                element.scrollIntoView({
                  behavior: "smooth",
                  block: "end",
                  inline: "nearest",
                });
              }
            }, 500);
            let couples = [];

            const filteredData = res.retreats.filter(
              (item) =>
                item.accountStatus !== "DIVORCED" &&
                item.accountStatus !== "DECEASED" &&
                item.accountStatus !== "INACTIVE"
            );
            filteredData.forEach((item) => {
              if (item.hisEmail) {
                couples.push({
                  label:
                    item.hisFirstName +
                    " " +
                    item.hisLastName +
                    "(" +
                    item.hisEmail +
                    ")",
                  value: item.hisEmail,
                });
              }
              if (item.herEmail) {
                couples.push({
                  label:
                    item.herFirstName +
                    " " +
                    item.herLastName +
                    "(" +
                    item.herEmail +
                    ")",
                  value: item.herEmail,
                });
              }
            });
            const key = "value";
            const arrayUniqueByKey = [
              ...new Map(couples.map((item) => [item[key], item])).values(),
            ];
            setAllCoupleData(arrayUniqueByKey);
          } else if (
            res.retreats &&
            res.retreats[0].attendeeType &&
            res.retreats[0].attendeeType !== "" &&
            searchQuery.retreatId == 0 &&
            searchQuery.parisheId == 0 &&
            searchQuery.coupleId == 0 &&
            searchQuery.searchFor == "couple"
          ) {
            // show couples with attendee Type
            console.log("3");
            setLoadData(false);
            setTimeout(() => {
              const element = document.getElementById("section-1");
              if (element) {
                element.scrollIntoView({
                  behavior: "smooth",
                  block: "end",
                  inline: "nearest",
                });
              }
            }, 500);
            setDataCount(res.retreats.length);
            setCoupWithAttenType(true);
            setNoRetAttend(false);
            setRetreatsList(res.retreats);
            let couples = [];
            const filteredData = res.retreats.filter(
              (item) =>
                item.accountStatus !== "DIVORCED" &&
                item.accountStatus !== "DECEASED" &&
                item.accountStatus !== "INACTIVE"
            );
            filteredData.forEach((item) => {
              if (item.hisEmail) {
                couples.push({
                  label:
                    item.hisFirstName +
                    " " +
                    item.hisLastName +
                    "(" +
                    item.hisEmail +
                    ")",
                  value: item.hisEmail,
                });
              }
              if (item.herEmail) {
                couples.push({
                  label:
                    item.herFirstName +
                    " " +
                    item.herLastName +
                    "(" +
                    item.herEmail +
                    ")",
                  value: item.herEmail,
                });
              }
            });
            const key = "value";
            const arrayUniqueByKey = [
              ...new Map(couples.map((item) => [item[key], item])).values(),
            ];
            setAllCoupleData(arrayUniqueByKey);
          } else {
            //  show retreats with couple columns
            console.log("4");
            setLoadData(false);
            setTimeout(() => {
              const element = document.getElementById("section-1");
              if (element) {
                element.scrollIntoView({
                  behavior: "smooth",
                  block: "end",
                  inline: "nearest",
                });
              }
            }, 500);
            setDataCount(res.retreats.length);
            setIsShowData(true);
            setNoRetAttend(false);
            setCoupWithAttenType(false);
            setRetreatsList(res.retreats);
            let couples = [];
            const filteredData = res.retreats.filter(
              (item) =>
                item.accountStatus !== "DIVORCED" &&
                item.accountStatus !== "DECEASED" &&
                item.accountStatus !== "INACTIVE"
            );
            filteredData.forEach((item) => {
              if (item.hisEmail) {
                couples.push({
                  label:
                    item.hisFirstName +
                    " " +
                    item.hisLastName +
                    "(" +
                    item.hisEmail +
                    ")",
                  value: item.hisEmail,
                });
              }
              if (item.herEmail) {
                couples.push({
                  label:
                    item.herFirstName +
                    " " +
                    item.herLastName +
                    "(" +
                    item.herEmail +
                    ")",
                  value: item.herEmail,
                });
              }
            });
            const key = "value";
            const arrayUniqueByKey = [
              ...new Map(couples.map((item) => [item[key], item])).values(),
            ];
            setAllCoupleData(arrayUniqueByKey);
          }
        } else {
          setIsShowData(false);
          setLoadData(false);
          setNoRetAttend(false);
          setCoupWithAttenType(false);
          setRetreatsList([]);
          toast.error(res.msg);
          setTimeout(() => {
            const element = document.getElementById("footer");
            if (element) {
              element.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest",
              });
            }
          }, 500);
        }
      })
      .catch((error) => {
        setLoadData(false);
        toast.error(error);
        setTimeout(() => {
          const element = document.getElementById("footer");
          if (element) {
            element.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          }
        }, 500);
      });
  };

  const showDataTableRet = () => {
    if (LoadData) {
      return (
        <div className="text-center">
          <Loader />
        </div>
      );
    } else {
      return (
        <CustomDataTable
          key={`tab-1`}
          data={retreatsList}
          columns={retreatsColumns}
          pointerOnHover={true}
          highlightOnHover={true}
        />
      );
    }
  };

  function showDataTableRetWithCoup() {
    if (LoadData) {
      return (
        <div className="text-center">
          <Loader />
        </div>
      );
    } else {
      return (
        <CustomDataTable
          key={`tab-2`}
          data={retreatsList}
          columns={retreatColWithCouple}
          pointerOnHover={true}
          highlightOnHover={true}
          pagination={true}
        />
      );
    }
  }

  const showNoRetrattend = () => {
    if (LoadData) {
      return (
        <div className="text-center">
          <Loader />
        </div>
      );
    } else {
      return (
        <CustomDataTable
          key={`tab-3`}
          data={retreatsList}
          columns={coupleColWithRetAttend}
          pointerOnHover={true}
          highlightOnHover={true}
          pagination={true}
        />
      );
    }
  };

  const showRetAttenType = () => {
    if (LoadData) {
      return (
        <div className="text-center">
          <Loader />
        </div>
      );
    } else {
      return (
        <CustomDataTable
          key={`tab-4`}
          data={retreatsList}
          columns={coupleColWithAttendType}
          pointerOnHover={true}
          highlightOnHover={true}
          pagination={true}
        />
      );
    }
  };

  const resetForm = () => {
    // document.getElementById("searchForm").reset();
    setSearchQuery(searchInitial);
    setCouplesList([]);
    setRetreatsList([]);
    setIsShowData(false);
    setNoRetAttend(false);
    setCoupWithAttenType(false);
    setSearchFor("retreat");
    setSelectedLocation([]);
    setSelectedZips([]);
    setSelectedStateCity([]);
    setSelectRetreat(null);
    setSelectCouple(null);
    setSelectParish(null);
    setRoleTextArray([]);
    setServiceTextArray([]);
    setTalkTextArray([]);
    setQuickSearchFor("");
    setDataCount([]);
    setRetreatOptions([]);
    setSelectedRetreats([]);
    // setLanguagesOptions([])
    setSelectedLanguages([]);
    setCoupleOptions([]);
    setSelectedCouples([]);
    setParishOptions([]);
    setSelectedParishes([]);
    getPerishList();
    getTalksList();
    getrolesList();
    getServiceList();
    AllComposedMailList();
    getAllCouples1();
    getAllRetreats();
    getStateCityCouples();
    getRetreatLocation();
    checkPKCouple();
  };

  const getPerishList = async () => {
    await perishApi
      .getAllPerishSearch({ searchFor: searchQuery.searchFor })
      .then((response) => {
        const sortedList = response.data.sort((a, b) =>
          a.parish.localeCompare(b.parish)
        );
        setParishDataList(sortedList);
        let parishList = [];
        response.data &&
          response.data.forEach((item) => {
            if (item.parish) {
              parishList.push({
                label: item.parish,
                value: item.id,
              });
            }
          });
        const sort = parishList.sort((a, b) => a.label.localeCompare(b.label));
        setParishOptions(sort);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const getAllRetreats = async () => {
    await retreatApi
      .getAllRetreatSearch()
      .then((response) => {
        const sortedList = response.data.sort((a, b) =>
          a.title.localeCompare(b.title)
        );
        setAllRetreatData(sortedList);
        let retreatList = [];
        response.data &&
          response.data.forEach((item) => {
            if (item.title) {
              retreatList.push({
                label:
                  item && item.title !== null && item.title != ""
                    ? `${
                        item.title.charAt(0).toUpperCase() + item.title.slice(1)
                      }` +
                      " " +
                      `${
                        moment(item.dateFrom).format("MMM DD") +
                        " - " +
                        moment(item.dateTo).format("MMM DD") +
                        ", " +
                        moment(item.dateFrom).format("YYYY")
                      }`
                    : "",
                value: item.id,
                dateFrom: moment(item.dateFrom),
              });
            }
          });
        const sort = retreatList.sort((a, b) => b.title - a.title);

        setRetreatOptions(sort);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  function handleSelect(data) {
    setselectedData(data);
    let selectedDataa = { ...maildata };
    selectedDataa.retreats = data.map((item, index) => {
      let returnOBj = {
        email: item.label,
        value: item.value,
      };
      return returnOBj;
    });
    setMailData(selectedDataa);
  }

  const ShowCompose = () => {
    setMailShow(true);
    clearfield();
    setDefaultEdtitorContent(initialEditorContent);
    setData({
      // primaryKey: '',
      id: "",
      hisFirstName: "",
      hisLastName: "",
      hisEmail: "",
      hisMobile: "",
      herFirstName: "",
      herLastName: "",
      herEmail: "",
      herMobile: "",
      ciyt: "",
      state: "",
      notes: "",
      userRole: "COUPLE",
    });
    setValidated(false);
  };

  const CloseCompose = () => {
    setMailShow(false);
    setIsDisabled(false);
    setIsEditAdmin(false);
    setValidated(false);
    setShowREgModal(false);
    setEdiUserIdClicked("");
    clearfield();
  };

  const getroastersOfCouple = async (id) => {
    await adminApi
      .participatedInRetreats({ couple_Id: id })
      .then(async (response) => {
        setRoastersOfCoupleList(response.data);
        setRoasterCount(response.totalRecords);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const AllComposedMailList = async () => {
    await sendEmailApi
      .getAllComposedMails()
      .then(async (response) => {
        setComposedMails(response.data);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const clearfield = () => {
    setMailData({
      couple: [],
      subject: "",
      message: defaultEditorContent,
    });
    SetMail_id("");
    setselectedData([]);
  };

  const handleSearchChanges = (event, id = null, text = null) => {
    let { name, value } = event.target;
    const search = { ...searchQuery };
    switch (name) {
      case "primaryKey":
        search.primaryKey = value;
        break;
      case "cityState":
        search.cityState = value;
        break;
      case "retreatAttended":
        if (value == "false") {
          value = true;
        } else {
          value = false;
        }
        search.retreatAttended = value;
        break;
      case "retreatants":
        let retreatantType = search.retreatants.findIndex((s) => s == value);
        if (retreatantType != -1) {
          search.retreatants.splice(retreatantType, 1);
        } else {
          search.retreatants.push(value);
        }
        break;
      case "language":
        search.language = value;
        break;
      case "service":
        let findId = search.serviceIds.findIndex((id) => id == value);
        let serviceName =
          serviceDataList && serviceDataList.find((item) => item.id == value);
        let temp3 = serviceName.service;

        let serviceIndex3 = serviceTextArray.findIndex((id) => id == temp3);

        if (serviceIndex3 != -1) {
          serviceTextArray.splice(serviceIndex3, 1);
        } else {
          setServiceTextArray((oldArray) => [...oldArray, temp3]);
        }
        if (findId != -1) {
          search.serviceIds.splice(findId, 1);
        } else {
          search.serviceIds.push(value);
        }
        break;
      case "neverService":
        search.neverServedTeam = !search.neverServedTeam;
        break;
      case "serviceRole":
        let roleIndex = search.roleIds.findIndex((id) => id == value);
        let roleName =
          rolesDataList && rolesDataList.find((item) => item.id == value);
        let temp = roleName.role;
        let roleIndex1 = roleTextArray.findIndex((id) => id == temp);
        if (roleIndex1 != -1) {
          roleTextArray.splice(roleIndex1, 1);
        } else {
          setRoleTextArray((oldArray) => [...oldArray, temp]);
        }
        if (roleIndex != -1) {
          search.roleIds.splice(roleIndex, 1);
        } else {
          search.roleIds.push(value);
        }
        break;
      case "neverServiceRole":
        search.neverServiceRole = !search.neverServiceRole;
        break;
      case "talks":
        let talkId = search.talkIds.findIndex((id) => id == value);
        let talkName =
          talksDataList && talksDataList.find((item) => item.talkId == value);
        let temp6 = talkName.talk;
        let talkIndex2 = talkTextArray.findIndex((id) => id == temp6);
        if (talkIndex2 != -1) {
          talkTextArray.splice(talkIndex2, 1);
        } else {
          setTalkTextArray((oldArray) => [...oldArray, temp6]);
        }

        if (talkId != -1) {
          search.talkIds.splice(talkId, 1);
        } else {
          search.talkIds.push(value);
        }
        break;
      case "neverTalk":
        search.neverTalk = !search.neverTalk;
        break;
      case "coupleStatus":
        let status = search.accountStatus.findIndex((s) => s == value);
        if (status != -1) {
          search.accountStatus.splice(status, 1);
        } else {
          search.accountStatus.push(value);
        }
        break;
      case "searchFor":
        search.searchFor = value;
        break;
    }
    setSearchQuery(search);
  };

  const enableAllDates = (e) => {
    let { name, value } = e.target;
    if (value == "true") {
      value = false;
      searchQuery.dateFrom = "";
      searchQuery.dateTo = "";
    } else {
      value = true;
    }
    setAllDates(value);
  };

  const handleSelect1 = (da) => {
    setSelectedStateCity(da);
    let selectedData = { ...searchQuery };
    selectedData.cityState = da.map((item, index) => {
      let returnOBj = {
        label: item.label,
        value: item.value,
      };
      return returnOBj;
    });
    setSearchQuery(selectedData);
  };

  const handleSelectLocation = (val) => {
    setSelectedLocation(val);
    let selectedData = { ...searchQuery };
    selectedData.location = val.map((item, index) => {
      let returnOBj = {
        label: item.label,
        value: item.value,
      };
      return returnOBj;
    });
    setSearchQuery(selectedData);
  };

  const handleSelectRetreat = (val) => {
    setSelectedRetreats(val);
    let selectedRetreatName = { ...selectRetreat };
    selectedRetreatName = val.map((item, index) => {
      return item.label;
    });
    setSelectRetreat(selectedRetreatName);
    let selectedData = { ...searchQuery };
    selectedData.retreatId = val.map((item, index) => {
      return item.value;
    });
    setSearchQuery(selectedData);
  };

  const handleSelectLanguages = (val) => {
    setSelectedLanguages(val);

    let selectedLanguageName = { ...selectLanguage };
    selectedLanguageName = val.map((item, index) => {
      return item.label;
    });
    setSelectLanguage(selectedLanguageName);

    let selectedData = { ...searchQuery };
    selectedData.language = val.map((item, index) => {
      return item.value;
    });
    setSearchQuery(selectedData);
  };

  const handleSelectCouples = (val) => {
    setSelectedCouples(val);

    let selectedCoupleName = { ...selectCouple };
    selectedCoupleName = val.map((item, index) => {
      return item.label;
    });
    setSelectCouple(selectedCoupleName);

    let selectedData = { ...searchQuery };
    selectedData.coupleId = val.map((item, index) => {
      return item.value;
    });
    setSearchQuery(selectedData);
  };

  const handleSelectZip = (val) => {
    setSelectedZips(val);
    let selectedData = { ...searchQuery };
    selectedData.zip = val.map((item, index) => {
      let returnOBj = {
        label: item.label,
        value: item.value,
      };
      return returnOBj;
    });
    setSearchQuery(selectedData);
  };

  const handleSelectParishes = (val) => {
    setSelectedParishes(val);
    let selectedParishName = { ...selectParish };
    selectedParishName = val.map((item, index) => {
      return item.label;
    });
    setSelectParish(selectedParishName);

    let selectedData = { ...searchQuery };
    selectedData.parisheId = val.map((item, index) => {
      return item.value;
    });
    setSearchQuery(selectedData);
  };

  const handleSearchFor = (e) => {
    let { value } = e.target;
    setSearchFor(value);
    let d = { ...searchQuery };
    d.searchFor = value;
    setSearchQuery(d);
    setCouplesList([]);
    setRetreatsList([]);
    setIsShowData(false);
    setNoRetAttend(false);
    setCoupWithAttenType(false);
    setSelectedStateCity([]);
    setSelectedLocation([]);
  };

  const checkPKCouple = async () => {
    await authApi
      .checkPKCouple()
      .then((res) => {
        if (res.success) {
          if (res.data != null) {
            let incPK = res.data + 1;
            setAlreadyPk(incPK);
            let d = { ...data };
            d.primaryKey = incPK;
            setData(d);
          } else {
            let defaultPK = 1001;
            setAlreadyPk(defaultPK);
            let d = { ...data };
            d.primaryKey = defaultPK;
            setData(d);
          }
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleChanges1 = (e) => {
    let { name, value } = e.target;
    let d = { ...maildata };
    d[name] = value;
    setMailData(d);
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      e.preventDefault();
      e.stopPropagation();
      let d = { ...maildata };
      if (allCoupleData.length) {
        const maildta = allCoupleData.map((item, index) => {
          let jsdata = {
            email: item.label,
            value: item.value,
          };
          return jsdata;
        });
        d.couple = maildta;
      }
      d.message = Message;
      sendEmailApi
        .addSendEmail(d)
        .then(async (res) => {
          if (res.success) {
            toast.success(res.msg);
            clearfield();
            setSendEmailList(res.data);
            AllComposedMailList();
            setMailShow(false);
            let report = {
              byWhom:
                `${userData && userData.userName ? userData.userName : null} ` +
                ` (${
                  userData && userData.userRole ? userData.userRole : null
                })`,
              pagelink: window.location.href,
              description: `Send mail (Mail Id : ${res.data.id}, Mail Subject : ${res.data.subject})`,
              action: "Add",
            };
            await adminApi
              .addLogReport(report)
              .then((response) => {
                if (response.success) {
                  console.log("Created new log report.");
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            toast.error(res.msg);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
    setValidated(true);
  };

  const editCouple = async (id) => {
    setEdiUserIdClicked(id);
    setIsTempUpload(false);
    setIsTempUpload2(false);
    let d = { ...data };
    await adminApi
      .getCouplesDetail({ coupleId: id })
      .then(async (res) => {
        if (
          res.data[0].hisEmail === "tkolkebeck@me.com" ||
          res.data[0].herEmail === "tkolkebeck@satx.rr.com" ||
          res.data[0].hisEmail === "jimmieflores@gmail.com" ||
          res.data[0].herEmail === "dulceaflores@gmail.com"
        ) {
          setIsEditAdmin(true);
        }
        if (res.success) {
          d.id = res.data[0].id;
          d.primaryKey = res.data[0].primaryKey;
          d.hisFirstName = res.data[0].hisFirstName;
          d.hisLastName = res.data[0].hisLastName;
          d.hisEmail = res.data[0].hisEmail;
          d.hisMobile = res.data[0].hisMobile;
          d.herFirstName = res.data[0].herFirstName;
          d.herLastName = res.data[0].herLastName;
          d.herEmail = res.data[0].herEmail;
          d.herMobile = res.data[0].herMobile;
          d.city = res.data[0].city;
          d.state = res.data[0].state;
          d.zip = res.data[0].zip;
          d.notes = res.data[0].notes;
          d.parishId = res.data[0].parishId;
          d.imageUrl = res.data[0].imageUrl;
          // d.marriageImageUrl = res.data[0].marriageImageUrl;
          d.address = res.data[0].address;
          d.allergies = res.data[0].allergies;
          d.anniversary_date = res.data[0].anniversary_date
            ? new Date(res.data[0].anniversary_date)
            : "";
          d.under_age_35 = res.data[0].under_age_35;
          d.emergency_name1 = res.data[0].emergency_name1;
          d.emergency_relationship1 = res.data[0].emergency_relationship1;
          d.emergency_phone1 = res.data[0].emergency_phone1;
          d.emergency_name2 = res.data[0].emergency_name2;
          d.emergency_relationship2 = res.data[0].emergency_relationship2;
          d.emergency_phone2 = res.data[0].emergency_phone2;
          d.hear_us = res.data[0].hear_us;
          d.referral_from_a_friend_or_family_member =
            res.data[0].referral_from_a_friend_or_family_member;
          setData(d);
          setOldValues(res.data[0]);
          let report = {
            byWhom:
              `${userData && userData.userName ? userData.userName : null} ` +
              ` (${userData && userData.userRole ? userData.userRole : null})`,
            pagelink: window.location.href,
            description: `Checked Deatil of Couple (${d && d.primaryKey} - ${
              res && res.data && res.data[0] && res.data[0].herFirstName
            } ${res && res.data && res.data[0] && res.data[0].herLastName})`,
            action: "View",
          };
          await adminApi
            .addLogReport(report)
            .then((response) => {
              if (response.success) {
                console.log("Created new log report.");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleExport = async () => {
    let headings;
    let exportData = [];
    if (isShowData) {
      retreatsList.forEach((row) => {
        exportData.push({
          RetreatTitle: row.title,
          CoupleId: row.primaryKey,
          Type: row.attendeeType,
          HisLastName: row.hisLastName,
          HisFirstName: row.hisFirstName,
          HerLastName: row.herLastName,
          HerFirstName: row.herFirstName,
          Parish: row.coupleParish,
          ServiceRole: row.role,
          ServiceTitle: row.service,
          Talk: row.talk,
          HisEmail: row.hisEmail,
          HerEmail: row.herEmail,
          HisMobile: row.hisMobile,
          herMobile: row.herMobile,
          address: row.address,
          city: row.city,
          state: row.state,
          zip: row.zip,
          // parish: row.parish,
          Anniversary_date:
            row.anniversary_date !== null
              ? Helpers.convertUtcToTimezone(row.anniversary_date, "MM-DD-YYYY")
              : "",
          Dietary_restrictions_or_physical_limitations: row.allergies,
          Under_age_35: row.under_age_35,
          Emergency_name1: row.emergency_name1,
          Emergency_relationship1: row.emergency_relationship1,
          Emergency_phone1: row.emergency_phone1,
          Emergency_name2: row.emergency_name2,
          Emergency_relationship2: row.emergency_relationship2,
          Emergency_phone2: row.emergency_phone2,
          // How_did_you_hear_about_us: row.hear_us,
          Referral_from_a_friend_or_family_member:
            row.referral_from_a_friend_or_family_member,
          notes: row.notes,
          RetreatDates:
            moment(row.dateFrom).format("MMM DD") +
            "-" +
            moment(row.dateTo).format("MMM DD") +
            "," +
            moment(row.dateFrom).format("YYYY"),
          Status:
            row.accountStatus &&
            row.accountStatus.charAt(0).toUpperCase() +
              row.accountStatus.slice(1).toLowerCase(),
          Created: Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY"),
        });
      });
      headings = [
        [
          "Retreat Title",
          "Couple Id",
          "Type",
          "His Last Name",
          "His First Name",
          "Her Last Name",
          "Her First Name",
          "Parish",
          "Service Role",
          "Service Title",
          "Talk",
          "His Email",
          "Her Email",
          "His Mobile",
          "Her Mobile",
          "Address",
          "City",
          "State",
          "Zip",
          "Anniversary Date",
          "Dietary Restrictions Or Physical Limitations",
          "Under Age 35",
          "Emergency Name1",
          "Emergency Relationship1",
          "Emergency Phone1",
          "Emergency Name2",
          "Emergency Emergency Relationship2",
          "Emergency Phone2",
          // "How did you hear about us",
          "Referral from a friend/family member",
          "Notes",
          "Retreat Dates",
          "Status",
          "Created",
        ],
      ];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, exportData, { origin: "A2", skipHeader: true });
      utils.book_append_sheet(wb, ws, "Data");
      writeFile(
        wb,
        `${quickSearchFor ? `Quick Search Results` : `Search Results`}` +
          ".xlsx"
      );
    } else if (!isShowData && !noRetAttend && !coupWithAttenType) {
      retreatsList.forEach((row) => {
        exportData.push({
          RetreatTitle: row.title,
          language: row.language,
          location: row.location,
          Retreatants: row.countRetreatant,
          Team: row.countTeam,
          retreat_heading: row.heading,
          RetreatDates:
            moment(row.dateFrom).format("MMM DD") +
            "-" +
            moment(row.dateTo).format("MMM DD") +
            "," +
            moment(row.dateFrom).format("YYYY"),

          Created: Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY"),
        });
      });
      headings = [
        [
          "Retreat Title",
          "Language",
          "Location",
          "Retreatants Count",
          "Team Count",
          "Retreat Heading",
          "Retreat Dates",
          "Created",
        ],
      ];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, exportData, { origin: "A2", skipHeader: true });
      utils.book_append_sheet(wb, ws, "Data");
      writeFile(
        wb,
        `${quickSearchFor ? `Quick Search Results` : `Search Results`}` +
          ".xlsx"
      );
    } else if (noRetAttend) {
      retreatsList.forEach((row) => {
        exportData.push({
          CoupleId: row.primaryKey,
          RetreatAttended: row.retreatsCount,
          Type: row.attendeeType,
          HisLastName: row.hisLastName,
          HisFirstName: row.hisFirstName,
          HerLastName: row.herLastName,
          HerFirstName: row.herFirstName,
          Parish: row.coupleParish,
          ServiceRole: row.role,
          ServiceTitle: row.service,
          Talk: row.talk,
          HisEmail: row.hisEmail,
          HerEmail: row.herEmail,
          HisMobile: row.hisMobile,
          HerMobile: row.herMobile,
          address: row.address,
          city: row.city,
          state: row.state,
          zip: row.zip,
          Anniversary_date:
            row.anniversary_date !== null
              ? Helpers.convertUtcToTimezone(row.anniversary_date, "MM-DD-YYYY")
              : "",
          Dietary_restrictions_or_physical_limitations: row.allergies,
          Under_age_35: row.under_age_35,
          Emergency_name1: row.emergency_name1,
          Emergency_relationship1: row.emergency_relationship1,
          Emergency_phone1: row.emergency_phone1,
          Emergency_name2: row.emergency_name2,
          Emergency_relationship2: row.emergency_relationship2,
          Emergency_phone2: row.emergency_phone2,
          // How_did_you_hear_about_us: row.hear_us,
          Referral_from_a_friend_or_family_member:
            row.referral_from_a_friend_or_family_member,
          notes: row.notes,
          RetreatDates:
            moment(row.dateFrom).format("MMM DD") +
            "-" +
            moment(row.dateTo).format("MMM DD") +
            "," +
            moment(row.dateFrom).format("YYYY"),
          Status:
            row.accountStatus &&
            row.accountStatus.charAt(0).toUpperCase() +
              row.accountStatus.slice(1).toLowerCase(),
        });
      });
      headings = [
        [
          "Couple Id",
          "Retreat Attended",
          "Type",
          "His Last Name",
          "His First Name",
          "Her Last Name",
          "Her First Name",
          "Parish",
          "Service Role",
          "Service Title",
          "Talk",
          "His Email",
          "Her Email",
          "His Mobile",
          "Her Mobile",
          "Address",
          "City",
          "State",
          "Zip",
          "Anniversary Date",
          "Dietary Restrictions Or Physical Limitations",
          "Under Age 35",
          "Emergency Name1",
          "Emergency Relationship1",
          "Emergency Phone1",
          "Emergency Name2",
          "Emergency Relationship2",
          "Emergency Phone2",
          // "How did you hear about us",
          "Referral from a friend/family member",
          "Notes",
          "Retreat Dates",
          "Status",
        ],
      ];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, exportData, { origin: "A2", skipHeader: true });
      utils.book_append_sheet(wb, ws, "Data");
      writeFile(
        wb,
        `${quickSearchFor ? `Quick Search Results` : `Search Results`}` +
          ".xlsx"
      );
    } else if (coupWithAttenType) {
      retreatsList.forEach((row) => {
        exportData.push({
          RetreatTitle: row.title.charAt(0).toUpperCase() + row.title.slice(1),
          CoupleId: row.primaryKey,
          Type: row.attendeeType,
          HisLastName: row.hisLastName,
          HisFirstName: row.hisFirstName,
          HerLastName: row.herLastName,
          HerFirstName: row.herFirstName,
          Parish: row.coupleParish,
          ServiceRole: row.role,
          ServiceTitle: row.service,
          Talk: row.talk,
          HisEmail: row.hisEmail,
          HerEmail: row.herEmail,
          HisMobile: row.hisMobile,
          HerMobile: row.herMobile,
          address: row.address,
          city: row.city,
          state: row.state,
          zip: row.zip,
          Anniversary_date:
            row.anniversary_date !== null
              ? Helpers.convertUtcToTimezone(row.anniversary_date, "MM-DD-YYYY")
              : "",
          Dietary_restrictions_or_physical_limitations: row.allergies,
          Under_age_35: row.under_age_35,
          Emergency_name1: row.emergency_name1,
          Emergency_relationship1: row.emergency_relationship1,
          Emergency_phone1: row.emergency_phone1,
          Emergency_name2: row.emergency_name2,
          Emergency_relationship2: row.emergency_relationship2,
          Emergency_phone2: row.emergency_phone2,
          // How_did_you_hear_about_us: row.hear_us,
          Referral_from_a_friend_or_family_member:
            row.referral_from_a_friend_or_family_member,
          notes: row.notes,
          RetreatDates:
            moment(row.dateFrom).format("MMM DD") +
            "-" +
            moment(row.dateTo).format("MMM DD") +
            "," +
            moment(row.dateFrom).format("YYYY"),
          Status:
            row.accountStatus &&
            row.accountStatus.charAt(0).toUpperCase() +
              row.accountStatus.slice(1).toLowerCase(),
        });
      });
      headings = [
        [
          "Retreat Title",
          "Couple Id",
          "Type",
          "His Last Name",
          "His First Name",
          "Her Last Name",
          "Her First Name",
          "Parish",
          "Service Role",
          "Service Title",
          "Talk",
          "His Email",
          "Her Email",
          "His Mobile",
          "Her Mobile",
          "Address",
          "City",
          "State",
          "Zip",
          "Anniversary Date",
          "Dietary Restrictions Or Physical Limitations",
          "Under Age 35",
          "Emergency Name1",
          "Emergency Relationship1",
          "Emergency Phone1",
          "Emergency Name2",
          "Emergency Emergency Relationship2",
          "Emergency Phone2",
          // "How did you hear about us",
          "Referral from a friend/family member",
          "Notes",
          "Retreat Dates",
          "Status",
        ],
      ];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, exportData, { origin: "A2", skipHeader: true });
      utils.book_append_sheet(wb, ws, "Data");
      writeFile(
        wb,
        `${quickSearchFor ? `Quick Search Results` : `Search Results`}` +
          ".xlsx"
      );
    }
    let report = {
      byWhom:
        `${userData && userData.userName ? userData.userName : null} ` +
        ` (${userData && userData.userRole ? userData.userRole : null})`,
      pagelink: window.location.href,
      description: `Excel file exported in the Advanced Search.`,
      action: "Click",
    };
    await adminApi
      .addLogReport(report)
      .then((response) => {
        if (response.success) {
          console.log("Created new log report.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const exportFlockNotes = async () => {
    let headings;
    let exportData = [];
    if (isShowData) {
      retreatsList.forEach((row) => {
        // Push his data
        exportData.push({
          firstName: row.hisFirstName,
          lastName: row.hisLastName,
          email: row.hisEmail,
          mobile: row.hisMobile,
          // RetreatTitle: row.title,
        });
        // Push her data
        exportData.push({
          firstName: row.herFirstName,
          lastName: row.herLastName,
          email: row.herEmail,
          mobile: row.herMobile,
          // RetreatTitle: row.title,
        });
      });
      const headings = [["First Name", "Last Name", "Email", "Mobile"]];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, exportData, { origin: "A2", skipHeader: true });
      utils.book_append_sheet(wb, ws, "Data");
      writeFile(
        wb,
        `${quickSearchFor ? `Quick Search Results` : `Search Results`}` +
          ".xlsx"
      );
    } else if (!isShowData && !noRetAttend && !coupWithAttenType) {
      retreatsList.forEach((row) => {
        exportData.push({
          RetreatTitle: row.title,
          language: row.language,
          location: row.location,
          Retreatants: row.countRetreatant,
          Team: row.countTeam,
          retreat_heading: row.heading,
          RetreatDates:
            moment(row.dateFrom).format("MMM DD") +
            "-" +
            moment(row.dateTo).format("MMM DD") +
            "," +
            moment(row.dateFrom).format("YYYY"),

          Created: Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY"),
        });
      });
      headings = [
        [
          "Retreat Title",
          "Language",
          "Location",
          "Retreatants Count",
          "Team Count",
          "Retreat Heading",
          "Retreat Dates",
          "Created",
        ],
      ];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, exportData, { origin: "A2", skipHeader: true });
      utils.book_append_sheet(wb, ws, "Data");
      writeFile(
        wb,
        `${quickSearchFor ? `Quick Search Results` : `Search Results`}` +
          ".xlsx"
      );
    } else if (noRetAttend) {
      retreatsList.forEach((row) => {
        // Push his data
        exportData.push({
          firstName: row.hisFirstName,
          lastName: row.hisLastName,
          email: row.hisEmail,
          mobile: row.hisMobile,
          RetreatAttended: row.retreatsCount,
        });
        // Push her data
        exportData.push({
          firstName: row.herFirstName,
          lastName: row.herLastName,
          email: row.herEmail,
          mobile: row.herMobile,
          RetreatAttended: row.retreatsCount,
        });
      });
      const headings = [["First Name", "Last Name", "Email", "Mobile"]];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, exportData, { origin: "A2", skipHeader: true });
      utils.book_append_sheet(wb, ws, "Data");
      writeFile(
        wb,
        `${quickSearchFor ? `Quick Search Results` : `Search Results`}` +
          ".xlsx"
      );
    } else if (coupWithAttenType) {
      retreatsList.forEach((row) => {
        // Push his data
        exportData.push({
          firstName: row.hisFirstName,
          lastName: row.hisLastName,
          email: row.hisEmail,
          mobile: row.hisMobile,
          // RetreatTitle: row.title.charAt(0).toUpperCase() + row.title.slice(1),
        });
        // Push her data
        exportData.push({
          firstName: row.herFirstName,
          lastName: row.herLastName,
          email: row.herEmail,
          mobile: row.herMobile,
          // RetreatTitle: row.title.charAt(0).toUpperCase() + row.title.slice(1),
        });
      });
      const headings = [["First Name", "Last Name", "Email", "Mobile"]];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, exportData, { origin: "A2", skipHeader: true });
      utils.book_append_sheet(wb, ws, "Data");
      writeFile(
        wb,
        `${quickSearchFor ? `Quick Search Results` : `Search Results`}` +
          ".xlsx"
      );
    }
    let report = {
      byWhom:
        `${userData && userData.userName ? userData.userName : null} ` +
        ` (${userData && userData.userRole ? userData.userRole : null})`,
      pagelink: window.location.href,
      description: `The Flocknote Excel file exported through the Advanced Search.`,
      action: "Click",
    };
    await adminApi
      .addLogReport(report)
      .then((response) => {
        if (response.success) {
          console.log("Created new log report.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const exportPdf = async () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "Searched Data";
    let headings;
    if (isShowData) {
      const exportData = retreatsList.map((row) => [
        row.title,
        row.primaryKey,
        row.attendeeType,
        row.hisLastName,
        row.hisFirstName,
        row.herLastName,
        row.herFirstName,
        // row.coupleParish,
        row.role,
        row.service,
        row.talk,
        row.hisEmail,
        row.herEmail,
        row.hisMobile,
        row.herMobile,
        row.address,
        row.city,
        row.state,
        row.zip,
        row.coupleParish,
        row.anniversary_date !== null
          ? Helpers.convertUtcToTimezone(row.anniversary_date, "MM-DD-YYYY")
          : "",
        row.allergies,
        row.under_age_35,
        row.emergency_name1,
        row.emergency_relationship1,
        row.emergency_phone1,
        row.emergency_name2,
        row.emergency_relationship2,
        row.emergency_phone2,
        // row.hear_us,
        row.referral_from_a_friend_or_family_member,
        row.notes,
        moment(row.dateFrom).format("MMM DD") +
          "-" +
          moment(row.dateTo).format("MMM DD") +
          "," +
          moment(row.dateFrom).format("YYYY"),
        row.accountStatus &&
          row.accountStatus.charAt(0).toUpperCase() +
            row.accountStatus.slice(1).toLowerCase(),

        Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY"),
      ]);
      headings = [
        [
          "Retreat Title",
          "Couple Id",
          "Type",
          "His Last Name",
          "His First Name",
          "Her Last Name",
          "Her First Name",
          "Service Role",
          "Service Title",
          "Talk",
          "His Email",
          "Her Email",
          "His Mobile",
          "Her Mobile",
          "Address",
          "City",
          "State",
          "Zip",
          "Parish",
          "Anniversary Date",
          "Dietary Restrictions Or Physical Limitations",
          "Under Age 35",
          "Emergency Name1",
          "Emergency Relationship1",
          "Emergency Phone1",
          "Emergency Name2",
          "Emergency Emergency Relationship2",
          "Emergency Phone2",
          // "How did you hear about us",
          "Referral from a friend/family member",
          "Notes",
          "Retreat Dates",
          "Status",
          "Created",
        ],
      ];
      let content = {
        head: headings,
        body: exportData,
        startY: 70,
        theme: "grid",
        tableLineColor: [189, 195, 199],
        tableLineWidth: 0.75,
      };
      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      doc.save(
        `${quickSearchFor ? `Quick Search Results` : `Search Results`}` + ".pdf"
      );
    } else if (!isShowData && !noRetAttend && !coupWithAttenType) {
      const exportData = retreatsList.map((row) => [
        row.title,
        row.language,
        row.location,
        row.countRetreatant,
        row.countTeam,
        row.heading,
        moment(row.dateFrom).format("MMM DD") +
          "-" +
          moment(row.dateTo).format("MMM DD") +
          "," +
          moment(row.dateFrom).format("YYYY"),
        Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY"),
      ]);
      headings = [
        [
          "Retreat Title",
          "Language",
          "Location",
          "Retreatants Count",
          "Team Count",
          "Retreat Heading",
          "Retreat Dates",
          "Created",
        ],
      ];
      let content = {
        head: headings,
        body: exportData,
        startY: 70,
        theme: "grid",
        tableLineColor: [189, 195, 199],
        tableLineWidth: 0.75,
      };
      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      doc.save(
        `${quickSearchFor ? `Quick Search Results` : `Search Results`}` + ".pdf"
      );
    } else if (noRetAttend) {
      const exportData = retreatsList.map((row) => [
        row.primaryKey,
        row.retreatsCount,
        row.attendeeType,
        row.hisLastName,
        row.hisFirstName,
        row.herLastName,
        row.herFirstName,
        // row.coupleParish,
        row.role,
        row.service,
        row.talk,
        row.hisEmail,
        row.herEmail,
        row.hisMobile,
        row.herMobile,
        row.address,
        row.city,
        row.state,
        row.zip,
        row.coupleParish,
        row.anniversary_date !== null
          ? Helpers.convertUtcToTimezone(row.anniversary_date, "MM-DD-YYYY")
          : "",
        row.allergies,
        row.under_age_35,
        row.emergency_name1,
        row.emergency_relationship1,
        row.emergency_phone1,
        row.emergency_name2,
        row.emergency_relationship2,
        row.emergency_phone2,
        // row.hear_us,
        row.notes,
        moment(row.dateFrom).format("MMM DD") +
          "-" +
          moment(row.dateTo).format("MMM DD") +
          "," +
          moment(row.dateFrom).format("YYYY"),
        row.accountStatus &&
          row.accountStatus.charAt(0).toUpperCase() +
            row.accountStatus.slice(1).toLowerCase(),
      ]);
      headings = [
        [
          "Couple Id",
          "Retreat Attended",
          "Type",
          "His Last Name",
          "His First Name",
          "Her Last Name",
          "Her First Name",
          "Service Role",
          "Service Title",
          "Talk",
          "His Email",
          "Her Email",
          "His Mobile",
          "Her Mobile",
          "Address",
          "City",
          "State",
          "Zip",
          "Parish",
          "Anniversary Date",
          "Dietary Restrictions Or Physical Limitations",
          "Under Age 35",
          "Emergency Name1",
          "Emergency Relationship1",
          "Emergency Phone1",
          "Emergency Name2",
          "Emergency Emergency Relationship2",
          "Emergency Phone2",
          // "How did you hear about us",
          "Referral from a friend/family member",
          "Notes",
          "Retreat Dates",
          "Status",
        ],
      ];
      let content = {
        head: headings,
        body: exportData,
        startY: 70,
        theme: "grid",
        tableLineColor: [189, 195, 199],
        tableLineWidth: 0.75,
      };
      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      doc.save(
        `${quickSearchFor ? `Quick Search Results` : `Search Results`}` + ".pdf"
      );
    } else if (coupWithAttenType) {
      const exportData = retreatsList.map((row) => [
        row.title.charAt(0).toUpperCase() + row.title.slice(1),
        row.primaryKey,
        row.attendeeType,
        row.hisLastName,
        row.hisFirstName,
        row.herLastName,
        row.herFirstName,
        // row.coupleParish,
        row.role,
        row.service,
        row.talk,
        row.hisEmail,
        row.herEmail,
        row.hisMobile,
        row.herMobile,
        row.address,
        row.city,
        row.state,
        row.zip,
        row.coupleParish,
        row.anniversary_date !== null
          ? Helpers.convertUtcToTimezone(row.anniversary_date, "MM-DD-YYYY")
          : "",
        row.allergies,
        row.under_age_35,
        row.emergency_name1,
        row.emergency_relationship1,
        row.emergency_phone1,
        row.emergency_name2,
        row.emergency_relationship2,
        row.emergency_phone2,
        // row.hear_us,
        row.notes,
        moment(row.dateFrom).format("MMM DD") +
          "-" +
          moment(row.dateTo).format("MMM DD") +
          "," +
          moment(row.dateFrom).format("YYYY"),
        row.accountStatus &&
          row.accountStatus.charAt(0).toUpperCase() +
            row.accountStatus.slice(1).toLowerCase(),
      ]);
      headings = [
        [
          "Retreat Title",
          "Couple Id",
          "Type",
          "His Last Name",
          "His First Name",
          "Her Last Name",
          "Her First Name",
          "Service Role",
          "Service Title",
          "Talk",
          "His Email",
          "Her Email",
          "His Mobile",
          "Her Mobile",
          "Address",
          "City",
          "State",
          "Zip",
          "Parish",
          "Anniversary Date",
          "Dietary Restrictions Or Physical Limitations",
          "Under Age 35",
          "Emergency Name1",
          "Emergency Relationship1",
          "Emergency Phone1",
          "Emergency Name2",
          "Emergency Emergency Relationship2",
          "Emergency Phone2",
          // "How did you hear about us",
          "Referral from a friend/family member",
          "Notes",
          "Retreat Dates",
          "Status",
        ],
      ];
      let content = {
        head: headings,
        body: exportData,
        startY: 70,
        theme: "grid",
        tableLineColor: [189, 195, 199],
        tableLineWidth: 0.75,
      };
      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      doc.save(
        `${quickSearchFor ? `Quick Search Results` : `Search Results`}` + ".pdf"
      );
    }
    let report = {
      byWhom:
        `${userData && userData.userName ? userData.userName : null} ` +
        ` (${userData && userData.userRole ? userData.userRole : null})`,
      pagelink: window.location.href,
      description: `PDF exported in Advance Search.`,
      action: "Click",
    };
    await adminApi
      .addLogReport(report)
      .then((response) => {
        if (response.success) {
          console.log("Created new log report.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Row className="extra_space">
        <div className="cllapse_out">
          <Button
            className="collap"
            variant="primary"
            onClick={() => setOpen1(!open1)}
          >
            {" "}
            {open1 == true
              ? "- Hide Advanced Search"
              : "+ Show Advanced Search"}
          </Button>
        </div>
        {open1 ? (
          <Col lg={12} className="d-flex flex-column">
            <Row className="flex-grow">
              <Col className="grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    {/* searchForm */}
                    {searchForm()}
                    {/* end */}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        ) : null}
      </Row>
      <Container fluid>
        <Row className="margin_set">
          <Col md={6} sm={6} className="mt-3">
            {quickSearchFor ? (
              <h6>
                Quick Search Results ({dataCount > 0 ? dataCount : "0"}) -{" "}
                {quickSearchFor}
              </h6>
            ) : (
              <label htmlFor="">
                Search Results
                {searchQuery ? (
                  <label> &nbsp; ({dataCount > 0 ? dataCount : "0"}) </label>
                ) : null}{" "}
              </label>
            )}
          </Col>
          <Col md={6} sm={6} className="mt-2 mb-2">
            {" "}
            {userData && userData.userRole === "ADMIN" ? (
              <div className="import_drop1">
                <Dropdown className="exportData">
                  <Dropdown.Toggle
                    className="btn btn-primary mailbtn"
                    id="dropdown-basic"
                  >
                    <i className="icon-download"></i>
                    &nbsp;Export
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <a onClick={handleExport}>
                      {" "}
                      <Dropdown.Item>Excel</Dropdown.Item>
                    </a>
                    <a onClick={exportPdf}>
                      {" "}
                      <Dropdown.Item>PDF</Dropdown.Item>
                    </a>
                    {searchQuery.searchFor === "couple" ? (
                      <a onClick={exportFlockNotes}>
                        {" "}
                        <Dropdown.Item>Flock Note Excel</Dropdown.Item>
                      </a>
                    ) : null}
                  </Dropdown.Menu>
                </Dropdown>
                <button
                  className="btn btn-primary mailbtn"
                  onClick={ShowCompose}
                >
                  <i className="mdi mdi-email"></i>&nbsp;Send Email
                </button>
              </div>
            ) : null}
          </Col>
        </Row>
      </Container>
      <div className="home-tab">
        <div className="col-lg-12 d-flex flex-column">
          <Row className="flex-grow">
            <Col className="grid-margin stretch-card">
              <div className="card custom-card">
                <div className="card-body">
                  <div className="d-sm-flex justify-content-between align-items-start Couple_heading"></div>
                  <div className="table-responsive">
                    {isShowData ? showDataTableRetWithCoup() : null}
                    {!isShowData && !noRetAttend && !coupWithAttenType
                      ? showDataTableRet()
                      : null}
                    {noRetAttend ? showNoRetrattend() : null}
                    {coupWithAttenType ? showRetAttenType() : null}
                    <div id="section-1"></div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {showRegModal ? (
          <UpdateCouplePopUp
            showRegModal={showRegModal}
            setShowREgModal={setShowREgModal}
            setEdiUserIdClicked={setEdiUserIdClicked}
            onHide={() => {
              CloseCompose();
              clearfield();
              setEdiUserIdClicked("");
            }}
            closeButton={() => {
              checkPKCouple();
              setEdiUserIdClicked("");
              CloseCompose();
              searchRetreatsAndCouples();
            }}
            userData={userData}
            isEditAdmin={isEditAdmin}
            parishList={parishOptions}
            ediUserIdClicked={ediUserIdClicked}
            CloseCompose={CloseCompose}
            roasterCount={roasterCount}
            roastersOfCoupleList={roastersOfCoupleList}
            getAllCouples={searchRetreatsAndCouples}
            data={data}
            setData={setData}
            oldValues={oldValues}
            setOldValues={setOldValues}
            isTempUpload={isTempUpload}
            isTempUpload2={isTempUpload2}
            setIsTempUpload={setIsTempUpload}
            setIsTempUpload2={setIsTempUpload2}
          />
        ) : null}
        <Modal
          size="lg"
          show={mailshow}
          onHide={() => {
            setMailShow(false);
            setIsDisabled(false);
          }}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit1}
            className="forms-sample"
          >
            <Modal.Header closeButton>
              <h3 className="page-title"> Compose Email </h3>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group row">
                {/* {quickSearchFor ? (
                  <h6>
                    Quick Search Results ({dataCount > 0 ? dataCount : "0"}) -{" "}
                    {quickSearchFor}
                  </h6>
                ) : (
                  <label htmlFor="">
                    Search Results
                    {searchQuery ? (
                      <label>
                        {" "}
                        &nbsp; ({dataCount > 0 ? dataCount : "0"}){" "}
                      </label>
                    ) : null}{" "}
                    {searchQuery.searchFor ? (
                      <label>
                        {" "}
                        &nbsp;
                        {` - For ${
                          searchQuery.searchFor.charAt(0).toUpperCase() +
                          searchQuery.searchFor.slice(1)
                        }`}
                      </label>
                    ) : null}
                    {searchQuery && searchQuery.retreatId ? (
                      <label>
                        {" "}
                        &nbsp;
                        {` - ${
                          Array.isArray(searchQuery.retreatId) &&
                          searchQuery.retreatId.length > 0
                            ? `${selectRetreat
                                .filter((item) => item)
                                .map(
                                  (item) =>
                                    item.charAt(0).toUpperCase() + item.slice(1)
                                )
                                .join(", ")}`
                            : ""
                        }`}
                      </label>
                    ) : (
                      <label> &nbsp;{`- All Retreats `} &nbsp;</label>
                    )}
                    {searchQuery && searchQuery.coupleId ? (
                      <label>
                        {" "}
                        &nbsp;{" "}
                        {` - Couple - ${
                          Array.isArray(selectCouple) && selectCouple.length > 0
                            ? `${selectCouple
                                .filter((item) => item)
                                .map(
                                  (item) =>
                                    item.charAt(0).toUpperCase() + item.slice(1)
                                )
                                .join(", ")}`
                            : ""
                        } `}
                      </label>
                    ) : (
                      <label> &nbsp; {` - All Couples `}</label>
                    )}
                    {searchQuery && searchQuery.parisheId ? (
                      <label>
                        {" "}
                        &nbsp; -{" "}
                        {`${
                          selectParish
                            ? `${selectParish
                                .filter((item) => item)
                                .map(
                                  (item) =>
                                    item.charAt(0).toUpperCase() + item.slice(1)
                                )
                                .join(", ")}`
                            : ""
                        }`}
                      </label>
                    ) : null}
                    {searchQuery.dateFrom ? (
                      <label>
                        {" "}
                        - {moment(searchQuery.dateFrom).format(
                          "MMM DD yy"
                        )}{" "}
                      </label>
                    ) : null}{" "}
                    {searchQuery.dateTo ? (
                      <label>
                        {" "}
                        ,{moment(searchQuery.dateTo).format("MMM DD yy")}{" "}
                      </label>
                    ) : null}
                    {searchQuery.retreatants.length ? (
                      <label>
                        {" "}
                        &nbsp; -{searchQuery.retreatants.join(" , ")}
                      </label>
                    ) : null}
                    {serviceTextArray.length > 0 ? (
                      <label>
                        &nbsp;{` - Titles - ${serviceTextArray.join(" , ")}`}
                      </label>
                    ) : null}{" "}
                    {roleTextArray.length > 0 ? (
                      <label>
                        &nbsp;{` - Roles - ${roleTextArray.join(" , ")}`}
                      </label>
                    ) : null}{" "}
                    {talkTextArray.length > 0 ? (
                      <label>
                        &nbsp;{` - Talks - ${talkTextArray.join(" , ")}`}
                      </label>
                    ) : null}
                    {searchQuery.accountStatus.length > 0 ? (
                      <label>
                        {" "}
                        &nbsp;
                        {` - Status - ${searchQuery.accountStatus.join(" , ")}`}
                      </label>
                    ) : null}
                    {searchQuery.cityState.length > 0 ? (
                      <label>
                        &nbsp;
                        {` - City State - `}&nbsp;
                        {searchQuery.cityState
                          ? searchQuery.cityState.map((i, index, arr) => {
                              return (
                                <label>
                                  {i.label}{" "}
                                  {arr.length - 1 === i ? (
                                    <label>,</label>
                                  ) : null}{" "}
                                </label>
                              );
                            })
                          : null}
                      </label>
                    ) : null}
                    {searchQuery.primaryKey ? (
                      <label> - {searchQuery.primaryKey}</label>
                    ) : null}
                    {searchQuery.location.length > 0 ? (
                      <label>
                        &nbsp;
                        {` - Location - `}&nbsp;
                        {searchQuery.location
                          ? searchQuery.location.map((i, index, arr) => {
                              return (
                                <label>
                                  {i.label}{" "}
                                  {arr.length - 1 === i ? (
                                    <label>,</label>
                                  ) : null}{" "}
                                </label>
                              );
                            })
                          : null}
                      </label>
                    ) : null}
                    {searchQuery.language ? (
                      <label>
                        {" "}
                        -{" "}
                        {`              ${
                          Array.isArray(searchQuery.language) &&
                          searchQuery.language.length > 0
                            ? `${selectLanguage
                                .filter((item) => item)
                                .map(
                                  (item) =>
                                    item.charAt(0).toUpperCase() + item.slice(1)
                                )
                                .join(", ")}`
                            : ""
                        }`}{" "}
                      </label>
                    ) : null}
                    {searchQuery.neverServedTeam == true ? (
                      <label> &nbsp; - Never Served Team</label>
                    ) : null}
                    {searchQuery.neverTalk == true ? (
                      <label> &nbsp; - Never Talk</label>
                    ) : null}
                    {searchQuery.neverServiceRole == true ? (
                      <label> &nbsp; - Never Service Role</label>
                    ) : null}
                  </label>
                )} */}
                <label>To:</label>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Filtered Email</Accordion.Header>
                    <Accordion.Body>
                      {allCoupleData.length
                        ? allCoupleData.map((item, index) => (
                            <li>{item.label}</li>
                          ))
                        : " "}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <Form.Group className="form-group">
                <label htmlFor="exampleInputEmail1">Subject:</label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Subject"
                  name="subject"
                  id="subject"
                  value={maildata.subject}
                  className="form-control"
                  onChange={(e) => handleChanges1(e)}
                  disabled={isDisabled}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter the Subject!
                </Form.Control.Feedback>
              </Form.Group>
              <div className="form-group sunEditor">
                <label htmlFor="exampleInputEmail1">Message:</label>
                <SunEditor
                  setContents={defaultEditorContent}
                  id="message"
                  className="form-control"
                  onChange={(e) => {
                    setMessage(e);
                  }}
                  readOnly={isDisabled}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={CloseCompose}>
                Close
              </Button>
              {isDisabled ? null : (
                <Button variant="primary" type="submit">
                  Send
                </Button>
              )}
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    </>
  );

  function searchForm() {
    return (
      <form className="forms-sample border-bottom mb-4" id="searchForm">
        <Row>
          <Col md={8} sm={8} xs={8} className="d-sm-flex">
            <label className="bold mt-2" htmlFor="exampleInputEmail1">
              Search For :
            </label>
            <div className="mb-3">
              <Form.Check
                type={"radio"}
                id={`retreadRad`}
                checked={searchQuery.searchFor === "retreat"}
                onChange={(e) => {
                  handleSearchFor(e);
                  handleSearchChanges(e);
                }}
                value={"retreat"}
                label={`Retreats(s)`}
                name="searchFor"
                className="space_lef1"
              />
            </div>
            <div className="mb-3">
              <Form.Check
                type={"radio"}
                id={`coupleRad`}
                checked={searchQuery.searchFor === "couple"}
                onChange={(e) => {
                  handleSearchFor(e);
                  handleSearchChanges(e);
                }}
                value={`couple`}
                label={`Couple(s)`}
                className="space_lef1"
                name="searchFor"
              />
            </div>
          </Col>
          <Col md={12} sm={12} xs={12} className="">
            <Row>
              <Col lg={4} md={4} sm={12} xs={12} className="form-group d-flex">
                <label htmlFor="exampleInputEmail1" className="bold">
                  Search By Date :
                </label>
                <label className="form-check m-0 space_lef1">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="allDates"
                    onChange={(e) => enableAllDates(e)}
                    value={allDates}
                    // checked={allDates}
                  />
                  All Dates
                  <i className="input-helper"></i>
                </label>
              </Col>
              <Col lg={8} md={8} sm={12} xs={12} className="form-group">
                <span className="d-flex">
                  <DatePicker
                    required
                    autoComplete="off"
                    dateFormat="MM-dd-yyyy"
                    onChange={(date) => {
                      let d = { ...searchQuery };
                      d["dateFrom"] = date;
                      setSearchQuery(d);
                    }}
                    selected={searchQuery.dateFrom}
                    placeholderText="mm-dd-yyyy"
                    className="form-select setdate datepicker"
                    name="dateFrom"
                    id="dateFrom"
                    disabled={allDates === true ? false : true}
                  />
                  <span className="mx-2">-</span>
                  <DatePicker
                    required
                    autoComplete="off"
                    dateFormat="MM-dd-yyyy"
                    selected={searchQuery.dateTo}
                    placeholderText="mm-dd-yyyy"
                    onChange={(date) => {
                      let d = { ...searchQuery };
                      d["dateTo"] = date;
                      setSearchQuery(d);
                    }}
                    className="form-select setdate datepicker"
                    name="dateTo"
                    id="dateTo"
                    maxDate={new Date()}
                    showDisabledMonthNavigation
                    disabled={allDates === true ? false : true}
                  />
                </span>
              </Col>
            </Row>
          </Col>
          <Col lg={4} md={4} sm={6} xs={12} className="form-group bold">
            <label htmlFor="retreat">Search by Retreats</label>

            <Select
              id="retreat"
              name="retreat"
              options={retreatOptions}
              value={selectedRetreats}
              onChange={handleSelectRetreat}
              labelledBy="Select"
              isSearchable={true}
              isMulti={true}
            />

            {/* <select
              className="form-control minimal"
              id="retreat"
              name="retreat"
              onChange={(e) => handleSearchChanges(e)}
              value={searchQuery.retreatId}
            >
              <option value={"0"}>All Retreats</option>
              {allRetreatData.map((item) => {
                return (
                  <option key={`retret-${item.id}`} value={item.id}>
                    {item && item.title !== null && item.title != ""
                      ? `${
                          item.title.charAt(0).toUpperCase() +
                          item.title.slice(1)
                        }`
                      : ""}
                    {item && item.parish !== null && item.parish != ""
                      ? " " +
                        `${
                          moment(item.dateFrom).format("MMM DD") +
                          " - " +
                          moment(item.dateTo).format("MMM DD") +
                          ", " +
                          moment(item.dateFrom).format("YYYY")
                        }`
                      : ""}
                  </option>
                );
              })}
            </select> */}
          </Col>

          <Col lg={4} md={4} sm={6} xs={12} className="form-group bold">
            <label htmlFor="type">Search by Language </label>

            <Select
              id="language"
              name="language"
              options={languagesOptions}
              value={selectedLanguages}
              onChange={handleSelectLanguages}
              labelledBy="Select"
              isSearchable={true}
              isMulti={true}
            />

            {/* <select
              className="form-control minimal"
              id="language"
              name="language"
              onChange={(e) => handleSearchChanges(e)}
              value={searchQuery.language}
            >
              <option value={"0"}>All Languages</option>
              <option value="english">English</option>
              <option value="spanish">Spanish</option>
            </select> */}
          </Col>

          <Col lg={4} md={4} sm={6} xs={12} className="form-group bold">
            <label htmlFor="parish">Search by Parish</label>

            <Select
              id="parish"
              name="parish"
              options={parishOptions}
              value={selectedParishes}
              onChange={handleSelectParishes}
              labelledBy="Select"
              isSearchable={true}
              isMulti={true}
            />
          </Col>
          <Col lg={4} md={4} sm={6} xs={12} className="form-group bold">
            <label htmlFor="type">Search by Couples</label>

            <Select
              id="couple"
              name="couple"
              options={coupleOptions}
              value={selectedCouples}
              onChange={handleSelectCouples}
              labelledBy="Select"
              isSearchable={true}
              isMulti={true}
            />

            {/* <select
              className="form-control minimal"
              id="couple"
              name="couple"
              onChange={(e) => handleSearchChanges(e)}
              value={searchQuery.coupleId}
            >
              <option value={"0"}>All Couples</option>
              {allCoupleList.map((item) => {
                return (
                  <option key={`couple-${item.id}`} value={item.id}>
                    {item && item.hisLastName ? `${item.hisLastName}` : null}
                    {item && item.hisFirstName
                      ? `, ${item.hisFirstName}`
                      : null}{" "}
                    {item && item.hisFirstName ? ` and ` : null}
                    {item && item.herFirstName ? `${item.herFirstName}` : null}
                  </option>
                );
              })}
            </select> */}
          </Col>
          {searchFor === "retreat" ? (
            <Col lg={4} md={4} sm={6} xs={12} className="form-group">
              <label htmlFor="type" className="bold">
                Location
              </label>
              <Select
                name="location"
                options={location}
                value={selectedLocation}
                onChange={handleSelectLocation}
                labelledBy="Select"
                isSearchable={true}
                isMulti={true}
              />
            </Col>
          ) : (
            <Col lg={4} md={4} sm={6} xs={12} className="form-group bold">
              <label htmlFor="type" className="bold">
                City / State
              </label>
              <Select
                name="cityState"
                options={ciytState}
                value={selectedStateCity}
                onChange={handleSelect1}
                labelledBy="Select"
                isSearchable={true}
                isMulti={true}
              />
            </Col>
          )}
          {searchFor == "couple" ? (
            <Col lg={4} md={4} sm={6} xs={12} className="form-group bold">
              <label htmlFor="type">Search by Couple Id</label>
              <input
                type="text"
                className="form-control"
                id=""
                name="primaryKey"
                onChange={(e) => handleSearchChanges(e)}
                placeholder="Enter Couple Id"
                value={
                  searchQuery.primaryKey == 0 ? "" : searchQuery.primaryKey
                }
              />
            </Col>
          ) : null}
          <Col lg={4} md={4} sm={6} xs={12} className="form-group">
            <label htmlFor="type" className="bold">
              ZipCode
            </label>
            <Select
              name="zip"
              options={zip}
              value={selectedZips}
              onChange={handleSelectZip}
              labelledBy="Select"
              isSearchable={true}
              isMulti={true}
            />
          </Col>
          {searchFor == "retreat" ? (
            <>
              <Col
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="form-group colorTalks"
              >
                <Col lg={4} md={4} sm={6} xs={12}>
                  <label className="bold" htmlFor="exampleInputEmail1">
                    Search in Retreat
                  </label>
                  <div className="row m-0">
                    <div className="form-check col-6">
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name="retreatants"
                          onChange={(e) => handleSearchChanges(e)}
                          value={"Team"}
                          checked={
                            searchQuery.retreatants.findIndex(
                              (s) => s == "Team"
                            ) > -1
                              ? true
                              : false
                          }
                        />
                        Team
                        <i className="input-helper"></i>
                      </label>
                    </div>
                    <div className="form-check col-6">
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name="retreatants"
                          onChange={(e) => handleSearchChanges(e)}
                          value={"Retreatant"}
                          checked={
                            searchQuery.retreatants.findIndex(
                              (s) => s == "Retreatant"
                            ) > -1
                              ? true
                              : false
                          }
                        />
                        Retreatant
                        <i className="input-helper"></i>
                      </label>
                    </div>
                  </div>
                </Col>
              </Col>
            </>
          ) : (
            <Col lg={4} md={4} sm={6} xs={12} className="form-group">
              <label className="bold" htmlFor="retreatAttended">
                Retreats Attended
              </label>
              <div className="form-check">
                <label className="form-check-label">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="retreatAttended"
                    onChange={(e) => handleSearchChanges(e)}
                    value={searchQuery.retreatAttended}
                    checked={searchQuery.retreatAttended}
                  />
                  No. of Times Retreats Attended
                  <i className="input-helper"></i>
                </label>
              </div>
            </Col>
          )}
          <div className="form-group colorTalks">
            <label htmlFor="exampleInputUsername1" className="bold">
              Service Title
            </label>
            <Row className="m-0">
              {serviceDataList.length
                ? serviceDataList.map((item) => {
                    return (
                      <Col
                        lg={3}
                        md={3}
                        sm={4}
                        xs={6}
                        className="form-check"
                        key={`servTitle-${item.id}`}
                      >
                        <label className="form-check-label">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            name="service"
                            onChange={(e) => handleSearchChanges(e, item.id)}
                            value={item.id}
                            checked={
                              searchQuery.serviceIds.findIndex(
                                (id) => id == item.id
                              ) > -1
                                ? true
                                : false
                            }
                          />
                          {item.service}
                          <i className="input-helper"></i>
                        </label>
                      </Col>
                    );
                  })
                : " "}
              <>
                <Col lg={3} md={3} sm={4} xs={6} className="form-check">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="neverService"
                      onChange={(e) => {
                        handleSearchChanges(e);
                      }}
                      value={searchQuery.neverServedTeam}
                      checked={searchQuery.neverServedTeam}
                    />
                    Never served on Team
                    <i className="input-helper"></i>
                  </label>
                </Col>
              </>
            </Row>
          </div>
          <Col
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={"form-group colorTalks"}
          >
            <label htmlFor="exampleInputUsername1" className="bold">
              Service Role
            </label>
            <Row className="m-0">
              {rolesDataList.length
                ? rolesDataList.map((item, index) => (
                    <Col
                      lg={3}
                      md={3}
                      sm={4}
                      xs={6}
                      className="form-check"
                      key={`servRol-${item.id}`}
                    >
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name="serviceRole"
                          onChange={(e) => handleSearchChanges(e, item.id)}
                          value={item.id}
                          checked={
                            searchQuery.roleIds.findIndex(
                              (id) => id == item.id
                            ) > -1
                          }
                        />
                        {item.role}
                        <i className="input-helper"></i>
                      </label>
                    </Col>
                  ))
                : " "}
              <>
                <Col lg={3} md={3} sm={4} xs={6} className="form-check">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="neverServiceRole"
                      onChange={(e) => {
                        handleSearchChanges(e);
                      }}
                      value={searchQuery.neverServiceRole}
                      checked={searchQuery.neverServiceRole}
                    />
                    No Service Role
                    <i className="input-helper"></i>
                  </label>
                </Col>
              </>
            </Row>
          </Col>
          <Col
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="form-group colorTalks"
          >
            <label htmlFor="exampleInputUsername1" className="bold">
              Talks
            </label>
            <Row className="m-0">
              {talksDataList && talksDataList.length
                ? talksDataList.map((item, index) => (
                    <Col
                      lg={3}
                      md={3}
                      sm={4}
                      xs={6}
                      className="form-check"
                      key={`servTalk-${index}`}
                    >
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name="talks"
                          onChange={(e) => handleSearchChanges(e, item.talkId)}
                          value={item && item.talkId}
                          checked={
                            searchQuery.talkIds.findIndex(
                              (id) => id == item.talkId
                            ) > -1 ||
                            (location1.state && location1.state.reportId === 3)
                              ? true
                              : false
                          }
                          // defaultChecked={location1.state.reportId == 3 ? true : false}
                        />
                        {item.talk}
                        <i className="input-helper"></i>
                      </label>
                    </Col>
                  ))
                : " "}
              <>
                <Col lg={3} md={3} sm={4} xs={6} className="form-check">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="neverTalk"
                      onChange={(e) => {
                        handleSearchChanges(e);
                      }}
                      value={searchQuery.neverTalk}
                      checked={searchQuery.neverTalk}
                    />
                    Never Given Talk
                    <i className="input-helper"></i>
                  </label>
                </Col>
              </>
            </Row>
          </Col>
          {searchFor == "couple" ? (
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="form-group colorTalks"
            >
              <label htmlFor="exampleInputUsername1" className="bold">
                Couples Status
              </label>
              <div className="row m-0">
                <Col lg={3} md={3} sm={4} xs={6} className="form-check">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="coupleStatus"
                      onChange={(e) => handleSearchChanges(e)}
                      value={"ACTIVE"}
                      checked={
                        searchQuery.accountStatus.findIndex(
                          (s) => s == "ACTIVE"
                        ) > -1
                          ? true
                          : false
                      }
                    />
                    Active
                    <i className="input-helper"></i>
                  </label>
                </Col>
                <Col lg={3} md={3} sm={4} xs={6} className="form-check">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="coupleStatus"
                      onChange={(e) => handleSearchChanges(e)}
                      value={"DECEASED"}
                      checked={
                        searchQuery.accountStatus.findIndex(
                          (s) => s == "DECEASED"
                        ) > -1
                          ? true
                          : false
                      }
                    />
                    Deceased
                    <i className="input-helper"></i>
                  </label>
                </Col>
                <Col lg={3} md={3} sm={4} xs={6} className="form-check">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="coupleStatus"
                      onChange={(e) => handleSearchChanges(e)}
                      value={"DIVORCED"}
                      checked={
                        searchQuery.accountStatus.findIndex(
                          (s) => s == "DIVORCED"
                        ) > -1
                          ? true
                          : false
                      }
                    />
                    Divorced
                    <i className="input-helper"></i>
                  </label>
                </Col>
              </div>
            </Col>
          ) : null}
        </Row>
        <div className="mb-3">
          <button
            type="button"
            className="btn btn-primary"
            onClick={(e) => searchRetreatsAndCouples(e)}
          >
            Search
          </button>
          <button
            type="button"
            className="btn btn-light"
            onClick={(e) => resetForm(e)}
          >
            Reset
          </button>
        </div>
      </form>
    );
  }
};
export default SearchData;
